import { useMutation } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import logo from '../../assets/images/logo-full.svg';
import Button from '../../components/Button';
import FormInput from '../../components/FormInput';
import PageLoader from '../../components/PageLoader';
import UserRole from '../../enums/UserRole';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';
import { useUser } from '../../providers/UserProvider';

const Login = () => {
  const { setUser } = useUser();
  const { toastError, toastSuccess } = useCustomToast();
  const { toastFetchError } = useToastFetchError();
  const { t } = useTranslation();
  const { fetch } = useFetch();

  const mutation = useMutation({
    mutationFn: (values) =>
      fetch('/users/login', {
        body: { email: values.username, password: values.password },
        method: 'POST',
      }),
    onError: (error) => {
      toastFetchError(error);
    },
    onSuccess: async (response) => {
      const data = await response.json();
      const {
        data: { role },
      } = data;

      if (role === UserRole.Driver) {
        toastError(t('You are not allowed to log in.'));
      } else {
        setUser(data.data);
        toastSuccess(t('Logged in successfully.'));
      }
    },
  });

  const methods = useForm({
    defaultValues: {
      password: '',
      username: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });
  const { handleSubmit } = methods;

  return (
    <>
      {mutation.isPending && <PageLoader />}

      <div className="flex flex-1 flex-col items-center justify-center pt-18">
        <img
          alt="Urbify logo"
          className="h-[96px] w-[128px]"
          height="96"
          src={logo}
        />

        <div className="my-[60px] flex w-full justify-center px-4">
          <div className="w-full max-w-[480px] rounded-md bg-white px-4 py-5 shadow-elevation-200 sm:p-8">
            <FormProvider {...methods}>
              <form noValidate onSubmit={handleSubmit(mutation.mutate)}>
                <div className="flex flex-col gap-5">
                  <p className="font-semibold">{t('Log In to Your Account')}</p>

                  <div className="flex flex-col gap-4">
                    <FormInput
                      id="user-name"
                      label={t('E-mail Address')}
                      name="username"
                      placeholder={t('Enter your e-mail address')}
                      required
                      type="email"
                    />
                    <FormInput
                      id="password"
                      label={t('Password')}
                      name="password"
                      placeholder={t('Enter your password')}
                      required
                      type="password"
                    />
                  </div>

                  <div className="border-b-2 border-grey-200" />

                  <div className="flex flex-col gap-3">
                    <Button
                      isFullWidth
                      text={t('Log In')}
                      type="submit"
                      variant="solidBlack"
                    />
                    <Link className="flex gap-2" to="/forgot-password">
                      <Button
                        className="text-sm font-normal"
                        isFullWidth
                        text={t('Forgot Password?')}
                        type="button"
                        variant="ghostBlack"
                      />
                    </Link>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
