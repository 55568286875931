import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';

import LinkButton from '../../../components/LinkButton';
import Tooltip from '../../../components/Tooltip';
import Breakpoint from '../../../enums/Breakpoint';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useUser } from '../../../providers/UserProvider';
import RoutingsDrawer from './RoutingsDrawer';

const RoutingCycleInfo = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { fetch } = useFetch();
  const { user } = useUser();
  const { width } = useWindowSize();
  const isSmallDesktop = width >= Breakpoint.LG && width < Breakpoint.XL;

  const { data: organisationShifts } = useQuery({
    queryKey: [`/carriers/${user.carrier.id}/shifts?sortField=routingTime`],

    queryFn: async () => {
      const response = await fetch(
        `/carriers/${user.carrier.id}/shifts?sortField=routingTime`,
        {
          method: 'GET',
        },
      );
      return response.json();
    },
    refetchOnWindowFocus: true,
    // 5 minutes
    staleTime: 1000 * 60 * 5,
  });

  const nextShiftRoutingTime = useMemo(() => {
    const currentTime = moment();

    const nextShift = organisationShifts?.data.find((shift) => {
      const shiftRoutingTime = moment(shift.routingTime, 'HH:mm');
      return currentTime.isBefore(shiftRoutingTime);
    });

    return nextShift?.routingTime;
  }, [organisationShifts]);

  const nextShifts = useMemo(() => {
    const shifts = organisationShifts?.data.filter(
      (shift) => shift.routingTime === nextShiftRoutingTime,
    );

    return shifts?.map(
      (shift) =>
        `${shift.hub.shortCode} ${t('Hub Shift')} ${shift.number} (${
          shift.startTime
        }-${shift.endTime})`,
    );
  }, [nextShiftRoutingTime, organisationShifts?.data, t]);

  return (
    <>
      <RoutingsDrawer
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        shifts={organisationShifts?.data}
      />
      <div className="divide flex flex-1 flex-col gap-3 divide-y divide-grey-700 rounded-md border border-grey-300 bg-grey-100 px-3 py-1.5 lg:flex-row lg:gap-4 lg:divide-x lg:divide-y-0">
        {nextShiftRoutingTime ? (
          <>
            <div className="flex items-center gap-2 leading-6">
              {!isSmallDesktop && (
                <div className="text-sm text-grey-700">
                  {t('Upcoming routing')}:
                </div>
              )}
              <Tooltip
                size="sm"
                hidden={!isSmallDesktop}
                text={t('Upcoming routing')}
              >
                <strong data-test="next-shift-routing-time">
                  {nextShiftRoutingTime}
                </strong>
              </Tooltip>
            </div>
            <div className="flex flex-col items-start gap-2 pl-0 pt-3 text-sm lg:flex-row lg:items-center lg:pl-4 lg:pt-0">
              <div data-test="next-shifts-data">
                <span>{nextShifts[0]}</span>
                {nextShifts.length > 1 && ` ${t('and others')}`}
              </div>
              <LinkButton
                data-test="upcoming-routings-modal-button"
                text={t('See all upcoming routings')}
                onClick={() => setIsModalOpen(true)}
              />
            </div>
          </>
        ) : (
          <div className="flex flex-col items-start gap-4 lg:flex-row lg:items-center">
            <div className="text-sm text-grey-700 leading-6">
              {t('Upcoming routing')}:
            </div>
            <div className="text-sm" data-test="upcoming-route-info">
              {t('No more scheduled routings for today')}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RoutingCycleInfo;
