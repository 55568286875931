import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';
import { useMount } from 'react-use';

import Icon from '../../../components/Icon';
import TourStopStatus from '../../../enums/TourStopStatus';
import deepCopy from '../../../helpers/deepCopy';
import { SocketHandler } from '../../../lib/api/hooks/useSocket';
import TourStopDetails from './TourStopDetails';

const TourStop = (props) => {
  const { className, highlight, queryKey, stop, tour } = props;

  const { status } = stop;
  const ref = useRef(null);
  const queryClient = useQueryClient();
  useMount(() => {
    if (highlight) {
      const topbarHeight = document.getElementById('topbar')?.clientHeight || 0;
      const titleBarHeight =
        document.getElementById('titlebar')?.clientHeight || 0;

      window.scrollTo({
        behavior: 'smooth',
        top: ref.current.offsetTop - titleBarHeight - topbarHeight - 16,
      });
    }
  });

  const isPending = status === TourStopStatus.Pending;
  const isCompleted = status === TourStopStatus.Completed;
  const isWithdrawn = status === TourStopStatus.Withdrawn;
  const isAttempted = status === TourStopStatus.Attempted;

  const onSocketUpdate = useCallback(
    (event) => {
      if (event.type !== 'updated') {
        return;
      }

      const updatedRecord = event.data;

      queryClient.setQueryData([queryKey], (oldData) => {
        const changedProperties = event.changedProperties || [];

        if (changedProperties.length === 0) {
          return undefined;
        }

        const recordsData = oldData?.data || [];
        const newRecordsData = recordsData.map((record) => {
          if (record.id === updatedRecord.id) {
            const newRecord = deepCopy(record);
            changedProperties.forEach((propertyName) => {
              newRecord[propertyName] = updatedRecord[propertyName];
            });
            return newRecord;
          }
          return record;
        });

        if (import.meta.env.DEV) {
          // eslint-disable-next-line no-console
          console.log(
            `[TourStop] Updated record tourStop - ${updatedRecord.id}`,
            '\n',
            updatedRecord,
            '\n',
            'Changed properties: ',
            '\n',
            changedProperties,
          );
        }

        return {
          ...oldData,
          data: newRecordsData,
        };
      });
    },
    [queryClient, queryKey],
  );

  return (
    <>
      <SocketHandler entity="tourStop" id={stop.id} onEvent={onSocketUpdate} />
      <div
        className={cn(
          'relative flex flex-col gap-2 rounded-md p-4 border-[2px] border-transparent print:p-0 print:gap-1',
          isPending && 'bg-white',
          isCompleted && 'bg-ui-green-dark-light',
          isWithdrawn && 'bg-ui-red-light',
          isAttempted && 'bg-ui-notification-yellow-light',
          highlight &&
            isPending &&
            'animate-[highlightTourStopPending_2s_0.5s_forwards]',
          highlight &&
            isCompleted &&
            'animate-[highlightTourStopCompleted_2s_0.5s_forwards]',
          highlight &&
            isAttempted &&
            'animate-[highlightTourStopAttempted_2s_0.5s_forwards]',
          highlight &&
            isWithdrawn &&
            'animate-[highlightTourStopWithdrawn_2s_0.5s_forwards]',
          className,
        )}
        ref={ref}
      >
        <div>
          <div className="flex flex-row items-center gap-3">
            <div className="inline-flex justify-center">
              {isWithdrawn ? (
                <Icon className="h-5 w-5 text-ui-red" icon="tourStopCancel" />
              ) : (
                <Icon
                  className={cn(
                    'h-5 w-5',
                    isPending && 'text-grey-700',
                    isCompleted && 'text-ui-green-dark',
                    isAttempted && 'text-ui-notification-yellow',
                  )}
                  icon="tourStop"
                />
              )}
            </div>
            <div className="flex flex-1 flex-row items-center justify-between">
              <div className="flex items-center">
                <span className="text-base font-semibold">
                  {`${moment(stop.eta).format('HH:mm')}`}
                </span>
                <span className="ml-2 text-xs text-grey-900">
                  {`(${moment(stop.windowFrom).format('HH:mm')} - ${moment(
                    stop.windowTo,
                  ).format('HH:mm')})`}
                </span>
              </div>

              {stop.driverActionTime && (
                <div className="flex flex-row items-center gap-0.5 text-grey-700">
                  <Icon className="h-4 w-4 " icon="flagOutlined" />
                  <span className="text-sm">
                    {moment(stop.driverActionTime).format('HH:mm')}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-3">
          <span
            className={cn(
              'inline-block h-5 min-w-[20px] rounded text-center text-sm font-medium text-grey-900',
              status !== TourStopStatus.Pending ? 'bg-white' : 'bg-grey-100',
            )}
          >
            {stop.sequence}
          </span>

          <div className="flex-1">
            <div className="text-sm text-grey-900">
              <span>{stop.address}</span>
            </div>
          </div>
        </div>
        {stop.tasks?.length > 0 && (
          <TourStopDetails
            tour={tour}
            clientName={stop.tasks[0]?.client?.publicName}
            hasBlockedUnits={
              stop.unitsBlocked && status !== TourStopStatus.Withdrawn
            }
            tourStopId={stop.id}
            tasks={stop.tasks}
            status={stop.status}
          />
        )}
      </div>
      <div className="w-full px-4">
        <div className="border-b border-b-grey-300" />
      </div>
    </>
  );
};

TourStop.propTypes = {
  className: PropTypes.string,
  stop: PropTypes.shape({
    address: PropTypes.string,
    completedTime: PropTypes.string,
    customerOrganisation: PropTypes.shape({
      publicName: PropTypes.string,
    }),
    driverActionTime: PropTypes.string,
    eta: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    tasks: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
    sequence: PropTypes.number,
    windowFrom: PropTypes.string,
    windowTo: PropTypes.string,
    status: PropTypes.string,
    unitsBlocked: PropTypes.bool,
  }),
  highlight: PropTypes.bool,
  tour: PropTypes.shape({
    vehicle: PropTypes.string,
    date: PropTypes.string,
    shift: PropTypes.number,
  }).isRequired,
  queryKey: PropTypes.string.isRequired,
};

TourStop.defaultProps = {
  className: undefined,
  stop: undefined,
  highlight: false,
};

export default TourStop;
