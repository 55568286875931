import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Dropdown from '../../../components/Dropdown/Dropdown';
import IconButton from '../../../components/IconButton';
import Label from '../../../components/Label';
import NewTable, { CellAlignment } from '../../../components/NewTable';
import Tooltip from '../../../components/Tooltip';
import TourType from '../../../enums/TourType';
import UserRole from '../../../enums/UserRole';
import CurrencyHelper from '../../../helpers/CurrencyHelper';
import { useIsRole } from '../../../providers/UserProvider';
import DeleteShiftModal from '../DeleteShiftModal';
import ShiftsFilters from '../ShiftsFilters';

const Actions = ({ shift: { hub, id, number } }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const menuItems = useMemo(
    () => [
      {
        icon: 'edit',
        iconClassName: 'text-grey-700',
        onClick: () => {
          navigate(`/my-organisation/shifts/${id}/edit`);
        },
        text: t('Edit Shift'),
      },
      {
        icon: 'delete',
        iconClassName: 'text-ui-red',
        onClick: () => {
          setIsModalOpen(true);
        },
        text: t('Delete Shift'),
        textClassName: 'text-ui-red',
      },
    ],
    [navigate, id, t],
  );

  return (
    <>
      <DeleteShiftModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        shiftId={id}
        name={hub.name}
        number={number}
      />
      <Dropdown menu={menuItems}>
        <div className="flex">
          <Tooltip placement="top" size="sm" text={t('Shift Options')}>
            <IconButton
              icon="moreVert"
              iconSize="s"
              size="s"
              variant="ghostBlack"
            />
          </Tooltip>
        </div>
      </Dropdown>
    </>
  );
};

Actions.propTypes = {
  shift: PropTypes.shape({
    id: PropTypes.string,
    hub: PropTypes.shape({
      name: PropTypes.string,
    }),
    number: PropTypes.number,
  }).isRequired,
};

const ShiftsTable = (props) => {
  const { children, className, organisation, total, ...rest } = props;
  const isAdmin = useIsRole(UserRole.Admin);

  const { t } = useTranslation();

  const getUnitLabel = useCallback(
    (number) => {
      if (number === 1) {
        return t('Morning');
      }
      if (number === 2) {
        return t('Afternoon');
      }
      return t('Evening');
    },
    [t],
  );

  const columns = useMemo(() => {
    const allColumns = [
      {
        accessorKey: 'hub',
        header: t('Hub'),
        size: 320,
        cell: (info) => (
          <span>
            {info.row.original?.hub
              ? info.row.original?.hub?.name
              : t('All (except Custom assigned)')}
          </span>
        ),
      },
      {
        size: 220,
        header: t('Shift'),
        cell: (info) => (
          <span>
            {info.row.original.number} ({getUnitLabel(info.row.original.number)}
            )
          </span>
        ),
      },
      {
        size: 86,
        header: t('Label'),
        cell: (info) => (
          <Label
            size="s"
            text={`S${info.row.original.number}`}
            variant="grey"
          />
        ),
      },
      {
        size: 120,
        header: t('Duration'),
        cell: (info) => (
          <span>
            {info.row.original.startTime}-{info.row.original.endTime}
          </span>
        ),
      },
      {
        size: 120,
        header: t('Execution Slot'),
        cell: (info) => (
          <span>
            {info.row.original.executionSlotStart}-
            {info.row.original.executionSlotEnd}
          </span>
        ),
      },
      {
        size: 164,
        accessorKey: 'standardDrivingTime',
        header: t('Standard Driving Time'),
        cell: (info) => {
          const value = info.getValue();
          return (
            <span>
              {value !== null
                ? moment.utc(value * 60 * 1000).format('HH:mm')
                : 'N/A'}
            </span>
          );
        },
      },
      {
        size: 110,
        accessorKey: 'routingTime',
        header: t('Routing Time'),
        cell: (info) => info.getValue(),
      },
      {
        size: 118,
        accessorKey: 'tourType',
        header: t('Tour Type'),
        cell: (info) => {
          const tourType = info.getValue();
          const text =
            tourType === TourType.Circular ? t('Circular') : t('Open-ended');

          return <span>{text}</span>;
        },
      },
      {
        size: 120,
        accessorKey: 'fixedTourPrice',
        header: t('Tour Price'),
        cell: (info) => {
          const value = info.getValue();
          const formatted =
            `${CurrencyHelper.numberToCurrency(value)} €` || 'N/A';

          return <span>{formatted}</span>;
        },
      },
      {
        size: 120,
        accessorKey: 'overtimeHourPrice',
        header: t('Overtime Rate (Per H)'),
        cell: (info) => {
          const value = info.getValue();
          const formatted =
            `${CurrencyHelper.numberToCurrency(value)} €` || 'N/A';

          return <span>{formatted}</span>;
        },
      },
      {
        id: 'actions',
        meta: {
          align: CellAlignment.Center,
        },
        size: 50,
        enableResizing: false,
        cell: (info) => <Actions shift={info.row.original} />,
      },
    ];

    return allColumns.filter((column) => {
      if (!isAdmin) {
        return column.id !== 'actions';
      }

      return column;
    });
  }, [t, getUnitLabel, isAdmin]);

  return (
    <>
      <ShiftsFilters organisation={organisation} count={total} />
      <NewTable
        columns={columns}
        data-test="my-organisation-shifts-table"
        id="my-organisation-shifts-table"
        restoreScroll
        inlineScroll
        roundedTop={false}
        noRecordsText={t('No Shifts added to this Organisation')}
        {...rest}
      />
    </>
  );
};

ShiftsTable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  organisation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  total: PropTypes.number,
};

ShiftsTable.defaultProps = {
  children: undefined,
  className: '',
  organisation: undefined,
  total: undefined,
};

export default ShiftsTable;
