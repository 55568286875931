import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DeliveryTaskUnitStatus from '../enums/DeliveryTaskUnitStatus';

const useDeliveryUnitStatusTranslations = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      [DeliveryTaskUnitStatus.Created]: t('Created'),
      [DeliveryTaskUnitStatus.Arrived]: t('Arrived'),
      [DeliveryTaskUnitStatus.ArrivedLocal]: t('Arrived local'),
      [DeliveryTaskUnitStatus.Retry]: t('Retry'),
      [DeliveryTaskUnitStatus.Postponed]: t('Postponed'),
      [DeliveryTaskUnitStatus.Prepared]: t('Prepared'),
      [DeliveryTaskUnitStatus.Misplaced]: t('Misplaced'),
      [DeliveryTaskUnitStatus.InDelivery]: t('In delivery'),
      [DeliveryTaskUnitStatus.InClarification]: t('In clarification'),
      [DeliveryTaskUnitStatus.NotArrived]: t('Not arrived'),
      [DeliveryTaskUnitStatus.Missorted]: t('Missorted'),
      [DeliveryTaskUnitStatus.Damaged]: t('Damaged'),
      [DeliveryTaskUnitStatus.ReturnToClient]: t('Return to Client'),
      [DeliveryTaskUnitStatus.Delivered]: t('Delivered'),
      [DeliveryTaskUnitStatus.ShippedToClient]: t('Shipped to Client'),
      [DeliveryTaskUnitStatus.Stored]: t('Stored'),
      [DeliveryTaskUnitStatus.StoredLocal]: t('Stored local'),
      [DeliveryTaskUnitStatus.Cancelled]: t('Canceled'),
      [DeliveryTaskUnitStatus.Lost]: t('Lost'),
    }),
    [t],
  );
};

export default useDeliveryUnitStatusTranslations;
