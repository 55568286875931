import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link, useLocation, useParams } from 'react-router';

import Button from '../../components/Button';
import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import UserRole from '../../enums/UserRole';
import Workflow from '../../enums/Workflow';
import BasicInformationCard from '../../features/clients/BasicInformationCard';
import DeleteClient from '../../features/clients/DeleteClient';
import OrganisationAlternativeOptionsCard from '../../features/organisations/OrganisationAlternativeOptionsCard';
import OrganisationDetailsPlatformFeaturesCard from '../../features/organisations/OrganisationDetailsPlatformFeaturesCard';
import OrganisationDetailsVolumeBundling from '../../features/organisations/OrganisationDetailsVolumeBundling';
import OrganisationEmailCustomisation from '../../features/organisations/OrganisationEmailCustomisation';
import MembersCard from '../../features/shared/MembersCard';
import OrganisationDetailsSectionCard from '../../features/shared/OrganisationDetailsSectionCard';
import useFetch from '../../lib/api/hooks/useFetch';
import Allow from '../../lib/rbac/Allow';
import { useIsRole } from '../../providers/UserProvider';

const ClientDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { fetch } = useFetch();
  const { state } = useLocation();

  const isSuperadmin = useIsRole(UserRole.Superadmin);
  const isAdmin = useIsRole(UserRole.Admin);

  const { data, error, isError, isPending } = useQuery({
    queryKey: [`/clients/`, id],
    queryFn: async () => {
      const response = await fetch(`/clients/${id}`, {
        method: 'GET',
      });
      return response.json();
    },
    retry: false,
  });

  const organisation = data?.data || null;

  const backLink = useMemo(() => {
    if (state?.backLink) {
      return state?.backLink;
    }

    return isSuperadmin
      ? `/organisations/${organisation?.carrier?.id}/clients`
      : '/my-organisation/clients';
  }, [state?.backLink, isSuperadmin, organisation?.carrier?.id]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  const title = organisation?.name.concat(' (', organisation?.shortCode, ')');

  return (
    <Page>
      <Titlebar
        backLink={backLink}
        icon={<Icon className="h-5 w-5 text-grey-700" icon="domain" />}
        textPrimary={title}
        isLoading={isPending}
      />

      <Page.Content variant="grey">
        <div className="flex flex-col gap-4">
          <div className={cn('grid gap-4')}>
            <MembersCard isCustomer organisation={organisation} />
          </div>

          <div>
            {isPending ? (
              <Skeleton wrapper={Card} height={214} />
            ) : (
              <BasicInformationCard
                organisation={organisation}
                topRightContent={
                  isAdmin ? (
                    <Button
                      as={Link}
                      className="w-full sm:w-fit"
                      icon="edit"
                      state={{
                        backLink: `/clients/${organisation.id}`,
                      }}
                      text={t('Edit')}
                      to="edit"
                      variant="outlineBlack"
                    />
                  ) : null
                }
              />
            )}
          </div>

          <Allow roles={[UserRole.Admin, UserRole.Superadmin]}>
            {isPending ? (
              <Skeleton wrapper={Card} height={160} />
            ) : (
              <OrganisationDetailsSectionCard title={t('Settings')}>
                <OrganisationDetailsPlatformFeaturesCard
                  organisationId={organisation?.id}
                  organisationType={organisation?.type}
                />
                {organisation.carrier.workflow === Workflow.Operations && (
                  <>
                    <OrganisationEmailCustomisation />
                    <OrganisationDetailsVolumeBundling
                      disabled={isSuperadmin}
                      organisation={organisation}
                    />
                    <OrganisationAlternativeOptionsCard />
                  </>
                )}
                <Allow roles={[UserRole.Admin]}>
                  <DeleteClient organisation={organisation} />
                </Allow>
              </OrganisationDetailsSectionCard>
            )}
          </Allow>
        </div>
      </Page.Content>
    </Page>
  );
};

export default ClientDetails;
