import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Tooltip from '../../../components/Tooltip';
import Field from './Field';

const UnitsInput = (props) => {
  const { name } = props;

  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const unitCode = watch(name);

  const { append, fields, remove } = useFieldArray({
    control,
    name,
  });

  const isDisabled =
    (errors[name] && errors[name].length > 0) ||
    unitCode.some(
      (unit) =>
        unit.reference === '' ||
        (unit.isSpecialHandling && unit.specialHandling?.length === 0),
    );

  return (
    <div className="flex flex-col gap-4">
      {fields.map((field, index) => (
        <Field
          name={name}
          index={index}
          id={field.id}
          key={field.id}
          onRemoveClick={() => remove(index)}
        />
      ))}
      <div>
        <Tooltip
          hidden={!isDisabled}
          text={t(
            'To add more Units, complete the already added additional Unit field',
          )}
        >
          <Button
            text={t('Add More')}
            variant="outlineBlack"
            size="s"
            iconSize="s"
            icon="plus"
            disabled={isDisabled}
            onClick={() => {
              append({
                reference: '',
                isSpecialHandling: false,
                specialHandling: [],
              });
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

UnitsInput.propTypes = {
  name: PropTypes.string,
};

UnitsInput.defaultProps = {
  name: '',
};

export default UnitsInput;
