import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import Button from '../../../components/Button';
import DebouncedSearch from '../../../components/DebouncedSearch';
import Page from '../../../components/Page';
import Titlebar from '../../../components/Titlebar';
import OrganisationType from '../../../enums/OrganisationType';
import useMembersPageSearchParams from '../../../hooks/useMembersPageSearchParams';
import Resource from '../../../lib/api/Resource';
import MembersTable from '../../shared/MembersTable';

const OrganisationMembersPageDesktop = ({ isLoading, organisation }) => {
  const { t } = useTranslation();
  const { allParams, searchParams, searchQuery, setSearchParams } =
    useMembersPageSearchParams();

  const isSuperadminOrganisation =
    organisation?.type === OrganisationType.Superadmin;

  return (
    <Page className="max-h-screen">
      <Titlebar
        variant="small"
        backLink={
          isSuperadminOrganisation
            ? '/organisations'
            : `/organisations/${organisation?.id}`
        }
        menu={
          <div className="flex">
            <DebouncedSearch
              className="max-w-[200px]"
              placeholder={`${t('Find member')}...`}
              searchValue={searchQuery}
              onSearch={(newSearchValue) => {
                if (newSearchValue === searchQuery) {
                  return;
                }
                searchParams.set('page', '1');
                if (!newSearchValue) {
                  searchParams.delete('search');
                } else {
                  searchParams.set('search', newSearchValue?.trim());
                }
                setSearchParams(searchParams);
              }}
            />
          </div>
        }
        textPrimary={t('Members')}
        textSecondary={organisation?.name}
        isLoading={isLoading}
        titleRightContent={
          <Button
            as={Link}
            className="w-full lg:w-fit"
            icon="userAdd"
            text={t('New Member')}
            to="new"
            variant="solidBlue"
          />
        }
      />
      <Page.Content variant="wide">
        <div className="flex flex-col min-h-0">
          <Resource
            paginationStickyBottom
            itemsPerPage={100}
            showPageSizePicker={false}
            params={{ ...allParams, carrierId: organisation?.id }}
            resourceUrl="/users"
          >
            {organisation && <MembersTable organisation={organisation} />}
          </Resource>
        </div>
      </Page.Content>
    </Page>
  );
};

OrganisationMembersPageDesktop.propTypes = {
  organisation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};

OrganisationMembersPageDesktop.defaultProps = {
  organisation: undefined,
  isLoading: false,
};

export default OrganisationMembersPageDesktop;
