import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useFormInputValidationRules = ({
  max,
  maxLength,
  min,
  minLength,
  pattern,
  required,
  type,
  url,
  validate,
}) => {
  const { t } = useTranslation();
  return useCallback(() => {
    const validators = {};
    if (required) {
      const message = t('This field is required.');
      validators.required = message;
      // needed because react hook form considers spaces as non-empty input, as per HTML5 standard
      // see https://github.com/react-hook-form/react-hook-form/issues/1650#issuecomment-709665426
      validators.validate = (value) =>
        value.toString().trim() === '' ? message : true;
    } else {
      validators.required = false;
      validators.validate = undefined;
    }

    if (type === 'number') {
      validators.pattern = {
        message: `${t('Please enter a number.')}`,
        value: /^[0-9]+$/,
      };
    }
    if (type === 'email') {
      validators.pattern = {
        message: t('Please enter valid e-mail.'),
        value:
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      };
    }
    if (url) {
      validators.pattern = {
        message: t('Please enter valid url.'),
        value:
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i,
      };
    }
    if (pattern) {
      validators.pattern = pattern;
    }

    if (minLength) {
      validators.minLength = minLength;
    }
    if (maxLength) {
      validators.maxLength = maxLength;
    }
    if (min !== undefined) {
      validators.min = {
        value: min,
        message: t('This number should be at least {{min}}.', {
          min: min.toString(),
        }),
      };
    }

    if (max !== undefined) {
      validators.max = {
        value: max,
        message: t('This number should not be larger than {{max}}.', {
          max: max.toString(),
        }),
      };
    }

    if (validate) {
      validators.validate = validate;
    }
    return validators;
  }, [
    required,
    type,
    url,
    pattern,
    minLength,
    maxLength,
    min,
    max,
    validate,
    t,
  ]);
};

export default useFormInputValidationRules;
