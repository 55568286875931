import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router';

import Button from '../../components/Button';
import CancelPrompt from '../../components/CancelPrompt';
import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import OrganisationType from '../../enums/OrganisationType';
import UserRole from '../../enums/UserRole';
import MembersForm from '../../features/members/MembersForm';
import ObjectHelper from '../../helpers/ObjectHelper';
import useBlocker from '../../hooks/useBlocker';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';
import { useIsRole, useUser } from '../../providers/UserProvider';
import ConfirmMemberDeleteModal from './ConfirmMemberDeleteModal';

const trim = (str, chars) => str.split(chars).filter(Boolean).join(chars);

const EditMember = () => {
  const { t } = useTranslation();
  const { id: memberId } = useParams();
  const navigate = useNavigate();
  const { user: loggedInUser } = useUser();
  const { toastSuccess } = useCustomToast();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const [isDeleteMemberConfirmOpen, setIsDeleteMemberConfirmOpen] =
    useState(false);

  const {
    data: fetchedMember,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/users/${memberId}`],

    queryFn: async () => {
      const response = await fetch(`/users/${memberId}`);
      return response.json();
    },
  });

  const member = fetchedMember?.data;
  const isSuperadmin = useIsRole(UserRole.Superadmin);

  const mutation = useMutation({
    mutationFn: (values) => {
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        managingHubId:
          values.role === UserRole.HubManager ? values.managingHubId : null,
        phoneNumber: `+${trim(values.phoneNumber, '+')}`,
        role: values.role,
        subcarrierId: values.isSubcarrierChecked ? values.subcarrierId : null,
        ...(isSuperadmin && { organisationId: values.organisationId }),
        hubId:
          values.role === UserRole.Driver ||
          values.role === UserRole.HubManager ||
          values.role === UserRole.Crew
            ? values.hubId
            : null,
      };

      return fetch(`/users/${member.id}`, {
        body: ObjectHelper.removeNullableFields(body),
        method: 'PATCH',
      });
    },
    onError: toastFetchError,
    onSuccess: () => {
      setIsFormDirty(false);
      toastSuccess(t('Member data successfully updated.'));
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async () =>
      fetch(`/users/${member.id}`, {
        method: 'DELETE',
      }),
    onError: toastFetchError,
    onSuccess: () => {
      toastSuccess(
        t('Member {{member}} deleted.', {
          member: `${member.firstName} ${member.lastName}`,
        }),
      );
    },
  });

  const shouldBlock = useMemo(() => {
    if (mutation.isSuccess || deleteMutation.isSuccess) {
      return false;
    }
    return isFormDirty;
  }, [isFormDirty, mutation.isSuccess, deleteMutation.isSuccess]);

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (mutation.isSuccess) {
      navigate(`/members/${memberId}`, {
        replace: true,
      });
    }
  }, [memberId, mutation.isSuccess, navigate]);

  useEffect(() => {
    if (deleteMutation.isSuccess) {
      navigate('/my-organisation/members', {
        replace: true,
      });
    }
  }, [deleteMutation.isSuccess, navigate]);

  const isClient = !!member?.client;
  const isEditingUserSuperadmin = member?.role === UserRole.Superadmin;
  const organisationType = useMemo(() => {
    if (isClient) {
      return undefined;
    }
    if (isEditingUserSuperadmin) {
      return OrganisationType.Superadmin;
    }
    return OrganisationType.Carrier;
  }, [isClient, isEditingUserSuperadmin]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      <CancelPrompt
        title={t('Cancel Editing Member?')}
        isOpen={blocker.state === 'blocked'}
        onClose={() => blocker.reset()}
        onExitClick={() => blocker.proceed()}
      />
      <ConfirmMemberDeleteModal
        isOpen={isDeleteMemberConfirmOpen}
        onClose={() => setIsDeleteMemberConfirmOpen(false)}
        onConfirm={deleteMutation.mutate}
      />
      <Page>
        <Titlebar
          icon={<Icon className="h-5 w-5 text-ui-blue" icon="edit" />}
          textPrimary={t('Edit Member')}
          textSecondary={`${member?.firstName} ${member?.lastName}`}
          titleRightContent={
            <Button
              className="w-full lg:w-fit"
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => navigate(`/members/${memberId}`)}
            />
          }
          isLoading={isPending}
        />
        <Page.Content centerContent variant="grey">
          <div className="w-full max-w-[480px]">
            <Card className="px-4 py-5 sm:p-8">
              {isPending ? (
                <div className="grid gap-8">
                  <Skeleton height={156} />
                  <Skeleton height={98} />
                  <Skeleton height={110} />
                </div>
              ) : (
                <MembersForm
                  email={member?.email}
                  errors={mutation?.error?.data?.errors}
                  firstName={member?.firstName}
                  isCustomer={isClient}
                  isEdit
                  memberId={memberId}
                  isSubmitting={mutation.isPending}
                  lastName={member?.lastName}
                  loggedInUserRole={loggedInUser.role}
                  managingHubId={member?.managingHub?.id}
                  organisationId={
                    isClient ? member?.client?.id : member?.carrier?.id
                  }
                  organisationName={
                    isClient ? member?.client?.name : member?.carrier?.name
                  }
                  organisationType={organisationType}
                  phoneNumber={member?.phoneNumber}
                  role={member?.role}
                  subcarrier={member?.subcarrier}
                  hubId={member?.hub?.id}
                  onCancel={() => navigate(`/members/${memberId}`)}
                  onIsDirtyChange={setIsFormDirty}
                  onSubmit={mutation.mutate}
                  onMemberDelete={() => setIsDeleteMemberConfirmOpen(true)}
                />
              )}
            </Card>
          </div>
        </Page.Content>
      </Page>
    </>
  );
};

export default EditMember;
