import moment from 'moment';
import { useTranslation } from 'react-i18next';

import UnitEventType from '../../../../enums/UnitEventType';
import eventType from '../eventType';
import ExecutionField from './ExecutionField';
import NoteField from './NoteField';
import UserField from './UserField';

const TimeSlotUpdateEvent = (props) => {
  const { event, previousEventWithExecutionDate } = props;
  const { t } = useTranslation();
  return (
    <>
      <ExecutionField event={event} />
      {event.changeNote && <NoteField note={event.changeNote} />}
      {previousEventWithExecutionDate &&
        previousEventWithExecutionDate.timeFrom &&
        previousEventWithExecutionDate.timeTo &&
        moment(previousEventWithExecutionDate.timeFrom).isSame(
          previousEventWithExecutionDate.timeTo,
          'day',
        ) && (
          <div>
            <span>{t('Previous execution')}: </span>
            <span className="font-medium">
              {moment(previousEventWithExecutionDate.timeFrom).format(
                'DD.MM.YYYY',
              )}
              {' - '}
              {previousEventWithExecutionDate.shiftNumber &&
                `S${previousEventWithExecutionDate.shiftNumber} `}
              ({moment(previousEventWithExecutionDate.timeFrom).format('HH:mm')}
              -{moment(previousEventWithExecutionDate.timeTo).format('HH:mm')})
            </span>
          </div>
        )}

      {event.endCustomerNoticeSent !== null &&
        event.endCustomerNoticeSent !== undefined && (
          <div>
            {t('Customer notice:')}{' '}
            <span className="font-medium">
              {event.endCustomerNoticeSent ? t('Sent') : t('Not sent')}
            </span>
          </div>
        )}

      {event.type === UnitEventType.TrackingTimeSlotChange && (
        <div className="text-grey-700 text-sm">{t('Customer')}</div>
      )}

      {event.type === UnitEventType.TimeSlotChange && (
        <UserField event={event} />
      )}
    </>
  );
};

TimeSlotUpdateEvent.propTypes = {
  event: eventType.isRequired,
  previousEventWithExecutionDate: eventType,
};

TimeSlotUpdateEvent.defaultProps = {
  previousEventWithExecutionDate: undefined,
};
export default TimeSlotUpdateEvent;
