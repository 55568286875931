import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useParams } from 'react-router';

import Meta from '../../app/Meta';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import FadingOverlay from '../../components/FadingOverlay';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import Breakpoint from '../../enums/Breakpoint';
import UserRole from '../../enums/UserRole';
import CustomerFixedTourDetails from '../../features/fixed-tours/CustomerFixedTourDetails';
import FixedTourDetails from '../../features/fixed-tours/FixedTourDetails';
import TourAlert from '../../features/tours/TourAlert';
import TourHelper from '../../helpers/TourHelper';
import { AsyncStatus } from '../../hooks/useAsyncFn';
import useWindowSize from '../../hooks/useWindowSize';
import useDownloadBlob from '../../lib/api/hooks/useDownloadBlob';
import useFetch from '../../lib/api/hooks/useFetch';
import Allow from '../../lib/rbac/Allow';

const PageSkeleton = () => (
  <Page.Content variant="grey">
    <div className="grid gap-4 lg:grid-cols-2">
      <div className="flex flex-col gap-4">
        <Card>
          <Skeleton count={1} height={58} />
        </Card>
        <Card>
          <Skeleton count={1} height={72} />
        </Card>
        <Card>
          <FadingOverlay>
            <div className="flex flex-col gap-4">
              <Skeleton height={120} />
              <Skeleton height={120} />
              <Skeleton height={120} />
            </div>
          </FadingOverlay>
        </Card>
      </div>

      <div className="flex flex-col gap-4">
        <Card>
          <Skeleton count={1} height={140} />
        </Card>
        <Card>
          <Skeleton count={1} height={132} />
        </Card>
        <Card>
          <FadingOverlay>
            <div className="flex flex-col gap-4">
              <Skeleton height={44} />
              <Skeleton height={44} />
              <Skeleton height={44} />
            </div>
          </FadingOverlay>
        </Card>
      </div>
    </div>
  </Page.Content>
);

const TourDetails = () => {
  const { fetch } = useFetch();
  const { id } = useParams();
  const { state } = useLocation();
  const { t } = useTranslation();

  const {
    data: tourQueryData,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/tours/${id}`],

    queryFn: async () => {
      const response = await fetch(`/tours/${id}`, {
        method: 'GET',
      });
      return response.json();
    },
  });

  const tour = tourQueryData?.data;
  const carrierFeatures = tourQueryData?.carrierFeatures;

  const total = tour?.totalStops || 0;
  const completed = tour?.completedStops || 0;

  const pageTitle = useMemo(() => {
    if (!tour) {
      return null;
    }

    return `(${completed}/${total}) - ${tour?.vehicle || ''}`;
  }, [completed, total, tour]);

  const backLink = useMemo(() => {
    if (state?.backLink) {
      return state?.backLink;
    }
    return '/tours/fixed';
  }, [state?.backLink]);

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;

  const [getTourPdfStatus, getTourPdf] = useDownloadBlob();

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <Meta title={pageTitle} />
      <Titlebar
        backLink={backLink}
        generateMeta={false}
        isLoading={isPending}
        textPrimary={tour ? TourHelper.getTourCode(tour) : undefined}
        titleRightContent={
          <Button
            className="print:hidden"
            icon="dataExport"
            variant="outlineBlack"
            text={t('Tour Data Export')}
            onClick={async () => {
              await getTourPdf({
                url: `/exports/tours/${tour.id}`,
                fileName: TourHelper.getTourCode(tour),
              });
            }}
            disabled={getTourPdfStatus === AsyncStatus.Loading}
          />
        }
      />

      {isPending ? (
        <PageSkeleton />
      ) : (
        <Page.Content variant={isDesktop ? 'white' : 'grey'}>
          {tour && <TourAlert tour={tour} />}

          <div className="flex-1 rounded-md bg-grey-100 lg:p-4">
            <Allow roles={[UserRole.Customer]}>
              <CustomerFixedTourDetails tour={tour} />
            </Allow>
            <Allow
              roles={[
                UserRole.Admin,
                UserRole.OperationsManager,
                UserRole.HubManager,
              ]}
            >
              <FixedTourDetails carrierFeatures={carrierFeatures} tour={tour} />
            </Allow>
          </div>
        </Page.Content>
      )}
    </Page>
  );
};

export default TourDetails;
