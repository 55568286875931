import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import useSentry from '../hooks/useSentry';
import GuestLayout from '../layout/GuestLayout';
import { useUser } from '../providers/UserProvider';

const GuestApp = () => {
  const location = useLocation();
  const fromLocation = location?.state?.from;
  const { addBreadcrumbMessage } = useSentry('GuestApp');
  const navigate = useNavigate();
  const [shouldNavigate, setShouldNavigate] = useState(false);

  const redirectUrl = useMemo(() => {
    if (fromLocation?.pathname && fromLocation?.search) {
      return `${fromLocation?.pathname}${fromLocation?.search}`;
    }
    if (fromLocation?.pathname) {
      return fromLocation?.pathname;
    }
    return '/';
  }, [fromLocation?.pathname, fromLocation?.search]);

  const { user } = useUser();

  useEffect(() => {
    if (user) {
      setShouldNavigate(true);
    }
  }, [user]);

  useEffect(() => {
    if (shouldNavigate) {
      addBreadcrumbMessage(
        'User already authenticated, navigate to redirectUrl',
      );

      navigate(redirectUrl, {
        replace: true,
      });
    }
  }, [shouldNavigate, redirectUrl, addBreadcrumbMessage, navigate]);

  if (user) {
    // disallow authenticated user access to guest routes
    return null;
  }

  return <GuestLayout />;
};

export default GuestApp;
