import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import OrganisationPlatformFeaturesList from '../../features/shared/OrganisationPlatformFeaturesList';
import useFetch from '../../lib/api/hooks/useFetch';

const ClientPlatformFeatures = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { fetch } = useFetch();
  const { data, error, isError, isPending } = useQuery({
    queryKey: [`/clients/`, id],

    queryFn: async () => {
      const response = await fetch(`/clients/${id}`, {
        method: 'GET',
      });
      return response.json();
    },
  });

  const organisation = data?.data || null;

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <Titlebar
        backLink={`/clients/${id}`}
        textPrimary={t('Manage Platform Features')}
        textSecondary={organisation?.name}
        isLoading={isPending}
      />
      <Page.Content variant="grey">
        <OrganisationPlatformFeaturesList isClient organisationId={id} />
      </Page.Content>
    </Page>
  );
};

export default ClientPlatformFeatures;
