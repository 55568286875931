import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

const ServiceWorkerUpdateChecker = () => {
  const swRegistration = useRef(null);
  const [isLocationReady, setIsLocationReady] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!isLocationReady && location) {
      setIsLocationReady(true);
    }
  }, [isLocationReady, location]);

  const acquireRegistrationRef = useCallback(async () => {
    try {
      const registration = await navigator.serviceWorker.getRegistration();

      if (registration) {
        swRegistration.current = registration;

        // skip update if not online
        if ('connection' in navigator && !navigator.onLine) {
          return;
        }

        // prevent InvalidStateError for when the newestWorker is null
        // see: https://www.w3.org/TR/service-workers/#service-worker-registration-update
        if (
          registration.installing === null &&
          registration.waiting === null &&
          registration.active === null
        ) {
          return;
        }

        try {
          await swRegistration.current.update();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error triggering Service Worker update:', e);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error getting Service Worker registration:', e);
    }
  }, []);

  // Trigger Service Worker update on location pathname change
  useEffect(() => {
    async function checkRegistration() {
      if (!swRegistration.current) {
        acquireRegistrationRef();
      }

      // location.pathname must be a dependency, otherwise service worker update check will not run on SPA page changes.
      // This is just a way to trigger "The user navigates to a page within the service worker's scope." within a spa because
      // page changes triggered with history api are not considered navigation changes.
      // read: https://developer.chrome.com/docs/workbox/service-worker-lifecycle#when_updates_happen
      if (swRegistration.current && location.pathname) {
        // prevent InvalidStateError for when the newestWorker is null
        // see: https://www.w3.org/TR/service-workers/#service-worker-registration-update
        if (
          swRegistration.current.installing === null &&
          swRegistration.current.waiting === null &&
          swRegistration.current.active === null
        ) {
          return;
        }

        try {
          await swRegistration.current.update();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error triggering Service Worker update:', e);
        }
      }
    }

    checkRegistration();
  }, [acquireRegistrationRef, location.pathname]);
};

ServiceWorkerUpdateChecker.propTypes = {
  children: PropTypes.node,
};

ServiceWorkerUpdateChecker.defaultProps = {
  children: undefined,
};

export default ServiceWorkerUpdateChecker;
