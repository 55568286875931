import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router';

import useLogout from '../hooks/useLogout';
import useSentry from '../hooks/useSentry';
import { useUser } from '../providers/UserProvider';

const ProtectedApp = () => {
  const location = useLocation();
  const { isUserValid, user } = useUser();
  const logout = useLogout();
  const { t } = useTranslation();
  const { addBreadcrumbMessage } = useSentry('ProtectedApp');
  const navigate = useNavigate();
  const [shouldNavigate, setShouldNavigate] = useState(false);

  useEffect(() => {
    if (!user) {
      setShouldNavigate(true);
    }
  }, [user]);

  useEffect(() => {
    if (shouldNavigate) {
      addBreadcrumbMessage('No user, redirect to /login');

      navigate('/login', {
        replace: true,
        state: { from: location },
      });
    }
  }, [addBreadcrumbMessage, location, navigate, shouldNavigate]);

  useEffect(() => {
    if (user && !isUserValid) {
      addBreadcrumbMessage('User logged in, but invalid role, logging out');
      logout({ message: t('User logged out. Please log in again.') });
    }
  }, [addBreadcrumbMessage, isUserValid, logout, t, user]);

  // disallow unauthenticated user access to protected routes
  if (!user) {
    return null;
  }

  return <Outlet />;
};

export default ProtectedApp;
