import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Button from '../../components/Button';
import { Card } from '../../components/Card';
import FormDatePicker from '../../components/FormDatePicker';
import FormInput from '../../components/FormInput';
import FormMultiSelect from '../../components/FormMultiSelect';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';
import { useUser } from '../../providers/UserProvider';

const StartRouting = () => {
  const { toastInfo, toastSuccess } = useCustomToast();
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const { user } = useUser();
  const carrierId = user?.carrier?.id;

  const mutation = useMutation({
    mutationFn: async (values) => {
      const { optimisationDuration, shiftIds } = values;

      const body = {
        date: moment(values.date).format('YYYY-MM-DD'),
        shiftIds,
        optimisationDuration: Number(optimisationDuration),
      };

      const response = await fetch(`/routing/start`, {
        body,
        method: 'POST',
      });
      return response.json();
    },
    onError: (error) => toastFetchError(error),
    onSuccess: (response) => {
      const message = response?.data;

      if (typeof message === 'string') {
        toastInfo(message);
      } else {
        toastSuccess('Routing started.');
      }
    },
  });
  const methods = useForm({
    defaultValues: {
      date: new Date(),
      shiftIds: [],
      optimisationDuration: 30,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const { handleSubmit } = methods;

  const { data: shiftsData } = useQuery({
    queryKey: [`/carriers/${carrierId}/shifts`],
    queryFn: async () => {
      const response = await fetch(`/carriers/${carrierId}/shifts`, {
        method: 'GET',
      });
      return response.json();
    },
  });

  const shifts = useMemo(() => shiftsData?.data || [], [shiftsData?.data]);

  const shiftOptions = useMemo(
    () =>
      shifts
        .sort((shiftA, shiftB) => shiftA.hub.id.localeCompare(shiftB.hub.id))
        .map((item) => ({
          label: `Shift ${item.number} - ${item.hub.name} [${item.hub.shortCode}] (${item.startTime}-${item.endTime})`,
          value: item.id,
        })),
    [shifts],
  );

  return (
    <Page>
      <Titlebar textPrimary="Start Routing" />
      <Page.Content variant="grey">
        <Card className="m-auto mt-4 w-full max-w-[480px] px-4 py-5 sm:p-8">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(mutation.mutate)}>
              <h3 className="mb-5 text-base font-semibold text-primary-dark">
                Start routing manually
              </h3>

              <div className="flex flex-col gap-4">
                <FormDatePicker
                  dateFormat="dd.MM.yyyy"
                  label="Date"
                  minDate={new Date()}
                  name="date"
                  id="date"
                  placeholderText="Routing date"
                  required
                />

                <FormMultiSelect
                  id="shifts"
                  label="Shifts"
                  name="shiftIds"
                  options={shiftOptions}
                  required
                />

                <FormInput
                  label="Optimisation duration (seconds)"
                  inlineLabel="seconds"
                  name="optimisationDuration"
                  id="optimisationDuration"
                  required
                  min={30}
                  max={900}
                  type="number"
                  valueAsNumber
                />

                <Button
                  isFullWidth
                  text="Submit"
                  type="submit"
                  variant="solidBlack"
                  disabled={mutation.isPending}
                />
              </div>
            </form>
          </FormProvider>
        </Card>
      </Page.Content>
    </Page>
  );
};

export default StartRouting;
