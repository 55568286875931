import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router';

import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import ScannerType from '../../enums/ScannerType';
import DemoCodeScan from '../../features/package-scanning/DemoCodeScan';
import PrintScannerConfiguration from '../../features/package-scanning/PrintScannerConfiguration';
import ScannerSetupModal from '../../features/package-scanning/ScannerSetupModal';

const Item = ({ number, text }) => (
  <div className="flex gap-2 text-sm font-normal text-primary-dark">
    <span className="inline-block h-5 w-5 flex-shrink-0 rounded-sm bg-grey-200 text-center font-semibold text-primary-dark">
      {number}
    </span>
    <span>{text}</span>
  </div>
);

Item.propTypes = {
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

const ScannerConfiguration = (props) => {
  const { scannerType } = props;
  const { t } = useTranslation();
  // const [scannerType, setScannerType] = useState(ScannerType.SymbolLS2208);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <Page>
      <Titlebar
        icon={<Icon className="h-5 w-5 text-ui-blue" icon="scanning" />}
        textPrimary={t('Scanning Configuration')}
        titleRightContent={
          <Button
            as={Link}
            text={t('Cancel')}
            variant="outlineBlack"
            to="/package-scanning"
          />
        }
      />

      <Page.Content variant="grey">
        <div className="mx-auto max-w-[480px]">
          <div className="flex flex-col gap-8 rounded-md bg-white p-8 shadow-elevation-100">
            <div className="flex items-center justify-between rounded-md border-[1px] border-grey-200 p-4 text-sm">
              <div className="pr-2.5">
                <span className="text-grey-700">
                  {t('Configuring device:')}{' '}
                </span>
                <span className="font-medium text-primary-dark">
                  {scannerType}
                </span>
              </div>
              <Button
                text={t('Change Scanner')}
                variant="ghostBlue"
                onClick={() => setIsModalOpen(true)}
              />
            </div>
            <div className="text-sm">
              {t('To configure scanning device follow steps below')}:
            </div>
            <div className="flex flex-col gap-5">
              <h2 className="text-base font-semibold text-primary-dark">
                1. {t('Print the configuration label')}
              </h2>
              <PrintScannerConfiguration scannerType={scannerType} />
            </div>

            <div className="flex flex-col gap-5">
              <h2 className="text-base font-semibold text-primary-dark">
                2. {t('Follow the instructions')}
              </h2>
              <div className="flex flex-col gap-4">
                {scannerType === ScannerType.SymbolLS2208 ? (
                  <>
                    <Item
                      number={1}
                      text={t('Scan “Set Factory Defaults” barcode')}
                    />
                    <Item number={2} text={t('Scan “Enter Key” barcode')} />
                    <Item
                      number={3}
                      text={t(
                        'Scan “German” or “US English” barcode, depending on your keyboard layout',
                      )}
                    />
                    <Item
                      number={4}
                      text={t('Scan “Override Caps Lock Key” barcode')}
                    />
                  </>
                ) : (
                  <>
                    <Item number={1} text={t('Scan “Enter Setup” barcode')} />
                    <Item
                      number={2}
                      text={t(
                        'Scan  “US Keyboard” or “DE Keyboard” barcode, depending on your keyboard layout',
                      )}
                    />
                    <Item
                      number={3}
                      text={t(
                        "Optional - Scan “Enable Manual Scanning Mode” or “Enable Auto Induction Mode” (under this mode, you don't have to press the trigger) barcode, depending on scanning preference",
                      )}
                    />
                    <Item
                      number={4}
                      text={t('Scan “Exit with Save” barcode')}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-5">
              <h2 className="text-base font-semibold text-primary-dark">
                3. {t('Do a test scan')}
              </h2>
              <DemoCodeScan />
            </div>

            <div className="flex items-center justify-between">
              <Button
                as={Link}
                text={t('Cancel')}
                to="/package-scanning"
                variant="outlineBlack"
              />
              <Button
                as={Link}
                text={t('Save and Finish')}
                to="/package-scanning"
                variant="solidBlue"
              />
            </div>
          </div>
        </div>
      </Page.Content>

      <ScannerSetupModal
        onClose={() => {
          setIsModalOpen(false);
        }}
        scannerType={scannerType}
        isOpen={isModalOpen}
        onSubmit={(newScannerType) => {
          if (newScannerType === ScannerType.SymbolLS2208) {
            navigate('/package-scanning/scanner-configuration/symbol');
          } else {
            navigate('/package-scanning/scanner-configuration/inateck');
          }
          setIsModalOpen(false);
        }}
      />
    </Page>
  );
};

ScannerConfiguration.propTypes = {
  scannerType: PropTypes.oneOf(Object.values(ScannerType)).isRequired,
};

export default ScannerConfiguration;
