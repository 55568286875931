import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import DrawerCard from '../../../components/Drawer/DrawerCard';
import DrawerTextRow from '../../../components/Drawer/DrawerTextRow';
import Icon from '../../../components/Icon';
import TourStopStatus from '../../../enums/TourStopStatus';
import tourStopPropType from '../../../prop-types/tourStopPropType';

const TourStopInfoCard = (props) => {
  const { tourStop } = props;
  const { t } = useTranslation();

  const unitCount = tourStop.tasks.reduce(
    (acc, task) => acc + task.tourStopUnits.length,
    0,
  );

  const driverActionText = () => {
    if (tourStop.status === TourStopStatus.Attempted) {
      return t('Attempted at');
    }

    if (tourStop.status === TourStopStatus.Withdrawn) {
      return t('Postponed at');
    }

    return t('Executed at');
  };

  return (
    <DrawerCard
      border={false}
      className={cn({
        border: true,
        'border-transparent': tourStop.status !== TourStopStatus.Pending,
        'border-grey-200': tourStop.status === TourStopStatus.Pending,
        'bg-ui-green-dark-light': tourStop.status === TourStopStatus.Completed,
        'bg-ui-red-light': tourStop.status === TourStopStatus.Withdrawn,
        'bg-ui-notification-yellow-light':
          tourStop.status === TourStopStatus.Attempted,
      })}
      title={t(`Tour Stop {{sequence}}`, {
        sequence: tourStop.sequence,
      })}
    >
      <div className="flex flex-col gap-2 pt-2">
        <DrawerTextRow
          title={t('Tour Stop Status')}
          content={
            <span className="flex flex-row items-center gap-2">
              {tourStop.status === TourStopStatus.Pending && (
                <>
                  <Icon className="h-5 w-5 text-grey-700" icon="tourStop" />
                  {t('Pending')}
                </>
              )}
              {tourStop.status === TourStopStatus.Completed && (
                <>
                  <Icon
                    className="h-5 w-5 text-ui-green-dark"
                    icon="tourStop"
                  />
                  {t('Completed')}
                </>
              )}
              {tourStop.status === TourStopStatus.Attempted && (
                <>
                  <Icon
                    className="h-5 w-5 text-ui-notification-yellow"
                    icon="tourStop"
                  />
                  {t('Attempted')}
                </>
              )}
              {tourStop.status === TourStopStatus.Withdrawn && (
                <>
                  <Icon className="h-5 w-5 text-ui-red" icon="tourStopCancel" />
                  {t('Withdrawn')}
                </>
              )}
            </span>
          }
        />

        {tourStop.driverActionTime && (
          <DrawerTextRow
            title={
              <div className="flex flex-row items-center gap-2">
                <Icon className="h-4 w-4 " icon="flagOutlined" />
                <span>{driverActionText()}</span>
              </div>
            }
            content={
              <span>{moment(tourStop.driverActionTime).format('HH:mm')}</span>
            }
          />
        )}

        <DrawerTextRow
          title={t('ETA')}
          content={
            <span>
              <span className="font-semibold">
                {moment(tourStop.eta).format('HH:mm')}
              </span>{' '}
              ({moment(tourStop.windowFrom).format('HH:mm')} -{' '}
              {moment(tourStop.windowTo).format('HH:mm')})
            </span>
          }
        />

        <DrawerTextRow
          title={t('Task Count')}
          content={tourStop.tasks.length}
        />

        <DrawerTextRow title={t('Unit Count')} content={unitCount} />
      </div>
    </DrawerCard>
  );
};

TourStopInfoCard.propTypes = {
  tourStop: tourStopPropType.isRequired,
};

export default TourStopInfoCard;
