import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import ErrorPage from '../../components/ErrorPage';
import Breakpoint from '../../enums/Breakpoint';
import OrganisationsShiftsPageDesktop from '../../features/shifts/OrganisationsShiftsPage/OrganisationsShiftsPageDesktop';
import OrganisationsShiftsPageMobile from '../../features/shifts/OrganisationsShiftsPage/OrganisationsShiftsPageMobile';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';

const OrganisationShifts = () => {
  const { width } = useWindowSize();
  const isMobile = width < Breakpoint.LG;
  const { fetch } = useFetch();
  const { id } = useParams();

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/carriers/${id}`],

    queryFn: async () => {
      const response = await fetch(`/carriers/${id}`);
      return response.json();
    },
  });

  const organisation = fetchedOrganisation?.data || null;

  if (isError) {
    return <ErrorPage error={error} />;
  }

  if (!organisation) {
    return null;
  }

  if (isMobile) {
    return (
      <OrganisationsShiftsPageMobile
        organisation={organisation}
        isLoading={isPending}
      />
    );
  }
  return (
    <OrganisationsShiftsPageDesktop
      organisation={organisation}
      isLoading={isPending}
    />
  );
};

export default OrganisationShifts;
