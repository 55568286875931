import * as Sentry from '@sentry/react';
import { useCallback } from 'react';

import UserHelper from '../helpers/UserHelper';
import getCurrentLanguage from '../translations/getCurrentLanguage';

const useSentry = (context = '') => {
  const setSentryUser = useCallback((user) => {
    const username = UserHelper.getInitials(user);
    const userData = { role: user.role, username };
    // eslint-disable-next-line no-console
    console.log('set sentry user', userData);
    Sentry.setUser(userData);
  }, []);

  const unsetSentryUser = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('unset sentry user');
    Sentry.setUser(null);
  }, []);

  const showReportDialog = useCallback((eventId) => {
    Sentry.showReportDialog({ eventId, lang: getCurrentLanguage() });
  }, []);

  const sendSentryMessage = useCallback((message) => {
    Sentry.captureMessage(message);
  }, []);

  const addBreadcrumbMessage = useCallback(
    (message = '') => {
      const fullMessage =
        typeof context === 'string' && context !== ''
          ? `${context} - ${message}`
          : message;

      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.log('[useSentry] addBreadcrumb:', fullMessage);
      }

      Sentry.addBreadcrumb({
        message: fullMessage,
        level: 'info',
      });
    },
    [context],
  );

  return {
    setSentryUser,
    showReportDialog,
    unsetSentryUser,
    sendSentryMessage,
    addBreadcrumbMessage,
  };
};

export default useSentry;
