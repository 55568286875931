import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';
import useCustomToast from '../../../../hooks/useCustomToast';

const FailedTasksModal = ({ failedIds, isOpen, onClose, unitsUpdated }) => {
  const { t } = useTranslation();
  const copyToClipboard = useCopyToClipboard();
  const { toastSuccess } = useCustomToast();

  // eslint-disable-next-line no-unused-vars
  const _taskTranslation = t('tasksTerm_one');
  // eslint-disable-next-line no-unused-vars
  const _tasksTranslation = t('tasksTerm_other');

  const translate = t;
  // const translate is for ignoring translate script
  const taskTranslationString = translate('tasksTerm', {
    count: 1,
  });

  return (
    <Modal
      body={
        <>
          <p>{t('Following Tasks cannot be updated due to errors:')}</p>
          <p>{failedIds.join(', ')}</p>
        </>
      }
      footer={
        <div className="">
          <Button
            isFullWidth
            data-test="modal-button-action"
            text={t('Copy Codes to Clipboard and Close')}
            variant="solidBlue"
            onClick={async () => {
              try {
                await copyToClipboard(failedIds.join(', '));
                onClose();
                toastSuccess(t('Task codes copied to clipboard.'));
                return undefined;
              } catch (_e) {
                return undefined;
              }
            }}
          />
        </div>
      }
      header={
        <div>
          {t(
            '{{successNumber}} {{taskTranslation}} updated, {{failedNumber}} failed',
            {
              successNumber: String(unitsUpdated),
              failedNumber: failedIds.length,
              taskTranslation: taskTranslationString,
            },
          )}
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

FailedTasksModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  failedIds: PropTypes.arrayOf(PropTypes.string),
  unitsUpdated: PropTypes.number,
};

FailedTasksModal.defaultProps = {
  failedIds: [],
  unitsUpdated: 0,
};

export default FailedTasksModal;
