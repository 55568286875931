// order is important
import 'react-toastify/dist/ReactToastify.css';
import './Notifications.css';

import { ToastContainer } from 'react-toastify';

const Notifications = () => (
  <ToastContainer
    autoClose={3000}
    className="js-toast-container"
    draggable
    pauseOnFocusLoss={false}
    pauseOnHover
    position="top-center"
    theme="colored"
    limit={2}
  />
);

export default Notifications;
