import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router';

import Button from '../../components/Button';
import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import UserRole from '../../enums/UserRole';
import HubOverview from '../../features/hubs/HubOverview';
import useFetch from '../../lib/api/hooks/useFetch';
import { useIsRole } from '../../providers/UserProvider';

const HubDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const isAdmin = useIsRole(UserRole.Admin);
  const isSuperadmin = useIsRole(UserRole.Superadmin);
  const { state } = useLocation();

  const { fetch } = useFetch();
  const {
    data: hubQueryData,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/hubs/${id}`],

    queryFn: async () => {
      const response = await fetch(`/hubs/${id}`, {
        method: 'GET',
      });
      return response.json();
    },
  });

  const hub = hubQueryData?.data;

  const backLink = useMemo(() => {
    if (state?.backLink) {
      return state?.backLink;
    }

    return isSuperadmin
      ? `/organisations/${hub?.organisationId}/hubs`
      : `/my-organisation/hubs`;
  }, [state?.backLink, isSuperadmin, hub?.organisationId]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <Titlebar
        backLink={backLink}
        textPrimary={t('Hub Details')}
        textSecondary={hub?.name}
        isLoading={isPending}
        titleRightContent={
          isAdmin && (
            <div className="flex flex-col gap-4 lg:flex-row lg:items-center">
              <Button
                as={Link}
                icon="edit"
                text={t('Edit Hub')}
                to={`/hubs/${id}/edit`}
                variant="outlineBlue"
              />
            </div>
          )
        }
      />
      <Page.Content variant="grey">
        <HubOverview
          client={hub?.client}
          email={hub?.email}
          id={hub?.id}
          location={hub?.address}
          name={hub?.name}
          nonWorkingDates={hub?.nonWorkingDates}
          organisationId={hub?.carrier.id}
          postCodes={hub?.postCodes}
          shortCode={hub?.shortCode}
          type={hub?.type}
          isHubLoading={isPending}
        />
      </Page.Content>
    </Page>
  );
};

export default HubDetails;
