import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import Collapse from '../../../components/Collapse';
import CopyToClipboardButton from '../../../components/CopyToClipboardButton';
import DrawerTextRow from '../../../components/Drawer/DrawerTextRow';
import Icon from '../../../components/Icon';
import LinkButton from '../../../components/LinkButton';
import TourStopStatus from '../../../enums/TourStopStatus';
import TourStopUnitStatus from '../../../enums/TourStopUnitStatus';
import useTaskTypeTranslations from '../../../hooks/useTaskTypeTranslations';
import taskPropType from '../../../prop-types/taskPropType';
import ProofBlock from './ProofBlock';
import TourStopUnitCard from './TourStopUnitCard';

const TaskBlock = (props) => {
  const { task, tourStopStatus } = props;
  const { t } = useTranslation();
  const taskTypeTranslations = useTaskTypeTranslations();

  const [unitsOpen, setUnitsOpen] = useState(false);

  const firstExecutedTourStopUnit = task.tourStopUnits.find(
    (tourStopUnit) => tourStopUnit.status === TourStopUnitStatus.Executed,
  );

  const firstUnsuccessfulTourStopUnit = task.tourStopUnits.find(
    (tourStopUnit) => tourStopUnit.status === TourStopUnitStatus.Unsuccessful,
  );

  const hasBlockedUnits = useMemo(
    () =>
      task.tourStopUnits.some(
        (tourStopUnit) => tourStopUnit.status === TourStopUnitStatus.Blocked,
      ),
    [task.tourStopUnits],
  );

  return (
    <div className="pb-6 last-of-type:pb-0 flex flex-col gap-4">
      <div>
        <div className="flex flex-col text-sm gap-2">
          <DrawerTextRow
            title={
              <div className="flex gap-2 items-center">
                <Icon className="h-5 w-5 text-grey-700" icon="task" />
                <div className="flex-1 w-full min-w-0 break-words">
                  {t('Task Code')}
                </div>
              </div>
            }
            content={
              <div className="flex gap-[2px] items-center">
                <CopyToClipboardButton
                  info={task.code}
                  tooltipText={t('Copy Task code')}
                />
                <LinkButton
                  className="break-all"
                  as={Link}
                  text={task.code || ''}
                  to={{
                    pathname: `/tasks`,
                    search: `search=${encodeURIComponent(task.code)}&page=1`,
                  }}
                />
              </div>
            }
          />

          <DrawerTextRow
            title={t('Task Type')}
            content={taskTypeTranslations[task.type]}
          />

          <DrawerTextRow title={t('Client')} content={task.client?.name} />

          <div
            className="flex flex-row items-center justify-between text-grey-700 gap-4 cursor-pointer min-h-[24px]"
            onClick={() => setUnitsOpen(!unitsOpen)}
          >
            <div className="font-medium">{t('Units')}</div>
            <div className="flex-1 text-right font-medium text-primary-dark flex items-center justify-end gap-2">
              {task.tourStopUnits.some(
                (tourStopUnit) => tourStopUnit.specialHandling?.length > 0,
              ) && <div className="w-2.5 h-2.5 bg-primary-dark rounded-full" />}
              {/* dont show blocked units if whole tour stop is withdrawn */}
              {hasBlockedUnits &&
                tourStopStatus !== TourStopStatus.Withdrawn && (
                  <Icon
                    className="w-4 h-4 text-ui-warning-orange"
                    icon="warning"
                  />
                )}
              <span>{task.tourStopUnits.length}</span>
              <Icon
                className="w-3 h-3 text-primary-dark"
                icon={unitsOpen ? 'chevronUp' : 'chevronDown'}
              />
            </div>
          </div>
        </div>
        <Collapse isOpen={unitsOpen}>
          <div className="mt-4 flex flex-col gap-4">
            {task.tourStopUnits.map((tourStopUnit) => (
              <TourStopUnitCard
                key={tourStopUnit.unit.id}
                tourStopUnit={tourStopUnit}
              />
            ))}
          </div>
        </Collapse>
      </div>

      {firstExecutedTourStopUnit &&
        tourStopStatus === TourStopStatus.Completed && (
          <ProofBlock
            executionLocation={firstExecutedTourStopUnit.executionLocation}
            images={firstExecutedTourStopUnit.images}
            signature={firstExecutedTourStopUnit.signature}
          />
        )}
      {firstUnsuccessfulTourStopUnit &&
        tourStopStatus === TourStopStatus.Attempted && (
          <ProofBlock
            isUnsuccessful
            executionLocation={firstUnsuccessfulTourStopUnit.executionLocation}
            images={firstUnsuccessfulTourStopUnit.images}
            signature={firstUnsuccessfulTourStopUnit.signature}
          />
        )}
    </div>
  );
};

TaskBlock.propTypes = {
  task: taskPropType.isRequired,
  tourStopStatus: PropTypes.oneOf(Object.values(TourStopStatus)).isRequired,
};

export default TaskBlock;
