import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import OrganisationDetailsSectionCard from '../../shared/OrganisationDetailsSectionCard';

const BasicInformationCard = (props) => {
  const { organisation, topRightContent } = props;

  const { t } = useTranslation();

  return (
    <OrganisationDetailsSectionCard
      title={t('Basic Information')}
      topRightContent={topRightContent}
    >
      <div className="grid gap-4 pb-2 pt-4 md:grid-cols-2">
        <div className="flex flex-col">
          <span className="mb-1 text-xs text-grey-700">
            {t('Internal Label')}
          </span>
          <span className="text-sm font-medium">{organisation.name}</span>
        </div>

        <div className="flex flex-col">
          <div className="mb-1 flex items-center gap-2 text-xs text-grey-700">
            <span>{t('Public Name')}</span>
          </div>
          <span className="text-sm font-medium">{organisation.publicName}</span>
        </div>
        <div className="flex flex-col">
          <span className="mb-1 text-xs text-grey-700">{t('Address')}</span>
          <span className="text-sm font-medium">{organisation.address}</span>
        </div>
        <div className="flex flex-col">
          <span className="mb-1 text-xs text-grey-700">{t('Shortcode')}</span>
          <span className="text-sm font-medium">{organisation.shortCode}</span>
        </div>
      </div>
    </OrganisationDetailsSectionCard>
  );
};

BasicInformationCard.propTypes = {
  organisation: PropTypes.shape({
    address: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    publicName: PropTypes.string,
    shortCode: PropTypes.string,
  }).isRequired,
  topRightContent: PropTypes.oneOfType([PropTypes.object]),
};

BasicInformationCard.defaultProps = {
  topRightContent: undefined,
};

export default BasicInformationCard;
