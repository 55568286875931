import * as Sentry from '@sentry/react';
import Modal from 'react-modal';
import { createBrowserRouter } from 'react-router';
// https://reactrouter.com/upgrading/v6#upgrade-to-v7
// https://github.com/import-js/eslint-plugin-import/issues/1810
// eslint-disable-next-line import/no-unresolved
import { RouterProvider } from 'react-router/dom';

import createRoutes from '../routes/createRoutes';

Modal.setAppElement('#root');

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

const router = sentryCreateBrowserRouter(createRoutes());

const App = () => <RouterProvider router={router} />;

export default App;
