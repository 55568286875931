import moment from 'moment';

export const stringToTimePickerObject = (timeToTransform) => {
  if (!timeToTransform) {
    return {
      hours: undefined,
      minutes: undefined,
    };
  }

  const time = moment(timeToTransform, 'HH:mm');

  return {
    hours: time.hours(),
    minutes: time.minutes(),
  };
};

export const minutesToTimePickerObject = (minutes) => {
  if (minutes === undefined || minutes === null) {
    return {
      hours: undefined,
      minutes: undefined,
    };
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return {
    hours,
    minutes: remainingMinutes,
  };
};

export const timePickerObjectToString = (timeToTransform) => {
  if (timeToTransform.hours === undefined) {
    return '';
  }

  const time = moment({
    hour: timeToTransform.hours || 0,
    minute: timeToTransform.minutes || 0,
  });

  return time.format('HH:mm');
};

export const timePickerObjectToMinutes = (timeToTransform) => {
  if (timeToTransform.hours === undefined) {
    return undefined;
  }

  const hours = timeToTransform.hours || 0;
  const minutes = timeToTransform.minutes || 0;

  return hours * 60 + minutes;
};
