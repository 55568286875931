import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router';

import Button from '../../components/Button';
import DebouncedSearch from '../../components/DebouncedSearch';
import ErrorPage from '../../components/ErrorPage';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import Breakpoint from '../../enums/Breakpoint';
import UserRole from '../../enums/UserRole';
import ClientsCardList from '../../features/clients/ClientsCardList';
import ClientsTable from '../../features/clients/ClientsTable';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';
import Resource from '../../lib/api/Resource';
import Allow from '../../lib/rbac/Allow';
import { useUser } from '../../providers/UserProvider';

const MyOrganisationCustomers = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { fetch } = useFetch();

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/carriers/${user.carrier.id}`],

    queryFn: async () => {
      const response = await fetch(`/carriers/${user.carrier.id}`);
      return response.json();
    },
  });

  const organisation = fetchedOrganisation?.data || null;

  const { width } = useWindowSize();
  const isDesktop = width >= Breakpoint.LG;
  const [searchParams, setSearchParams] = useSearchParams();

  const searchQuery = searchParams.get('search') || '';
  const hasFiltersApplied = !!searchQuery;

  const params = useMemo(
    () => ({
      name: searchQuery,
    }),
    [searchQuery],
  );

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Page>
      <Titlebar
        backLink="/my-organisation"
        isActive={hasFiltersApplied}
        menu={
          <div className="lg:w-[200px]">
            <DebouncedSearch
              placeholder={`${t('Find Client')}...`}
              searchValue={searchQuery}
              onSearch={(newSearchValue) => {
                if (newSearchValue === searchQuery) {
                  return;
                }
                searchParams.set('page', '1');
                if (!newSearchValue) {
                  searchParams.delete('search');
                } else {
                  searchParams.set('search', newSearchValue?.trim());
                }
                setSearchParams(searchParams);
              }}
            />
          </div>
        }
        textPrimary={`${t('Clients')} (${organisation?.clients?.length || 0})`}
        textSecondary={organisation?.name}
        isLoading={isPending}
        titleRightContent={
          <Allow roles={[UserRole.Admin]}>
            <Button
              as={Link}
              className="w-full lg:w-fit"
              icon="plus"
              text={t('New Client')}
              to="/my-organisation/clients/new"
              variant="solidBlue"
            />
          </Allow>
        }
      />
      <Page.Content variant={isDesktop ? 'white' : 'grey'}>
        <Resource
          params={params}
          resourceUrl={`/carriers/${user.carrier.id}/clients`}
        >
          {isDesktop ? <ClientsTable /> : <ClientsCardList />}
        </Resource>
      </Page.Content>
    </Page>
  );
};
export default MyOrganisationCustomers;
