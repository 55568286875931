import moment from 'moment/moment';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router';

import ToursSearchBy from '../enums/ToursSearchBy';
import useDateQuery from './useDateQuery';

const useToursPageSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    searchBy: ToursSearchBy.TourNumber,
  });

  const hubIdQuery = searchParams.get('hubId') || '';
  const searchByQuery =
    searchParams.get('searchBy') || ToursSearchBy.TourNumber;
  const searchQuery = searchParams.get('search') || '';

  const startDateQuery = useDateQuery('startDate');
  const startDate = startDateQuery
    ? moment(startDateQuery).format('YYYY-MM-DD')
    : undefined;

  const endDateQuery = useDateQuery('endDate');
  const endDate = endDateQuery
    ? moment(endDateQuery).format('YYYY-MM-DD')
    : undefined;

  const statusQuery = searchParams.get('tourStatus') || '';
  const shiftQuery = searchParams.get('shiftNumber') || '';
  const checkoutTypeQuery = searchParams.get('checkOutType') || '';
  const subcarrierQuery = searchParams.get('subcarrierId') || '';

  useEffect(() => {
    if (!Object.values(ToursSearchBy).includes(searchByQuery)) {
      searchParams.set('searchBy', ToursSearchBy.TourNumber);
      setSearchParams(searchParams, {
        replace: true,
      });
    }
  }, [searchByQuery, searchParams, setSearchParams]);

  const isFilterApplied = useMemo(
    () =>
      !!startDate ||
      !!endDate ||
      !!hubIdQuery ||
      !!searchQuery ||
      !!shiftQuery ||
      !!statusQuery ||
      !!checkoutTypeQuery ||
      !!subcarrierQuery,
    [
      checkoutTypeQuery,
      endDate,
      hubIdQuery,
      searchQuery,
      shiftQuery,
      startDate,
      statusQuery,
      subcarrierQuery,
    ],
  );

  const allParams = {
    startDate,
    endDate,
    hubId: hubIdQuery,
    shiftNumber: shiftQuery,
    status: statusQuery,
    checkOutType: checkoutTypeQuery,
    subcarrierId: subcarrierQuery,
    ...(searchQuery && { search: searchQuery }),
    ...(searchQuery && { searchBy: searchByQuery }),
  };

  return {
    hubIdQuery,
    searchParams,
    shiftQuery,
    setSearchParams,
    searchQuery,
    searchByQuery,
    statusQuery,
    allParams,
    isFilterApplied,
    startDateQuery,
    endDateQuery,
    checkoutTypeQuery,
    subcarrierQuery,
  };
};

export default useToursPageSearchParams;
