import { useEffect } from 'react';
import { Outlet } from 'react-router';

import Sidebar from './components/Sidebar';

const HorizontalLayout = () => {
  useEffect(() => {
    document.documentElement.style.setProperty('--topbar-height', '0px');
  }, []);

  return (
    <div className="flex min-h-full w-full bg-white">
      <Sidebar />
      <div className="flex min-h-full w-full max-w-full flex-col min-w-0">
        <Outlet />
      </div>
    </div>
  );
};

export default HorizontalLayout;
