import PropTypes from 'prop-types';

import CardListContainer from '../../../components/CardListContainer';
import ClientCard from '../ClientCard';

const ClientsCardList = (props) => {
  const { data, isLoading, isPending } = props;

  return (
    <CardListContainer
      isLoading={isLoading}
      isPending={isPending}
      isEmpty={data.length === 0}
    >
      {data.map((client) => (
        <ClientCard
          address={client.address}
          id={client.id}
          key={client.id}
          memberCount={client.userCount}
          name={client.name}
          shortCode={client.shortCode}
          publicName={client.publicName}
        />
      ))}
    </CardListContainer>
  );
};

ClientsCardList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      shortCode: PropTypes.string,
      userCount: PropTypes.number,
    }),
  ),
  isLoading: PropTypes.bool,
  isPending: PropTypes.bool,
};

ClientsCardList.defaultProps = {
  data: [],
  isLoading: false,
  isPending: false,
};

export default ClientsCardList;
