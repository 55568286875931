import moment from 'moment';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router';

const useDateQuery = (param = 'date') => {
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    const date = searchParams.get(param);

    if (moment(date).isValid()) {
      return new Date(date);
    }

    return undefined;
  }, [param, searchParams]);
};

export default useDateQuery;
