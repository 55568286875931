// it must be imported this way, otherwise breaks storybook if imported as import * from i18n
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from './de.json';
import en from './en.json';
import errorsDe from './errors_de.json';
import errorsEn from './errors_en.json';
import getCurrentLanguage from './getCurrentLanguage';

const currentLanguage = getCurrentLanguage();

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  contextSeparator: '',
  fallbackLng: currentLanguage,
  keySeparator: '',
  lng: currentLanguage,
  nsSeparator: '',
  pluralSeparator: '_',
  resources: {
    de: {
      translation: { ...de, ...errorsDe },
    },
    en: {
      translation: { ...en, ...errorsEn },
    },
  },
});

export default i18n;
