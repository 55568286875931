import moment from 'moment';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import IconButton from '../../../components/IconButton';
import LinkButton from '../../../components/LinkButton';
import Tooltip from '../../../components/Tooltip';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import UnitsStatusAndChangeTime from '../../shared/UnitsStatusAndChangeTime';
import TaskUnitSocketHandler from '../TaskUnitSocketHandler';

const TaskUnitList = ({ onUnitDetailsClick, onUpdateUnitStatus, units }) => {
  const { t } = useTranslation();

  return (
    <td className="flex flex-col gap-2 overflow-hidden">
      {units.map((unit) => (
        <Fragment key={unit.id}>
          <TaskUnitSocketHandler id={unit.id} />
          <div className="flex bg-white gap-1 justify-between items-start">
            <div className="flex itms-center flex-wrap">
              <div className="flex flex-col gap-2 px-4 py-2 items-start text-left w-[188px]">
                <div className="text-xs text-grey-700">{t('Unit Number')}</div>
                <div className="text-sm">{unit.number}</div>
              </div>
              <div className="flex flex-col gap-2 px-4 py-2 items-start text-left w-[212px]">
                <div className="text-xs text-grey-700">{t('Unit Code')}</div>
                <div className="text-sm">{unit.code}</div>
              </div>
              <div className="flex flex-col gap-2 px-4 py-2 items-start text-left w-[320px]">
                <div className="text-xs text-grey-700">
                  {t('Unit Status and Change Time')}
                </div>
                <UnitsStatusAndChangeTime unit={unit} />
              </div>
              <div className="flex flex-col gap-2 px-4 py-2 items-start text-left w-[188px]">
                <div className="text-xs text-grey-700">{t('Tour Stop')}</div>
                {unit.latestTour ? (
                  <LinkButton
                    className="text-sm"
                    as={Link}
                    text={`${unit.latestTour.vehicle} ${unit.latestTour.tourStop.sequence}/${unit.latestTour.totalStops}`}
                    to={{
                      pathname: `/tours/${unit.latestTour.id}`,
                      search: `?stopId=${unit.latestTour.tourStop.id}`,
                    }}
                    state={{
                      backLink: -1,
                    }}
                  />
                ) : (
                  <span>N/A</span>
                )}
              </div>
              <div className="flex flex-col gap-2 px-4 py-2 items-start text-left w-[120px]">
                <div className="text-xs text-grey-700">
                  {t('Execution Date')}
                </div>
                <div className="text-sm">
                  {moment(unit.date).format('DD.MM.YYYY.')}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-end gap-1 px-4 py-2 h-[62px]">
              <Tooltip placement="top" size="sm" text={t('Unit details')}>
                <IconButton
                  icon="parcelView"
                  iconSize="s"
                  size="s"
                  variant="ghostBlack"
                  onClick={() => onUnitDetailsClick(unit)}
                />
              </Tooltip>
              <Tooltip
                placement="top"
                size="sm"
                text={t('Update Unit status')}
                hidden={unit.status === DeliveryTaskUnitStatus.Cancelled}
              >
                <IconButton
                  icon="updateUnitStatus"
                  iconSize="s"
                  size="s"
                  variant="ghostBlack"
                  disabled={unit.status === DeliveryTaskUnitStatus.Cancelled}
                  onClick={() => onUpdateUnitStatus(unit)}
                />
              </Tooltip>
            </div>
          </div>
        </Fragment>
      ))}
    </td>
  );
};

TaskUnitList.propTypes = {
  units: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      number: PropTypes.number,
      latestTour: PropTypes.shape({
        id: PropTypes.string,
        totalStops: PropTypes.number,
        tourStop: PropTypes.shape({
          sequence: PropTypes.number,
        }),
      }),
      data: PropTypes.string,
    }),
  ),
  onUpdateUnitStatus: PropTypes.func.isRequired,
  onUnitDetailsClick: PropTypes.func.isRequired,
};

TaskUnitList.defaultProps = {
  units: [],
};

export default TaskUnitList;
