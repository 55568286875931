import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Breakpoint from '../../../enums/Breakpoint';
import UserRole from '../../../enums/UserRole';
import useWindowSize from '../../../hooks/useWindowSize';
import { useIsRole } from '../../../providers/UserProvider';
import OrganisationDetailsSectionCard from '../OrganisationDetailsSectionCard';

const AddButton = ({ isCustomer, isMedium }) => {
  const { t } = useTranslation();
  const isAdmin = useIsRole(UserRole.Admin);

  if (isCustomer && !isAdmin) {
    return null;
  }

  return (
    <Button
      as={Link}
      className={cn('w-full sm:w-fit')}
      icon="plus"
      iconSize="m"
      text={isMedium ? t('Add') : t('Add Member')}
      to="members/new"
      variant="outlineBlack"
    />
  );
};

AddButton.propTypes = {
  isCustomer: PropTypes.bool,
  isMedium: PropTypes.bool,
};

AddButton.defaultProps = {
  isCustomer: false,
  isMedium: false,
};

const MembersCard = ({ isCustomer, organisation }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isMedium = width < Breakpoint.XXL && width >= Breakpoint.XL;
  const hasZeroMembers = organisation?.userCount === 0;

  return (
    <OrganisationDetailsSectionCard
      title={
        <div className="flex items-center gap-4">
          <Icon className="h-6 w-6" icon="users" />
          <span>
            {t('Members')}
            {(organisation?.userCount || hasZeroMembers) &&
              ` (${organisation?.userCount})`}
          </span>
        </div>
      }
      topRightContent={
        hasZeroMembers ? (
          <AddButton isCustomer={isCustomer} />
        ) : (
          <Button
            as={Link}
            className={cn('w-full sm:w-fit')}
            iconRight="chevronRight"
            iconSize="s"
            text={isMedium ? t('View') : t('View Members')}
            to="members"
            variant="outlineBlue"
          />
        )
      }
    />
  );
};

MembersCard.propTypes = {
  isCustomer: PropTypes.bool,
  organisation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    userCount: PropTypes.number,
  }),
};

MembersCard.defaultProps = {
  isCustomer: false,
  organisation: undefined,
};

export default MembersCard;
