import { keepPreviousData, useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Page from '../../../components/Page';
import Titlebar from '../../../components/Titlebar';
import useIsStuck from '../../../hooks/useIsStuck';
import useResourceQuery from '../../../lib/api/hooks/useResourceQuery';
import Resource from '../../../lib/api/Resource';
import FloatingContainerProvider from '../../../providers/FloatingContainerProvider';
import { useTitlebarHeight } from '../../../providers/TitlebarHeightProvider';
import MobileListVisibleContent from '../../shared/MobileListVisibleContent';
import ShiftsCardList from '../ShiftsCardList';

const itemsPerPage = 15;

const OrganisationsShiftsPageMobile = ({ isLoading, organisation }) => {
  const { t } = useTranslation();
  const { totalTitlebarHeight } = useTitlebarHeight();
  const stickyElementRef = useRef(null);
  const isStuck = useIsStuck(stickyElementRef);
  const visiblePageContentRef = useRef(null);
  const { id } = useParams();

  const { queryKey } = useResourceQuery({
    itemsPerPage,
    resourceUrl: `/carriers/${id}/shifts`,
  });

  const { data } = useQuery({ queryKey, placeholderData: keepPreviousData });

  return (
    <Page>
      <Titlebar
        textPrimary={`${t('Shifts')} (${organisation?.shiftCount})`}
        textSecondary={organisation?.name}
        backLink={`/organisations/${id}`}
        isLoading={isLoading}
      />
      <div
        ref={stickyElementRef}
        className={cn(
          'bg-white px-4 pb-2 sticky z-10 border-b transition-[padding]',
          isStuck ? 'border-grey-200 pt-3' : 'border-transparent pt-2',
        )}
        style={{
          top: `calc(var(--topbar-height) + ${totalTitlebarHeight}px)`,
        }}
      >
        <div className="flex gap-2 justify-between">
          <div className="flex flex-col grow overflow-hidden">
            <div className="text-sm pt-3">
              <span className="text-grey-700">{t('Shift count')}:</span>{' '}
              <span className="font-medium">{data?.count || 0}</span>
            </div>
          </div>
        </div>
      </div>
      <Page.Content variant="grey">
        <FloatingContainerProvider
          floatingContainer={visiblePageContentRef?.current}
        >
          <Resource
            itemsPerPage={itemsPerPage}
            showPageSizePicker={false}
            resourceUrl={`/carriers/${id}/shifts`}
          >
            <ShiftsCardList />
          </Resource>
          <MobileListVisibleContent
            ref={visiblePageContentRef}
            stickyElementRef={stickyElementRef}
          />
        </FloatingContainerProvider>
      </Page.Content>
    </Page>
  );
};

OrganisationsShiftsPageMobile.propTypes = {
  organisation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    shiftCount: PropTypes.number,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default OrganisationsShiftsPageMobile;
