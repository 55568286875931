import {
  autoUpdate,
  flip,
  hide,
  offset,
  size as sizeMiddleware,
  useFloating,
} from '@floating-ui/react-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { cloneElement, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useClickAway } from 'react-use';

import Breakpoint from '../../enums/Breakpoint';
import useWindowSize from '../../hooks/useWindowSize';
import * as icons from '../../icons';
import { useFloatingContainer } from '../../providers/FloatingContainerProvider';
import Icon from '../Icon';

const Dropdown = (props) => {
  const { children, className, isDisabled, menu, placement } = props;

  const { width } = useWindowSize();
  const isSmallerScreen = width < Breakpoint.LG;

  const { floatingContainer } = useFloatingContainer();

  const { floatingStyles, middlewareData, refs } = useFloating({
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [
      hide({
        boundary: floatingContainer,
      }),
      offset({ mainAxis: 8 }),
      sizeMiddleware({
        apply: ({ elements, rects }) => {
          if (isSmallerScreen) {
            // eslint-disable-next-line no-param-reassign
            elements.floating.style.width = `${rects.reference.width}px`;
          }
        },
      }),
      flip(),
    ],
  });

  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useClickAway(containerRef, (e) => {
    if (isOpen && !refs.reference?.current?.contains(e.target.parentElement)) {
      setIsOpen(false);
    }
  });

  const toggle = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const menuItems = useMemo(() => {
    if (!isOpen) {
      return [];
    }

    return typeof menu === 'function' ? menu() : menu;
  }, [isOpen, menu]);

  return (
    <div
      className={cn('relative inline-block overflow-hidden', className)}
      ref={refs.setReference}
    >
      {typeof children === 'function'
        ? children({ isOpen, toggle })
        : cloneElement(children, {
            ...children.props,
            onClick: () => {
              if (isDisabled) {
                return;
              }
              if (children.props.onClick) {
                children.props.onClick();
              }
              toggle();
            },
          })}

      {isOpen &&
        createPortal(
          <ul
            className="absolute left-0 z-30 m-0 w-[240px] min-w-[240px] list-none rounded-md bg-white p-2 shadow-elevation-300"
            ref={(ref) => {
              containerRef.current = ref;
              refs.setFloating(ref);
            }}
            style={{
              ...floatingStyles,
              visibility: middlewareData.hide?.referenceHidden
                ? 'hidden'
                : 'visible',
            }}
          >
            {menuItems.map((menuItem) => (
              <li
                key={
                  typeof menuItem.text === 'string'
                    ? menuItem.text
                    : menuItem.key
                }
              >
                <div
                  data-test={menuItem.dataTest}
                  className={cn(
                    'flex w-full items-center gap-2 rounded-md px-3 py-[10px] text-sm font-medium text-primary-dark ',
                    menuItem.isActive
                      ? 'bg-ui-blue text-white'
                      : 'hover:bg-grey-200',
                    !menuItem.isDisabled && 'cursor-pointer',
                    menuItem.isDisabled && 'cursor-not-allowed opacity-40',
                  )}
                  data-test-disabled={menuItem.isDisabled}
                  onClick={(e) => {
                    if (menuItem.isDisabled) {
                      e.stopPropagation();
                      return;
                    }
                    if (menuItem.onClick) {
                      menuItem.onClick(e);
                    }

                    setIsOpen(false);
                  }}
                >
                  {menuItem.icon && (
                    <Icon
                      className={cn('h-4 w-4', menuItem.iconClassName)}
                      icon={menuItem.icon}
                    />
                  )}
                  <div className={cn('text-left', menuItem.textClassName)}>
                    {menuItem.text}
                  </div>
                </div>
              </li>
            ))}
          </ul>,
          document.body,
        )}
    </div>
  );
};

Dropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  className: PropTypes.string,
  menu: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.oneOf(Object.keys(icons)),
        iconClassName: PropTypes.string,
        onClick: PropTypes.func,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        textClassName: PropTypes.string,
        dataTest: PropTypes.string,
        isDisabled: PropTypes.bool,
        key: PropTypes.string,
      }),
    ),
    PropTypes.func,
  ]),
  isDisabled: PropTypes.bool,
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'bottom',
    'right',
    'left',
    'top-start',
    'top-end',
    'bottom-start',
    'bottom-end',
    'right-start',
    'right-end',
    'left-start',
    'left-end',
  ]),
};

Dropdown.defaultProps = {
  className: undefined,
  isDisabled: false,
  menu: [],
  placement: 'bottom-start',
};

export default Dropdown;
