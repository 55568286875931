import PropTypes from 'prop-types';
import { useState } from 'react';

import CardListContainer from '../../../components/CardListContainer';
import UnitDetailsDrawer from '../../shared/UnitDetailsDrawer';
import UnitStatusUpdate from '../../shared/UnitStatusUpdate';
import TaskCard from '../TaskCard';
import UpdateAddressDrawer from '../UpdateAddressDrawer';
import UpdateDateDrawer from '../UpdateDateDrawer';

const TasksCardList = (props) => {
  const { data, isLoading, isPending } = props;
  const [taskForAddressUpdate, setTaskForAddressUpdate] = useState(null);
  const [taskForDateUpdate, setTaskForDateUpdate] = useState(null);
  const [unitForStatusUpdate, setUnitForStatusUpdate] = useState(null);
  const [unitForDetailsView, setUnitForDetailsView] = useState(null);

  return (
    <>
      <UpdateAddressDrawer
        isOpen={!!taskForAddressUpdate}
        task={taskForAddressUpdate}
        onClose={() => setTaskForAddressUpdate(null)}
      />
      <UpdateDateDrawer
        isOpen={!!taskForDateUpdate}
        tasks={[taskForDateUpdate]}
        onClose={() => setTaskForDateUpdate(null)}
      />
      <UnitStatusUpdate
        isOpen={!!unitForStatusUpdate}
        units={[unitForStatusUpdate]}
        onClose={() => setUnitForStatusUpdate(null)}
      />
      <UnitDetailsDrawer
        isOpen={!!unitForDetailsView}
        unitId={unitForDetailsView?.id}
        onClose={() => setUnitForDetailsView(null)}
      />
      <CardListContainer
        isEmpty={data.length === 0}
        isLoading={isLoading}
        isPending={isPending}
        isMultipleExpandable
        id="tasksCardList"
      >
        {data.map((task) => (
          <TaskCard
            key={task.id}
            task={task}
            onUpdateAddressClick={(t) => setTaskForAddressUpdate(t)}
            onUpdateDateClick={(t) => setTaskForDateUpdate(t)}
            onUpdateUnitStatus={setUnitForStatusUpdate}
            onUnitDetailsClick={setUnitForDetailsView}
          />
        ))}
      </CardListContainer>
    </>
  );
};

TasksCardList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoading: PropTypes.bool,
  isPending: PropTypes.bool,
};

TasksCardList.defaultProps = {
  data: undefined,
  isLoading: false,
  isPending: false,
};

export default TasksCardList;
