import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import FormCurrencyInput from '../../../components/FormCurrencyInput/FormCurrencyInput';
import FormSelect from '../../../components/FormSelect';
import InfiniteScrollFormSelect from '../../../components/InfiniteScrollFormSelect';
import TimePicker from '../../../components/TimePicker';
import TourType from '../../../enums/TourType';
import CurrencyHelper from '../../../helpers/CurrencyHelper';
import { useUser } from '../../../providers/UserProvider';
import {
  minutesToTimePickerObject,
  stringToTimePickerObject,
  timePickerObjectToMinutes,
  timePickerObjectToString,
} from './shiftForm.helpers';

const ShiftForm = (props) => {
  const {
    endTime,
    executionSlotEnd,
    executionSlotStart,
    existingShifts,
    fixedTourPrice,
    hubId,
    id,
    isSubmitting,
    number,
    onCancel,
    onIsDirtyChange,
    onShiftSubmit,
    overtimeHourPrice,
    routingTime,
    standardDrivingTime,
    startTime,
    tourType,
  } = props;
  const { t } = useTranslation();
  const { user } = useUser();

  const isEdit = !!id;

  const tourTypeOptions = [
    {
      label: t('Circular'),
      value: TourType.Circular,
    },
    {
      label: t('Open-ended'),
      value: TourType.OpenEnded,
    },
  ];

  const methods = useForm({
    defaultValues: {
      hubId,
      number,
      startTime,
      endTime,
      executionSlotStart,
      executionSlotEnd,
      routingTime,
      standardDrivingTime,
      fixedTourPrice: fixedTourPrice
        ? CurrencyHelper.numberToCurrency(fixedTourPrice)
        : '',
      overtimeHourPrice: overtimeHourPrice
        ? CurrencyHelper.numberToCurrency(overtimeHourPrice)
        : '',
      tourType,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    watch,
  } = methods;

  const selectedStartTime = watch('startTime');
  const selectedEndTime = watch('endTime');
  const selectedDeliverySlotStart = watch('executionSlotStart');
  const selectedDeliverySlotEnd = watch('executionSlotEnd');

  const onSubmit = (data) => {
    onShiftSubmit(data);
  };

  useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, methods, onIsDirtyChange]);

  const numberOptions = [
    {
      value: 1,
      label: 'Shift 1 (Morning)',
    },
    {
      value: 2,
      label: 'Shift 2 (Afternoon)',
    },
    {
      value: 3,
      label: 'Shift 3 (Evening)',
    },
  ];

  useEffect(() => {
    const momentStartTime = moment(selectedStartTime, 'HH:mm');
    const shift3Start = moment('18:00', 'HH:mm');
    const shift2Start = moment('12:00', 'HH:mm');

    if (!momentStartTime.isValid()) {
      return;
    }

    if (momentStartTime.isBefore(shift3Start)) {
      if (momentStartTime.isBefore(shift2Start)) {
        methods.setValue('number', 1);
      } else {
        methods.setValue('number', 2);
      }
    } else {
      methods.setValue('number', 3);
    }
  }, [methods, selectedStartTime]);

  const getStartMaxTime = useCallback((endTimeValue) => {
    const momentEndTime = moment(endTimeValue, 'HH:mm');
    const maxTime = momentEndTime.subtract(1, 'hours');
    return stringToTimePickerObject(maxTime);
  }, []);

  const getEndMinTime = useCallback((startTimeValue) => {
    const momentStartTime = moment(startTimeValue, 'HH:mm');
    const minTime = momentStartTime.add(1, 'hours');
    return stringToTimePickerObject(minTime);
  }, []);

  const validateStartTime = useCallback(
    (_, formValues) => {
      let existingShiftNumbers = [];

      existingShiftNumbers = existingShifts.map((item) =>
        item.hub?.id === formValues.hubId ? item.number : undefined,
      );

      if (isEdit) {
        existingShiftNumbers = existingShiftNumbers.filter(
          (item) => item !== number,
        );
      }

      if (existingShiftNumbers.includes(formValues.number)) {
        return t(
          'There is already a Hub Shift {{shiftNumber}} registered in the system. Please modify the shift details to avoid duplication.',
          {
            shiftNumber: formValues.number,
          },
        );
      }

      return true;
    },
    [existingShifts, isEdit, number, t],
  );

  const validateDeliverySlotStartTime = useCallback(
    (value) => {
      const momentStartTime = moment(selectedStartTime, 'HH:mm');
      const selectedTime = moment(value, 'HH:mm');

      const diff = selectedTime.diff(momentStartTime, 'minutes');

      if (diff < 15) {
        return t(
          'Ensure the execution slot aligns with the shift duration: start at least 15 minutes later and end at least 15 minutes earlier.',
        );
      }

      return true;
    },
    [selectedStartTime, t],
  );

  const validateDeliverySlotEndTime = useCallback(
    (value) => {
      const momentEndTime = moment(selectedEndTime, 'HH:mm');
      const selectedTime = moment(value, 'HH:mm');

      const diff = momentEndTime.diff(selectedTime, 'minutes');

      if (diff < 15) {
        return t(
          'Ensure the execution slot aligns with the shift duration: start at least 15 minutes later and end at least 15 minutes earlier.',
        );
      }

      return true;
    },
    [selectedEndTime, t],
  );

  const displayDeliverySlotErrors = useCallback(() => {
    if (
      errors?.executionSlotStart?.type === 'required' ||
      errors?.executionSlotEnd?.type === 'required'
    ) {
      return t('Please choose a value.');
    }
    if (errors?.executionSlotStart?.type === 'validate') {
      return errors?.executionSlotStart?.message;
    }

    return errors?.executionSlotEnd?.message;
  }, [
    errors?.executionSlotEnd?.message,
    errors?.executionSlotEnd?.type,
    errors?.executionSlotStart?.message,
    errors?.executionSlotStart?.type,
    t,
  ]);

  const displayDurationErrors = useCallback(() => {
    if (
      errors?.startTime?.type === 'required' ||
      errors?.endTime?.type === 'required'
    ) {
      return t('Please choose a value.');
    }

    return errors?.startTime?.message;
  }, [
    errors?.endTime?.type,
    errors?.startTime?.message,
    errors?.startTime?.type,
    t,
  ]);

  const validateRouteStartTime = useCallback(
    (value) => {
      const momentStartTime = moment(selectedStartTime, 'HH:mm');
      const selectedTime = moment(value, 'HH:mm');

      const diff = momentStartTime.diff(selectedTime, 'minutes');

      if (diff < 60) {
        return t(
          'Ensure the routing time aligns with the shift: should occur at least one hour earlier.',
        );
      }

      return true;
    },
    [selectedStartTime, t],
  );

  const transformOptionFn = useCallback(
    (option) => ({
      label: `${option.shortCode} (${option.name})`,
      value: option.id,
    }),
    [],
  );

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-5"
        onSubmit={(e) => {
          e.stopPropagation();
          return handleSubmit(onSubmit)(e);
        }}
        noValidate
      >
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-5">
            <div>
              <h3 className="grey-200 mb-5 text-base font-semibold">
                {t('Setup')}
              </h3>

              <div data-test="hub-id-select">
                <InfiniteScrollFormSelect
                  data-test="hub-select"
                  disabled={isEdit || !!hubId}
                  singleItemUrl="/hubs/"
                  url={`/carriers/${user?.carrier?.id}/hubs`}
                  id="hub-select"
                  label={t('Hub')}
                  name="hubId"
                  placeholder={t('Select Hub')}
                  required
                  transformOptionFn={transformOptionFn}
                />
              </div>
            </div>

            <div className="border-b border-grey-300" />

            <h3 className="text-sm font-medium">{t('Shift Duration')}</h3>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-4 sm:flex-row">
                  <Controller
                    control={control}
                    name="startTime"
                    render={({
                      field: { onChange, ref, value, ...rest },
                      fieldState: { invalid },
                    }) => (
                      <div className="flex flex-col gap-2">
                        <label
                          className="flex gap-1 text-xs font-medium text-primary-dark"
                          htmlFor="startTime"
                        >
                          {t('Earliest Start Time')}*
                        </label>
                        <TimePicker
                          data-test="start-time-picker"
                          value={stringToTimePickerObject(value)}
                          onChange={(timePickerValue) => {
                            onChange(timePickerObjectToString(timePickerValue));
                          }}
                          maxTime={getStartMaxTime(selectedEndTime)}
                          error={invalid}
                          {...rest}
                        />
                      </div>
                    )}
                    rules={{ required: true, validate: validateStartTime }}
                  />
                  <Controller
                    control={control}
                    name="endTime"
                    render={({
                      field: { onChange, ref, value, ...rest },
                      fieldState: { invalid },
                    }) => (
                      <div className="flex flex-col gap-2">
                        <label
                          className="flex gap-1 text-xs font-medium text-primary-dark"
                          htmlFor="endTime"
                        >
                          {t('Latest End Time')}*
                        </label>
                        <TimePicker
                          data-test="end-time-picker"
                          value={stringToTimePickerObject(value)}
                          onChange={(timePickerValue) =>
                            onChange(timePickerObjectToString(timePickerValue))
                          }
                          minTime={getEndMinTime(selectedStartTime)}
                          error={invalid}
                          {...rest}
                        />
                      </div>
                    )}
                    rules={{ required: true }}
                  />
                </div>
                {(errors.startTime || errors.endTime) && (
                  <div
                    className="text-sm text-ui-red"
                    data-test="duration-slot-error"
                  >
                    {displayDurationErrors()}
                  </div>
                )}
              </div>
              <Alert
                variant="info"
                fontWeight="normal"
                message={t(
                  "Shift duration refers to the period between a driver's start time and end time for their working shift.",
                )}
              />
            </div>

            <h3 className="text-sm font-medium">{t('Shift Unit')}</h3>
            <div data-test="number-select">
              <FormSelect
                id="number"
                label={t('Unit')}
                name="number"
                options={numberOptions}
                required
                readOnly
              />
            </div>

            <div className="border-b border-grey-300" />

            <h3 className="text-sm font-medium">{t('Execution Slot')}</h3>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-4 sm:flex-row">
                  <Controller
                    control={control}
                    name="executionSlotStart"
                    render={({
                      field: { onChange, ref, value, ...rest },
                      fieldState: { invalid },
                    }) => (
                      <div className="flex flex-col gap-2">
                        <label
                          className="flex gap-1 text-xs font-medium text-primary-dark"
                          htmlFor="executionSlotStart"
                        >
                          {t('Earliest Start Time')}*
                        </label>
                        <TimePicker
                          data-test="delivery-window-start-time-picker"
                          value={stringToTimePickerObject(value)}
                          onChange={(timePickerValue) => {
                            onChange(timePickerObjectToString(timePickerValue));
                          }}
                          maxTime={getStartMaxTime(selectedDeliverySlotEnd)}
                          error={invalid}
                          {...rest}
                        />
                      </div>
                    )}
                    rules={{
                      required: true,
                      validate: validateDeliverySlotStartTime,
                    }}
                  />
                  <Controller
                    control={control}
                    name="executionSlotEnd"
                    render={({
                      field: { onChange, ref, value, ...rest },
                      fieldState: { invalid },
                    }) => (
                      <div className="flex flex-col gap-2">
                        <label
                          className="flex gap-1 text-xs font-medium text-primary-dark"
                          htmlFor="executionSlotEnd"
                        >
                          {t('Latest End Time')}*
                        </label>
                        <TimePicker
                          data-test="delivery-window-end-time-picker"
                          value={stringToTimePickerObject(value)}
                          onChange={(timePickerValue) =>
                            onChange(timePickerObjectToString(timePickerValue))
                          }
                          minTime={getEndMinTime(selectedDeliverySlotStart)}
                          error={invalid}
                          {...rest}
                        />
                      </div>
                    )}
                    rules={{
                      required: true,
                      validate: validateDeliverySlotEndTime,
                    }}
                  />
                </div>
                {(errors.executionSlotStart || errors.executionSlotEnd) && (
                  <div
                    data-test="delivery-slot-error"
                    className="text-sm text-ui-red"
                  >
                    {displayDeliverySlotErrors()}
                  </div>
                )}
              </div>
              <Alert
                variant="info"
                fontWeight="normal"
                message={t(
                  'A delivery slot represents the designated time frame during which deliveries take place. For optimal scheduling, we recommend that the delivery slot starts at least half an hour after the shift begins and ends at least half an hour before the shift ends.',
                )}
              />
            </div>

            <div className="border-b border-grey-300" />

            <h3 className="text-sm font-medium">
              {t('Standard Driving Time')}
            </h3>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-4 sm:flex-row">
                  <Controller
                    control={control}
                    name="standardDrivingTime"
                    render={({
                      field: { onChange, ref, value, ...rest },
                      fieldState: { invalid },
                    }) => (
                      <div className="flex flex-col gap-2 basis-1/2">
                        <label
                          className="flex gap-1 text-xs font-medium text-primary-dark"
                          htmlFor="standardDrivingTime"
                        >
                          {t('Duration')}*
                        </label>
                        <TimePicker
                          data-test="standard-driving-time-picker"
                          value={minutesToTimePickerObject(value)}
                          onChange={(timePickerValue) =>
                            onChange(timePickerObjectToMinutes(timePickerValue))
                          }
                          error={invalid}
                          hoursFrom={8}
                          {...rest}
                        />
                      </div>
                    )}
                    rules={{
                      required: t('Please choose a value.'),
                    }}
                  />
                </div>
                {errors.standardDrivingTime && (
                  <div
                    data-test="routing-time-error"
                    className="text-sm text-ui-red"
                  >
                    {errors.standardDrivingTime.message}
                  </div>
                )}
              </div>
              <Alert
                variant="info"
                fontWeight="normal"
                message={t(
                  'Standard driving time refers to the fixed service duration for which subcarriers and drivers are paid. Due to routing optimisation, this may sometimes be exceeded up to the ‘latest end time’ of the assigned delivery slot, with any further excess considered overtime.',
                )}
              />
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-4 sm:flex-row">
                <div className="flex flex-col gap-2 basis-1/2">
                  <FormCurrencyInput
                    name="fixedTourPrice"
                    label={t('Fixed Tour Price')}
                    data-test="form-input-fixedTourPrice"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4 sm:flex-row">
                <div className="flex flex-col gap-2 basis-1/2">
                  <FormCurrencyInput
                    name="overtimeHourPrice"
                    label={t('Overtime Rate (Per Hour)')}
                    data-test="form-input-overtimeHourPrice"
                  />
                </div>
              </div>
            </div>

            <div className="border-b border-grey-300" />

            <h3 className="text-sm font-medium">{t('Tour Type')}</h3>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-2">
                <FormSelect
                  id="tour-type"
                  label={t('Type')}
                  name="tourType"
                  options={tourTypeOptions}
                  placeholder={t('Select type')}
                  required
                />
              </div>
            </div>

            <div className="border-b border-grey-300" />

            <h3 className="text-sm font-medium">{t('Routing Time')}</h3>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-4 sm:flex-row">
                  <Controller
                    control={control}
                    name="routingTime"
                    render={({
                      field: { onChange, ref, value, ...rest },
                      fieldState: { invalid },
                    }) => (
                      <div className="flex flex-col gap-2 basis-1/2">
                        <label
                          className="flex gap-1 text-xs font-medium text-primary-dark"
                          htmlFor="routingTime"
                        >
                          {t('Start time')}*
                        </label>
                        <TimePicker
                          data-test="routing-time-picker"
                          value={stringToTimePickerObject(value)}
                          onChange={(timePickerValue) =>
                            onChange(timePickerObjectToString(timePickerValue))
                          }
                          error={invalid}
                          {...rest}
                        />
                      </div>
                    )}
                    rules={{
                      required: t('Please choose a value.'),
                      validate: validateRouteStartTime,
                    }}
                  />
                </div>
                {errors.routingTime && (
                  <div
                    data-test="routing-time-error"
                    className="text-sm text-ui-red"
                  >
                    {errors.routingTime.message}
                  </div>
                )}
              </div>
              <Alert
                variant="info"
                fontWeight="normal"
                message={t(
                  'Routing time represents the designated period for planning routes. We recommend scheduling routing time to occur at least an hour prior to the start of the shift.',
                )}
              />
            </div>
          </div>
        </div>
        <div className="border-bottom h-0.5 w-full border border-grey-200" />
        <div className="flex flex-col justify-between gap-4 sm:flex-row sm:items-center">
          <Button
            className="order-last sm:order-none sm:flex-1"
            disabled={isSubmitting}
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onCancel}
          />
          <Button
            className="sm:flex-1"
            data-test="shift-form-submit"
            disabled={isSubmitting}
            text={isEdit ? t('Save Changes') : t('Create Shift')}
            type="submit"
            variant="solidBlue"
          />
        </div>
      </form>
    </FormProvider>
  );
};

ShiftForm.propTypes = {
  id: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onCancel: PropTypes.func,
  number: PropTypes.number,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  routingTime: PropTypes.string,
  executionSlotStart: PropTypes.string,
  executionSlotEnd: PropTypes.string,
  standardDrivingTime: PropTypes.number,
  hubId: PropTypes.string,
  onIsDirtyChange: PropTypes.func,
  fixedTourPrice: PropTypes.number,
  overtimeHourPrice: PropTypes.number,
  onShiftSubmit: PropTypes.func,
  tourType: PropTypes.oneOf(Object.values(TourType)),
  existingShifts: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number,
      hub: PropTypes.shape({
        hubId: PropTypes.string,
      }),
    }),
  ),
};

ShiftForm.defaultProps = {
  id: undefined,
  hubId: undefined,
  isSubmitting: false,
  number: undefined,
  startTime: '',
  endTime: '',
  executionSlotStart: '',
  executionSlotEnd: '',
  routingTime: '',
  fixedTourPrice: null,
  overtimeHourPrice: null,
  standardDrivingTime: null,
  onIsDirtyChange: () => null,
  onCancel: () => null,
  onShiftSubmit: () => null,
  tourType: undefined,
  existingShifts: [],
};

export default ShiftForm;
