import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';
import { usePrevious } from 'react-use';

import Button from '../../../components/Button';
import Drawer from '../../../components/Drawer';
import UserRole from '../../../enums/UserRole';
import parseSearchParams from '../../../helpers/parseSearchParams';
import { useIsRole } from '../../../providers/UserProvider';
import HubSelect from '../../shared/HubSelect';

const ShiftsFiltersDrawer = (props) => {
  const { isOpen, onClose, organisation } = props;
  const prevIsOpen = usePrevious(isOpen);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(parseSearchParams(searchParams));

  const isLoggedUserSuperadmin = useIsRole(UserRole.Superadmin);
  useEffect(() => {
    if (isOpen && !prevIsOpen) {
      setParams(parseSearchParams(searchParams));
    }
  }, [searchParams, isOpen, prevIsOpen]);

  const onSelectChange = useCallback(
    (queryKey, queryValue) => {
      const newParams = { ...params };

      newParams.page = 1;
      if (!queryValue) {
        delete newParams[queryKey];
      } else {
        newParams[queryKey] = queryValue;
      }
      setParams(newParams);
    },
    [params],
  );

  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      header={t('Filters')}
      footer={
        <Button
          data-test="modal-button-action"
          text={t('Apply')}
          variant="solidBlue"
          isFullWidth
          onClick={() => {
            const urlParams = new URLSearchParams(params);
            setSearchParams(urlParams);
            onClose();
          }}
        />
      }
      body={
        <div className="flex flex-col gap-4">
          <HubSelect
            value={params.hubId}
            organisationId={
              isLoggedUserSuperadmin ? organisation?.id : undefined
            }
            onChange={(hubId) => {
              onSelectChange('hubId', hubId);
            }}
          />
          <Button
            isFullWidth
            variant="outlineBlue"
            text={t('Clear All')}
            onClick={() => {
              const newParams = {
                page: 1,
              };

              setParams(newParams);
            }}
          />
        </div>
      }
    />
  );
};

ShiftsFiltersDrawer.propTypes = {
  organisation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

ShiftsFiltersDrawer.defaultProps = {
  organisation: undefined,
  isOpen: false,
  onClose: undefined,
};

export default ShiftsFiltersDrawer;
