export default class CurrencyHelper {
  static stringToCurrency(value) {
    let maskedValue = value;
    maskedValue = maskedValue.replace(/\D/g, '');
    maskedValue = maskedValue.replace(/(\d)(\d{2})$/, '$1,$2');
    maskedValue = maskedValue.replace(/(?=(\d{3})+(\D))\B/g, '.');
    return maskedValue;
  }

  static numberToCurrency(value) {
    if (Number.isNaN(value)) return '';

    // Convert to a number and fix to 2 decimal places
    const number = parseFloat(value).toFixed(2);

    // Replace decimal point with a comma
    const parts = number.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Add thousand separators

    return parts.join(',');
  }

  static currencyToNumber(currency) {
    let value = currency;
    value = value.replace('.', '');
    value = value.replace(',', '.');
    return parseFloat(value);
  }
}
