import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import Button from '../../../components/Button';
import CollapsableCard from '../../../components/CollapsableCard';
import Label from '../../../components/Label';
import DeleteSubcarrierConfirmationModal from '../DeleteSubcarrierConfirmationModal';

const SubcarriersCard = (props) => {
  const { id, name, shortCode, showActions, userCount } = props;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <DeleteSubcarrierConfirmationModal
        id={id}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <CollapsableCard
        id={id}
        expandedContent={
          <div className="flex flex-col gap-1">
            <div className="flex flex-col gap-1 border-t border-grey-200 py-4">
              <span className="text-xs text-grey-700">{t('Members')}</span>
              <span className="text-sm font-medium">{userCount}</span>
            </div>

            {showActions && (
              <div className="flex flex-col gap-4 border-t-[1px] border-grey-200 pt-5">
                <Button
                  as={Link}
                  icon="edit"
                  isFullWidth
                  text={t('Edit Subcarrier')}
                  to={`/my-organisation/subcarriers/${id}/edit`}
                  variant="outlineBlack"
                />
                <Button
                  icon="delete"
                  isFullWidth
                  text={t('Delete Subcarrier')}
                  variant="outlineRed"
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                />
              </div>
            )}
          </div>
        }
      >
        <div className="flex flex-col gap-1">
          <div>
            <span className="text-base font-medium">{name}</span>{' '}
            <Label size="xs" text={shortCode} />
          </div>
          <Label size="s" text={t('Subcarrier')} variant="green" />
        </div>
      </CollapsableCard>
    </>
  );
};

SubcarriersCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  shortCode: PropTypes.string,
  showActions: PropTypes.bool,
  userCount: PropTypes.number,
};

SubcarriersCard.defaultProps = {
  id: '',
  name: '',
  shortCode: '',
  showActions: false,
  userCount: null,
};

export default SubcarriersCard;
