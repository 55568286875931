import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link, useLocation } from 'react-router';

import UserRole from '../../../enums/UserRole';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useIsRole } from '../../../providers/UserProvider';
import RoutingItem from './RoutingItem';

const HubRoutingConfiguration = ({ hubId, organisationId }) => {
  const { t } = useTranslation();
  const { fetch } = useFetch();
  const location = useLocation();
  const isAdmin = useIsRole(UserRole.Admin);
  const { data, isPending } = useQuery({
    queryKey: [`/carriers/${organisationId}/routing-configurations`],

    queryFn: async () => {
      const response = await fetch(
        `/carriers/${organisationId}/routing-configurations`,
        {
          method: 'GET',
        },
      );
      return response.json();
    },
  });

  const routingConfigurations = useMemo(() => {
    if (!data?.data) {
      return undefined;
    }

    const sorted = data?.data.reduce((acc, routing) => {
      const { days, hub } = routing;

      if (hub && hub?.id !== hubId) {
        return acc;
      }

      const key = hub ? 'Hub' : 'Carrier';
      if (!acc[key]) {
        acc[key] = [];
      }

      if (!days) {
        acc[key].unshift(routing);
      } else {
        acc[key].push(routing);
      }

      return acc;
    }, {});

    return sorted;
  }, [data?.data, hubId]);

  return (
    <div className="rounded-md border border-grey-300 p-3">
      <div className="flex flex-col gap-3">
        <div className="text-sm font-medium text-primary-dark">
          {t('Routing Configuration')}
        </div>
        <hr className="text-grey-300" />

        {isPending && (
          <>
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
          </>
        )}

        {routingConfigurations && (
          <>
            {routingConfigurations.Carrier?.map((routing) => (
              <RoutingItem key={routing.id} routing={routing} isCarrier />
            ))}

            {routingConfigurations.Hub && <hr className="text-grey-300" />}

            {routingConfigurations.Hub?.map((routing) => (
              <RoutingItem key={routing.id} routing={routing} />
            ))}
          </>
        )}

        <Link
          className="text-sm text-ui-blue hover:text-ui-blue hover:underline w-max"
          state={{
            backLink: location.pathname,
          }}
          to={{
            pathname: isAdmin
              ? '/my-organisation/routing'
              : `/organisations/${organisationId}/routing`,
            search: `hubId=${hubId}`,
          }}
        >
          <span>{t('Go to Routing Configuration')}</span>
        </Link>
      </div>
    </div>
  );
};

export default HubRoutingConfiguration;

HubRoutingConfiguration.propTypes = {
  organisationId: PropTypes.string.isRequired,
  hubId: PropTypes.string.isRequired,
};
