import cn from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import Icon from '../../../components/Icon';

const Postcode = (props) => {
  const { onDeleteClick, value } = props;

  const [isDeletingState, setIsDeletingState] = useState(false);

  const timeoutRef = useRef(null);

  useEffect(
    () => () => {
      clearTimeout(timeoutRef?.current);
    },
    [],
  );

  return (
    <span
      className={cn(
        'mr-2 mt-2 inline-flex max-w-full items-center gap-1 overflow-hidden overflow-ellipsis rounded-md px-2 py-[2px] font-mono text-sm font-medium tracking-normal',
        isDeletingState ? 'bg-ui-red text-white' : 'bg-grey-200 text-grey-700',
      )}
      title={value}
    >
      <span>{value}</span>
      {isDeletingState ? (
        <Icon
          className="h-4 w-4"
          icon="delete"
          onClick={() => onDeleteClick(value)}
        />
      ) : (
        <Icon
          className="h-2.5 w-2.5"
          icon="close"
          onClick={() => {
            setIsDeletingState(true);
            timeoutRef.current = setTimeout(() => {
              setIsDeletingState(false);
            }, 3000);
          }}
        />
      )}
    </span>
  );
};

Postcode.propTypes = {
  onDeleteClick: PropTypes.func,
  value: PropTypes.string,
};

Postcode.defaultProps = {
  onDeleteClick: () => {},
  value: '',
};

export default Postcode;
