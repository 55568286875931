import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';

import LinkButton from '../../../components/LinkButton';
import SelectVariant from '../../../components/Select/SelectVariant';
import UserRole from '../../../enums/UserRole';
import { useIsRole } from '../../../providers/UserProvider';
import HubSelect from '../../shared/HubSelect';

const ShiftsFilters = (props) => {
  const { count, organisation } = props;

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const isSuperadmin = useIsRole(UserRole.Superadmin);

  const hubIdQuery = searchParams.get('hubId') || '';

  const onSelectChange = useCallback(
    (queryKey, queryValue) => {
      searchParams.set('page', '1');
      if (!queryValue) {
        searchParams.delete(queryKey);
      } else {
        searchParams.set(queryKey, queryValue);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return (
    <div className="flex w-full flex-col flex-wrap justify-between gap-3 lg:flex-row relative px-4 py-2 border-grey-300 rounded-t-md border items-center">
      <div className="flex flex-col flex-wrap gap-2 lg:flex-row lg:items-center">
        <div className="flex justify-center items-center">
          <div className="text-xs w-[104px] truncate" title={count}>
            <span className="text-grey-700 align-middle block">
              {t('Shift count')}:{' '}
            </span>
            <span className="font-medium text-primary-dark align-middle">
              {count}
            </span>
          </div>
        </div>
        {!isSuperadmin && (
          <>
            <HubSelect
              variant={SelectVariant.Filter}
              placeholder={t('Hub')}
              organisationId={isSuperadmin ? organisation?.id : undefined}
              value={hubIdQuery}
              onChange={(hubId) => {
                onSelectChange('hubId', hubId);
              }}
            />
            <LinkButton
              className="mx-1"
              text={t('Clear All')}
              onClick={() => {
                const newParams = new URLSearchParams({
                  page: 1,
                });

                setSearchParams(newParams);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

ShiftsFilters.propTypes = {
  organisation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  count: PropTypes.number,
};

ShiftsFilters.defaultProps = {
  organisation: undefined,
  count: undefined,
};

export default ShiftsFilters;
