import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Page from '../../../components/Page';
import Titlebar from '../../../components/Titlebar';
import Resource from '../../../lib/api/Resource';
import ShiftsTable from '../ShiftsTable';

const OrganisationsShiftsPageDesktop = ({ isLoading, organisation }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <Page className="max-h-screen">
      <Titlebar
        backLink={`/organisations/${id}`}
        variant="small"
        textPrimary={`${t('Shifts')} (${organisation?.shiftCount})`}
        textSecondary={organisation?.name}
        isLoading={isLoading}
      />
      <Page.Content variant="wide">
        <div className="flex flex-col min-h-0">
          <Resource
            paginationStickyBottom
            itemsPerPage={100}
            showPageSizePicker={false}
            resourceUrl={`/carriers/${id}/shifts`}
          >
            {organisation && <ShiftsTable />}
          </Resource>
        </div>
      </Page.Content>
    </Page>
  );
};

OrganisationsShiftsPageDesktop.propTypes = {
  isLoading: PropTypes.bool,
  organisation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    shiftCount: PropTypes.number,
  }).isRequired,
};

OrganisationsShiftsPageDesktop.defaultProps = {
  isLoading: false,
};

export default OrganisationsShiftsPageDesktop;
