import { useTranslation } from 'react-i18next';

import LoadingSpinner from '../../../components/LoadingSpinner';
import Modal from '../../../components/Modal';

const NewHubAddedModal = () => {
  const { t } = useTranslation();

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      showCloseButton={false}
      body={
        <div className="flex flex-col gap-6">
          <span>{t('You will be redirected to Shift creation form now.')}</span>
          <div className="flex justify-center">
            <LoadingSpinner className="text-grey-700" />
          </div>
        </div>
      }
      header={<span>{t('New Hub Added')}</span>}
      isOpen
    />
  );
};

export default NewHubAddedModal;
