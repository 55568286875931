import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import Button from '../../../components/Button';
import FadingOverlay from '../../../components/FadingOverlay';
import UserRole from '../../../enums/UserRole';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useIsRole } from '../../../providers/UserProvider';
import DeletePostcodeGroupModal from '../DeletePostcodeGroupModal';
import PostcodeGroupModal from '../PostcodeGroupModal';

const HubPostcodes = (props) => {
  const { id, postCodes } = props;
  const isAdmin = useIsRole(UserRole.Admin);
  const { t } = useTranslation();

  const { fetch } = useFetch();
  const { data: postcodeGroupsQueryData, isPending } = useQuery({
    queryKey: [`/hubs/${id}/postcode-groups`],

    queryFn: async () => {
      const response = await fetch(`/hubs/${id}/postcode-groups`, {
        method: 'GET',
      });
      return response.json();
    },
  });

  const allPostcodeGroups = postcodeGroupsQueryData?.data || [];
  const nonGroupPostcodes = postCodes.filter(
    (postcode) =>
      !allPostcodeGroups.some((group) => group.postcodes.includes(postcode)),
  );

  const [isPostcodeGroupModalOpen, setIsPostcodeGroupModalOpen] =
    useState(false);
  const [editingPostcodeGroup, setEditingPostcodeGroup] = useState(undefined);
  const [deletingPostcodeGroup, setDeletingPostcodeGroup] = useState(undefined);

  return (
    <>
      <div>
        {isPending ? (
          <FadingOverlay>
            <div className="flex flex-col gap-3 mb-3 leading-none">
              <Skeleton height={89} />
              <Skeleton height={89} />
              <Skeleton height={89} />
            </div>
          </FadingOverlay>
        ) : (
          allPostcodeGroups?.length > 0 && (
            <>
              <div className="flex flex-col gap-3">
                {allPostcodeGroups.map((postcodeGroup) => (
                  <div
                    key={postcodeGroup.id}
                    className="rounded-md bg-grey-100 p-3 text-sm"
                  >
                    <div>
                      <span className="font-medium">{postcodeGroup.name}</span>
                      <hr className="my-3 border-grey-300" />
                      <div className="font-medium">
                        {postcodeGroup.postcodes.length > 0
                          ? postcodeGroup.postcodes.join(', ')
                          : '/'}
                      </div>
                    </div>
                    {isAdmin && (
                      <div className="flex flex-col sm:flex-row gap-4 mt-3">
                        <Button
                          size="s"
                          icon="edit"
                          variant="outlineBlue"
                          text={t('Edit Group')}
                          onClick={() => {
                            setIsPostcodeGroupModalOpen(true);
                            setEditingPostcodeGroup(postcodeGroup);
                          }}
                        />
                        <Button
                          size="s"
                          icon="delete"
                          variant="outlineRed"
                          text={t('Delete Group')}
                          onClick={() => {
                            setDeletingPostcodeGroup(postcodeGroup);
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <hr className="my-3 border-grey-300" />
            </>
          )
        )}

        <div className="mb-1.5 text-xs text-grey-700">
          {t('Postcodes')}{' '}
          {allPostcodeGroups.length > 0 && `(${t('non-group')})`}
        </div>
        <div className="text-sm font-medium">
          {nonGroupPostcodes.length > 0 ? nonGroupPostcodes.join(', ') : '/'}
        </div>
        {isAdmin && nonGroupPostcodes?.length > 0 && (
          <Button
            className="mt-3"
            icon="plus"
            variant="outlineBlack"
            text={t('Create Postcode Group')}
            onClick={() => {
              setIsPostcodeGroupModalOpen(true);
              setEditingPostcodeGroup(undefined);
            }}
          />
        )}
      </div>

      <PostcodeGroupModal
        hubId={id}
        editingPostcodeGroup={editingPostcodeGroup}
        onClose={() => {
          setIsPostcodeGroupModalOpen(false);
          setEditingPostcodeGroup(undefined);
        }}
        isOpen={isPostcodeGroupModalOpen}
        allPostcodeGroups={allPostcodeGroups}
        allPostcodes={postCodes}
      />

      <DeletePostcodeGroupModal
        isOpen={!!deletingPostcodeGroup}
        postcodeGroup={deletingPostcodeGroup}
        onClose={() => {
          setDeletingPostcodeGroup(undefined);
        }}
        hubId={id}
      />
    </>
  );
};

HubPostcodes.propTypes = {
  postCodes: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
};

HubPostcodes.defaultProps = {
  postCodes: [],
  id: undefined,
};

export default HubPostcodes;
