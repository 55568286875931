import { keepPreviousData, useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router';

import BadgeIndicator from '../../../components/BadgeIndicator';
import IconButton from '../../../components/IconButton';
import Page from '../../../components/Page';
import Titlebar from '../../../components/Titlebar';
import MemberSortType from '../../../enums/MemberSortType';
import useIsStuck from '../../../hooks/useIsStuck';
import useResourceQuery from '../../../lib/api/hooks/useResourceQuery';
import Resource from '../../../lib/api/Resource';
import FloatingContainerProvider from '../../../providers/FloatingContainerProvider';
import { useTitlebarHeight } from '../../../providers/TitlebarHeightProvider';
import MembersCardList from '../../shared/MembersCardList';
import MembersSortDropdown from '../../shared/MembersSortDropdown';
import MobileListVisibleContent from '../../shared/MobileListVisibleContent';
import ClientMembersFilterDrawer from '../ClientMembersFilterDrawer';

const itemsPerPage = 15;

const ClientMembersPageMobile = ({ isLoading, organisation }) => {
  const { t } = useTranslation();
  const { totalTitlebarHeight } = useTitlebarHeight();
  const stickyElementRef = useRef(null);
  const { id } = useParams();
  const isStuck = useIsStuck(stickyElementRef);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const visiblePageContentRef = useRef(null);
  const [searchParams] = useSearchParams();

  const searchQuery = searchParams.get('search') || '';
  const sortTypeQuery = searchParams.get('sort');

  const currentSortType = useMemo(() => {
    if (sortTypeQuery === MemberSortType.EarliestCreated) {
      return MemberSortType.EarliestCreated;
    }
    if (sortTypeQuery === MemberSortType.LastCreated) {
      return MemberSortType.LastCreated;
    }
    if (sortTypeQuery === MemberSortType.RatingBadToExcellent) {
      return MemberSortType.RatingBadToExcellent;
    }

    return MemberSortType.LastActive;
  }, [sortTypeQuery]);

  const params = useMemo(
    () => ({
      name: searchQuery,
      sort: currentSortType,
    }),
    [currentSortType, searchQuery],
  );

  const { queryKey } = useResourceQuery({
    itemsPerPage,
    resourceUrl: `/clients/${id}/users`,
    params,
  });

  const { data } = useQuery({ queryKey, placeholderData: keepPreviousData });

  const hasFiltersApplied = !!searchQuery;

  return (
    <Page>
      <ClientMembersFilterDrawer
        onClose={() => setIsFilterDrawerOpen(false)}
        isOpen={isFilterDrawerOpen}
      />
      <Titlebar
        backLink={`/clients/${organisation?.id}`}
        textPrimary={t('Members')}
        textSecondary={organisation?.name}
        isLoading={isLoading}
      />
      <div
        ref={stickyElementRef}
        className={cn(
          'bg-white px-4 pb-2 sticky z-10 border-b transition-[padding]',
          isStuck ? 'border-grey-200 pt-3' : 'border-transparent pt-2',
        )}
        style={{
          top: `calc(var(--topbar-height) + ${totalTitlebarHeight}px)`,
        }}
      >
        <div className="flex gap-2 justify-between">
          <div className="flex flex-col grow overflow-hidden">
            <MembersSortDropdown />
            <div className="text-sm pt-3">
              <span className="text-grey-700">{t('Member count')}:</span>{' '}
              <span className="font-medium">{data?.count || 0}</span>
            </div>
          </div>
          <div className="flex gap-2">
            <BadgeIndicator variant="warning" isVisible={hasFiltersApplied}>
              <IconButton
                onClick={() => setIsFilterDrawerOpen(true)}
                iconSize="s"
                icon="filter"
                variant="outlineBlack"
              />
            </BadgeIndicator>
          </div>
        </div>
      </div>
      <Page.Content variant="grey">
        <FloatingContainerProvider
          floatingContainer={visiblePageContentRef?.current}
        >
          <Resource params={params} resourceUrl={`/clients/${id}/users`}>
            <MembersCardList />
          </Resource>
          <MobileListVisibleContent
            ref={visiblePageContentRef}
            stickyElementRef={stickyElementRef}
          />
        </FloatingContainerProvider>
      </Page.Content>
    </Page>
  );
};

ClientMembersPageMobile.propTypes = {
  organisation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ClientMembersPageMobile;
