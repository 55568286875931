import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Button from '../../components/Button';
import CancelPrompt from '../../components/CancelPrompt';
import { Card } from '../../components/Card';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import hubDefaultCheckInTimeOffset from '../../constants/hubDefaultCheckInTimeOffset';
import HubForm from '../../features/hubs/HubForm';
import NewHubAddedModal from '../../features/hubs/HubForm/NewHubAddedModal';
import useBlocker from '../../hooks/useBlocker';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';

const MyOrganisationNewHub = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const [shouldNavigate, setShouldNavigate] = useState(false);

  const [newHubId, setNewHubId] = useState(undefined);
  const timeoutRef = useRef(null);

  const title = t('New Hub');

  const mutation = useMutation({
    mutationFn: async (values) => {
      const response = await fetch('/hubs', {
        body: {
          ...values,
          checkInTimeOffset: hubDefaultCheckInTimeOffset,
        },
        method: 'POST',
      });

      return response.json();
    },
    onError: (error) => {
      toastFetchError(error);
    },
    onSuccess: (response) => {
      const newId = response.data.id;
      setNewHubId(newId);
      setIsFormDirty(false);
    },
  });

  const shouldBlock = useMemo(() => {
    // new hub is created, but redirect is pending
    if (newHubId && !shouldNavigate) {
      return true;
    }
    if (mutation.isSuccess) {
      return false;
    }
    return isFormDirty;
  }, [isFormDirty, mutation.isSuccess, newHubId, shouldNavigate]);

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    // start countdown for set timeout, but we are intentionally not using clearTimeout
    // we want this redirect to absolutely happen after 3 seconds, regardless if the user tried to navigate back
    if (mutation.isSuccess && newHubId && !timeoutRef?.current) {
      // show modal for 3 seconds and redirect
      timeoutRef.current = window.setTimeout(() => {
        // reset blocker, in case the user tried navigating while the redirect was pending
        if (blocker?.reset) {
          blocker?.reset();
        }
        setShouldNavigate(true);
      }, 3000);
    }
  }, [blocker, mutation.isSuccess, navigate, newHubId]);

  useEffect(() => {
    if (shouldNavigate) {
      navigate(`/my-organisation/shifts/new?hubId=${newHubId}`, {
        replace: true,
      });
    }
  }, [navigate, newHubId, shouldNavigate]);

  return (
    <>
      {/* dont show cancel prompt if new hub id is already created, we are redirecting the user... */}
      {!newHubId && (
        <CancelPrompt
          title={t('Cancel Creating Hub?')}
          isOpen={blocker.state === 'blocked'}
          onClose={() => blocker.reset()}
          onExitClick={() => blocker.proceed()}
        />
      )}
      {newHubId && <NewHubAddedModal />}
      <Page>
        <Titlebar
          icon={<Icon className="h-5 w-5 text-ui-blue" icon="plus" />}
          textPrimary={title}
          titleRightContent={
            <Button
              className="w-full lg:w-fit"
              disabled={mutation.isPending}
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => navigate('/my-organisation/hubs')}
            />
          }
        />
        <Page.Content centerContent variant="grey">
          <div className="w-full max-w-[480px]">
            <Card className="px-4 py-5 sm:p-8">
              <HubForm
                isSubmitting={mutation.isPending}
                onCancel={() => navigate('/my-organisation/hubs')}
                onIsDirtyChange={setIsFormDirty}
                onSubmit={mutation.mutate}
              />
            </Card>
          </div>
        </Page.Content>
      </Page>
    </>
  );
};

export default MyOrganisationNewHub;
