import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import Button from '../../../components/Button';
import CollapsableCard from '../../../components/CollapsableCard';
import Label from '../../../components/Label';
import TourType from '../../../enums/TourType';
import CurrencyHelper from '../../../helpers/CurrencyHelper';
import DeleteShiftModal from '../DeleteShiftModal';

const ShiftCard = (props) => {
  const {
    endTime,
    executionSlotEnd,
    executionSlotStart,
    fixedTourPrice,
    hub,
    id,
    number,
    overtimeHourPrice,
    routingTime,
    showActions,
    standardDrivingTime,
    startTime,
    tourType,
  } = props;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const unitLabel = useMemo(() => {
    if (number === 1) {
      return t('Morning');
    }
    if (number === 2) {
      return t('Afternoon');
    }
    return t('Evening');
  }, [number, t]);

  const tourTypeText =
    tourType === TourType.Circular ? t('Circular') : t('Open-ended');

  const formattedFixedTourPrice =
    `${CurrencyHelper.numberToCurrency(fixedTourPrice)} €` || 'N/A';
  const formattedOvertimeHourPrice =
    `${CurrencyHelper.numberToCurrency(overtimeHourPrice)} €` || 'N/A';

  return (
    <>
      <DeleteShiftModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        shiftId={id}
      />
      <CollapsableCard
        id={id}
        expandedContent={
          <div className="flex flex-col gap-1">
            <div className="flex flex-col divide-y divide-grey-200 border-t border-grey-200">
              <div className="flex flex-col py-2">
                <span className="mb-1 text-xs text-grey-700">
                  {t('Execution Slot')}
                </span>
                <span className="text-sm font-medium">
                  {executionSlotStart}-{executionSlotEnd}
                </span>
              </div>

              <div className="flex flex-col py-2">
                <span className="mb-1 text-xs text-grey-700">
                  {t('Standard Driving Time')}
                </span>
                <span className="text-sm font-medium">
                  {standardDrivingTime !== null
                    ? moment
                        .utc(standardDrivingTime * 60 * 1000)
                        .format('HH:mm')
                    : 'N/A'}
                </span>
              </div>

              <div className="flex flex-col py-2">
                <span className="mb-1 text-xs text-grey-700">
                  {t('Tour Type')}
                </span>
                <span className="text-sm font-medium">{tourTypeText}</span>
              </div>

              <div className="flex flex-col py-2">
                <span className="mb-1 text-xs text-grey-700">
                  {t('Routing Time')}
                </span>
                <span className="text-sm font-medium">{routingTime}</span>
              </div>

              <div className="flex flex-col py-2">
                <span className="mb-1 text-xs text-grey-700">
                  {t('Tour Price')}
                </span>
                <span className="text-sm font-medium">
                  {formattedFixedTourPrice}
                </span>
              </div>

              <div className="flex flex-col py-2">
                <span className="mb-1 text-xs text-grey-700">
                  {t('Overtime Rate (Per H)')}
                </span>
                <span className="text-sm font-medium">
                  {formattedOvertimeHourPrice}
                </span>
              </div>

              <div className="flex flex-col py-2">
                <span className="mb-1 text-xs text-grey-700">{t('Label')}</span>
                <Label size="s" text={`S${number}`} variant="lightBlue" />
              </div>
            </div>

            {showActions && (
              <div className="flex flex-col gap-4 border-t-[1px] border-grey-200 pt-5">
                <Button
                  as={Link}
                  icon="edit"
                  isFullWidth
                  text={t('Edit Shift')}
                  to={`/my-organisation/shifts/${id}/edit`}
                  variant="outlineBlack"
                />
                <Button
                  icon="delete"
                  isFullWidth
                  text={t('Delete Shift')}
                  variant="outlineRed"
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                />
              </div>
            )}
          </div>
        }
      >
        <div className="flex flex-col gap-1">
          <div>
            <span className="text-base font-medium">
              <span className="mr-2 align-middle">
                {`${t('Hub')} ${number} (${unitLabel}) - ${hub.name}`}
              </span>
            </span>
          </div>
          <div>
            {startTime} : {endTime}
          </div>
        </div>
      </CollapsableCard>
    </>
  );
};

ShiftCard.propTypes = {
  id: PropTypes.string.isRequired,
  hub: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  executionSlotStart: PropTypes.string,
  executionSlotEnd: PropTypes.string,
  number: PropTypes.number.isRequired,
  routingTime: PropTypes.string.isRequired,
  tourType: PropTypes.oneOf(Object.values(TourType)).isRequired,
  overtimeHourPrice: PropTypes.number.isRequired,
  fixedTourPrice: PropTypes.number.isRequired,
  showActions: PropTypes.bool,
  standardDrivingTime: PropTypes.number,
};

ShiftCard.defaultProps = {
  hub: undefined,
  showActions: false,
  executionSlotEnd: undefined,
  executionSlotStart: undefined,
  standardDrivingTime: undefined,
};

export default ShiftCard;
