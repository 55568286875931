import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';

const version = import.meta.env.VITE_VERSION;
// eslint-disable-next-line no-console
console.log(version);

Sentry.init({
  dsn: 'https://5dd8184be3d240688f7227cb524c97bb@o1009310.ingest.sentry.io/5973431',
  // Prevent Sentry for monitoring in dev mode, but still show prompts and enable working with Sentry client APIs (e.g. Sentry.showReportDialog).
  enabled: import.meta.env.PROD,
  environment: import.meta.env.MODE,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ],
  integrations: [
    Sentry.reactRouterV7BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      maskAllInputs: true,
      blockAllMedia: true,
    }),
  ],
  release: `urbify-dashboard@${version}`,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.2,
  // we are not interested in replays for sessions without errors
  replaysSessionSampleRate: 0,
  // keep all samples for sessions that create errors
  replaysOnErrorSampleRate: 1.0,
});
