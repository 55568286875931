import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router';

import IconButton from '../../../components/IconButton';
import Tooltip from '../../../components/Tooltip';
import LocalStorage from '../../../enums/LocalStorage';
import TasksViewType from '../../../enums/TasksViewType';
import LocalStorageHelper from '../../../helpers/LocalStorageHelper';

const TasksViewToggle = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const todayFormatted = moment().format('YYYY-MM-DD');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const onToggleClick = (viewType) => {
    const keysToPreserve = ['date', 'shiftNumber', 'hubId', 'clientId'];
    const keys = Array.from(searchParams.keys());
    keys.forEach((key) => {
      if (!keysToPreserve.includes(key)) {
        searchParams.delete(key);
      }
    });

    searchParams.set('page', '1');
    if (!searchParams.has('startDate')) {
      searchParams.set('startDate', todayFormatted);
      searchParams.set('endDate', todayFormatted);
    }

    const location = {
      pathname: viewType === TasksViewType.Units ? '/units' : '/tasks',
      search: `?${searchParams.toString()}`,
    };

    LocalStorageHelper.setItem(LocalStorage.TasksPageViewPreference, viewType);

    navigate(location);
  };

  return (
    <div className="flex bg-grey-100 rounded-md">
      <Tooltip text={t('Switch to Tasks')} className="flex">
        <IconButton
          variant={
            pathname === `/${TasksViewType.Tasks}` ? 'solidBlue' : 'ghostGrey'
          }
          icon="task"
          iconSize="m"
          onClick={() => onToggleClick(TasksViewType.Tasks)}
        />
      </Tooltip>
      <Tooltip text={t('Switch to Units')}>
        <IconButton
          variant={
            pathname === `/${TasksViewType.Units}` ? 'solidBlue' : 'ghostGrey'
          }
          iconSize="m"
          icon="parcel"
          onClick={() => onToggleClick(TasksViewType.Units)}
        />
      </Tooltip>
    </div>
  );
};

export default TasksViewToggle;
