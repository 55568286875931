import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import FloatingContainerProvider from '../../providers/FloatingContainerProvider';
import IconButton from '../IconButton';

const Modal = ({
  body,
  'data-test': dataTest,
  footer,
  header,
  isOpen,
  onClose,
  onOpen,
  shouldCloseOnOverlayClick,
  showCloseButton,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    // FloatingContainerProvider must be used to reset the floating container to global
    <FloatingContainerProvider>
      <ReactModal
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        bodyOpenClassName="overflow-hidden pr-[var(--scrollbar-width)]"
        className="flex w-full max-w-[480px] flex-col gap-5 rounded-md bg-white p-4 pt-5 text-primary-dark shadow-elevation-400 lg:p-8"
        data={{ test: dataTest }}
        isOpen={isOpen}
        overlayClassName="flex min-h-full items-center justify-center bg-primary-dark bg-opacity-40 px-4 py-10 transition-opacity duration-300"
        portalClassName={
          isOpen ? 'fixed inset-0 z-30 overflow-y-auto overflow-x-hidden' : ''
        }
        onAfterOpen={onOpen}
        onRequestClose={onClose}
      >
        <div className="flex gap-2 items-center justify-between text-xl font-semibold">
          {header}
          {showCloseButton && (
            <IconButton
              aria-label="Close"
              data-test="modal-close"
              icon="close"
              iconSize="s"
              size="s"
              variant="ghostBlack"
              onClick={onClose}
            />
          )}
        </div>
        <div className="h-0.5 bg-grey-200" />
        {body}
        {footer && (
          <>
            <div className="h-0.5 bg-grey-200" />
            {footer}
          </>
        )}
      </ReactModal>
    </FloatingContainerProvider>
  );
};

Modal.propTypes = {
  body: PropTypes.node.isRequired,
  'data-test': PropTypes.string,
  footer: PropTypes.node,
  header: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  showCloseButton: PropTypes.bool,
};

Modal.defaultProps = {
  'data-test': undefined,
  footer: undefined,
  isOpen: false,
  shouldCloseOnOverlayClick: true,
  onClose: () => {},
  onOpen: () => {},
  showCloseButton: true,
};

export default Modal;
