import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Drawer from '../../../components/Drawer';
import FormAddressInput from '../../../components/FormAddressInput';
import FormInput from '../../../components/FormInput';
import FormTextarea from '../../../components/FormTextarea';
import Icon from '../../../components/Icon';
import Input from '../../../components/Input';
import InputLayout from '../../../components/InputLayout';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Tooltip from '../../../components/Tooltip';
import InvalidAddressReason from '../../../enums/InvalidAddressReason';
import UnitProgress from '../../../enums/UnitProgress';
import getFullUrl from '../../../helpers/getFullUrl';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useUnitProgress from '../../../hooks/useUnitProgress';
import useFetch from '../../../lib/api/hooks/useFetch';

const updatableUnitProgress = [
  UnitProgress.RoutingPending,
  UnitProgress.Blocked,
];
const UpdateAddressDrawer = ({ isOpen, onClose: propOnClose, task }) => {
  const { t } = useTranslation();
  const { fetch } = useFetch();
  const { toastSuccess } = useCustomToast();
  const { toastFetchError } = useToastFetchError();
  const unitProgress = useUnitProgress();

  const affectedUnits = useMemo(
    () =>
      task?.units.filter((unit) =>
        updatableUnitProgress.includes(unit.progress),
      ),
    [task?.units],
  );

  const firstUpdatableUnit = affectedUnits?.length > 0 && affectedUnits[0];

  const [addressInputDebounced, setAddressInputDebounced] = useState(
    firstUpdatableUnit?.address,
  );

  const queryClient = useQueryClient();

  const methods = useForm({
    defaultValues: {
      address: '',
      addressNote: '',
      endCustomerNote: '',
      // internal change note must be undefined as default value because if its disabled, it will be undefined
      internalChangeNote: undefined,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const { formState, reset } = methods;
  const { isDirty } = formState;

  const addressInput = methods.watch('address');
  const addressNote = methods.watch('addressNote');
  const endCustomerNote = methods.watch('endCustomerNote');
  const internalChangeNote = methods.watch('internalChangeNote');

  const isTransitioningDebouncedInput = addressInput !== addressInputDebounced;

  const { dirtyFields, isValid, touchedFields } = formState;
  const isAddressFieldDirty = !!dirtyFields.address;
  const isAddressFieldTouched = !!touchedFields.address;

  const queryKey = useMemo(
    () => [
      `/tasks/${task?.id}/geocoded-address`,
      { address: addressInputDebounced },
    ],
    [addressInputDebounced, task?.id],
  );

  useEffect(() => {
    if (isOpen) {
      setAddressInputDebounced(firstUpdatableUnit?.address);
    }
  }, [firstUpdatableUnit?.address, isOpen]);

  const enableTestGeocode =
    isOpen &&
    ((addressInputDebounced?.length > 0 && addressInput?.length > 0) ||
      (isAddressFieldTouched && !isAddressFieldDirty));

  const { data: testGeocodeData, isFetching: isFetchingGeocode } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps -- this is intentional because of useMemo
    queryKey,

    queryFn: async () => {
      const fullUrl = getFullUrl(`/tasks/${task?.id}/geocoded-address`, {
        address: addressInputDebounced,
      });
      const response = await fetch(fullUrl);
      return response.json();
    },
    enabled: enableTestGeocode,
  });

  const [, cancel] = useDebounce(
    () => {
      setAddressInputDebounced(addressInput);
    },
    1000,
    [addressInput],
  );

  const onClose = useCallback(() => {
    propOnClose();
    setAddressInputDebounced('');
    reset();
    queryClient.removeQueries({
      queryKey,
    });
    cancel();
  }, [cancel, propOnClose, queryClient, queryKey, reset]);

  const mutation = useMutation({
    mutationFn: () =>
      fetch(`/tasks/${task.id}/address`, {
        body: {
          address: addressInputDebounced,
          addressNote,
          endCustomerNote,
          internalChangeNote,
        },
        method: 'PATCH',
      }),
    onError: (error) => {
      toastFetchError(error);
    },
    onSuccess: async () => {
      onClose();
      toastSuccess(
        task?.invalidAddress
          ? t('Address error resolved.')
          : t('Address updated.'),
      );

      queryClient.invalidateQueries({
        queryKey: ['/tasks'],
      });
    },
  });

  const errorMessages = useMemo(
    () => ({
      [InvalidAddressReason.NotConfidentEnough]: t('Address not clear enough'),
      [InvalidAddressReason.InvalidStreetNumber]: t(
        'Incomplete, invalid or missing street number',
      ),
      [InvalidAddressReason.InvalidStreetName]: t(
        'Incomplete or invalid street name',
      ),
      [InvalidAddressReason.InvalidCity]: t('Invalid city'),
      [InvalidAddressReason.DriverMarkedAsInvalidAddress]: t(
        'Driver marked address as incorrect',
      ),
      [InvalidAddressReason.NoHubToAssign]: t('No Hub linked to this postcode'),
      [InvalidAddressReason.NoGooglePostcodeAvailable]: t(
        'No Google postcode available for this address',
      ),
    }),
    [t],
  );

  useEffect(
    () => () => {
      if (cancel) {
        cancel();
      }
    },
    [cancel],
  );

  const isValidGeocode = testGeocodeData?.data
    ? !testGeocodeData?.data?.invalidAddress
    : false;

  return (
    <Drawer
      onOpen={() => {
        reset({
          address: firstUpdatableUnit?.address || '',
          addressNote: firstUpdatableUnit?.addressNote || '',
          endCustomerNote: firstUpdatableUnit?.endCustomerNote || '',
          internalChangeNote: undefined,
        });
      }}
      data-test="update-address-drawer"
      header={
        <span className="flex items-center gap-2 text-base font-medium">
          <span>{t('Update Address')}</span>
        </span>
      }
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      body={
        <FormProvider {...methods}>
          <div className="flex flex-col divide-y divide-grey-200">
            <div className="flex flex-col gap-4 pb-4">
              <div className="flex flex-col divide-y divide-grey-200 text-sm">
                <div>
                  <div className="flex flex-row items-center justify-between pb-6 text-grey-700">
                    <Icon className="h-5 w-5 text-grey-700" icon="task" />
                    <div className="flex-1 ml-2 font-medium">
                      {t('Task Code')}
                    </div>
                    <div className="flex-1 text-right font-medium text-primary-dark">
                      {task?.code}
                    </div>
                  </div>

                  <div className="mb-4 text-grey-900">
                    {t('Units affected by this update')} (
                    {affectedUnits?.length}/{task?.units?.length})
                  </div>
                  <ul className="w-full flex flex-col gap-4">
                    {task?.units?.map((unit) => {
                      const isAffected = affectedUnits?.find(
                        (affectedUnit) => affectedUnit.id === unit.id,
                      );
                      return (
                        <li
                          key={unit.id}
                          className="flex items-center gap-4 text-sm"
                        >
                          <div className="flex-1 flex items-center gap-2">
                            <Tooltip text={unitProgress[unit.progress].label}>
                              <Icon
                                icon={unitProgress[unit.progress].labelIcon}
                                className={cn(
                                  unitProgress[unit.progress]
                                    .labelIconClassname,
                                  'w-[18px] h-[18px]',
                                )}
                              />
                            </Tooltip>
                            <div className="text-grey-900 font-medium">
                              {unit.code}
                            </div>
                          </div>
                          {isAffected ? (
                            <Icon icon="checkmark" className="w-4 h-4" />
                          ) : (
                            <Icon
                              icon="close"
                              className="w-[14px] h-[14px] text-grey-500"
                            />
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="py-4">
              <div className="flex flex-col text-grey-900 text-sm">
                <div className="mb-2">{t('Latest Address')}</div>
                <div
                  className="font-medium"
                  data-test="update-address-drawer-current-address"
                >
                  {firstUpdatableUnit?.address}
                </div>
              </div>
              <div>
                {task?.invalidAddressReasons?.length > 0 && (
                  <div className="flex flex-col gap-2 mt-2">
                    {task?.invalidAddressReasons?.map((reason) => (
                      <Alert
                        data-test="fix-address-modal-alert"
                        key={reason}
                        message={errorMessages[reason]}
                        variant="error"
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-4 pt-4">
                <FormAddressInput
                  disabled={mutation.isPending}
                  id="fix-address-modal-address-input"
                  dataTest="fix-address-modal-address-input"
                  label={t('New Address')}
                  name="address"
                  // check if we need min length validation (min 6 chars)
                  required
                  onSelect={(address) => {
                    setAddressInputDebounced(address);
                    cancel();
                  }}
                />

                <div className="flex flex-col gap-4 rounded-md bg-grey-100 p-4">
                  <div className="flex flex-col gap-2">
                    <div className="text-sm">{t('Address Validation')}</div>
                    <div
                      className={cn(
                        'rounded-md border border-grey-500 px-3 py-2.5 text-sm',
                        !isValidGeocode && 'bg-white',
                        !isFetchingGeocode &&
                          !isTransitioningDebouncedInput &&
                          isValidGeocode &&
                          'border-ui-green-dark bg-ui-green-dark-light',
                      )}
                    >
                      {isFetchingGeocode || isTransitioningDebouncedInput ? (
                        <div className="h-5 w-5 fill-primary-dark">
                          <LoadingSpinner />
                        </div>
                      ) : (
                        <span data-test="address-validation">
                          {isValidGeocode
                            ? testGeocodeData?.data?.address
                            : addressInputDebounced || '--'}
                        </span>
                      )}
                    </div>
                  </div>
                  {testGeocodeData?.data?.invalidAddressReasons?.length > 0 && (
                    <div
                      className="flex flex-col gap-2"
                      data-test="geocode-test-alerts"
                    >
                      {testGeocodeData?.data?.invalidAddressReasons?.map(
                        (reason, index) => {
                          const key = `${reason}-${index}`;
                          return (
                            <Alert
                              data-test="fix-address-modal-alert"
                              key={key}
                              message={errorMessages[reason]}
                              variant="errorLight"
                            />
                          );
                        },
                      )}
                    </div>
                  )}
                </div>
              </div>

              {task?.what3words && (
                <div className="mt-4">
                  <InputLayout label={t('What3words location')}>
                    <Input value={`///${task?.what3words}`} readOnly />
                  </InputLayout>
                  <Alert
                    className="mt-2"
                    fontWeight="normal"
                    variant="warningOrange"
                    message={t(
                      'The exact entrance location for the given address is provided via What3words. Changing the address will remove this information.',
                    )}
                  />
                </div>
              )}
            </div>

            <div className="flex flex-col py-4">
              <div className="text-sm text-grey-900 mb-2">
                {t('Additional Address Information')}
              </div>
              <Alert
                variant="info"
                message={t(
                  "If you're updating the following fields, please note that the entry overrides the original and becomes visible to both Drivers and customers.",
                )}
                className="text-grey-900"
                fontWeight="normal"
              />

              <div className="my-4">
                <FormInput
                  label={t('Address Details')}
                  name="addressNote"
                  placeholder={t(
                    'Enter details such as floor and apartment number',
                  )}
                />
              </div>

              <FormInput
                label={t('Address Instructions')}
                name="endCustomerNote"
                placeholder={t(
                  'e.g. Doorbell not functional, call on the cell',
                )}
              />
            </div>

            <div className="pt-4">
              <FormTextarea
                showCounter
                label={t('Change Note (internal)')}
                name="internalChangeNote"
                disabled={!isDirty}
                className="max-h-[120px]"
                placeholder={t('Type here...')}
              />
            </div>
          </div>
        </FormProvider>
      }
      footer={
        <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
          <Button
            className="order-last sm:order-none"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={() => {
              onClose();
            }}
          />
          <Button
            data-test="modal-button-action"
            disabled={
              !isDirty ||
              (enableTestGeocode && !isValidGeocode) ||
              !isValid ||
              mutation.isPending ||
              isFetchingGeocode ||
              (isTransitioningDebouncedInput && isAddressFieldDirty)
            }
            text={t('Save Changes')}
            variant="solidBlue"
            onClick={mutation.mutate}
          />
        </div>
      }
    />
  );
};

UpdateAddressDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  task: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    address: PropTypes.string,
    invalidAddress: PropTypes.bool,
    invalidAddressReasons: PropTypes.arrayOf(PropTypes.string),
    units: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        address: PropTypes.string,
        addressNote: PropTypes.string,
      }),
    ),
    what3words: PropTypes.string,
  }),
};

UpdateAddressDrawer.defaultProps = {
  task: undefined,
};

export default UpdateAddressDrawer;
