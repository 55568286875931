import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router';

import Button from '../../../components/Button';
import { Card } from '../../../components/Card';
import ErrorPage from '../../../components/ErrorPage';
import UserRole from '../../../enums/UserRole';
import Workflow from '../../../enums/Workflow';
import useHubTranslation from '../../../hooks/useHubTranslation';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useIsRole } from '../../../providers/UserProvider';
import ShiftLabel from '../../shared/ShiftLabel';
import NonWorkingDatesModal from '../NonWorkingDatesModal';
import HubPostcodes from './HubPostcodes';
import HubRoutingConfiguration from './HubRoutingConfiguration';

const HubOverview = ({
  client,
  email,
  id,
  isHubLoading,
  location,
  name,
  nonWorkingDates,
  organisationId,
  postCodes,
  shortCode,
  type,
}) => {
  const { t } = useTranslation();
  const hubTranslation = useHubTranslation();
  const { fetch } = useFetch();
  const isAdmin = useIsRole(UserRole.Admin);
  const [isNonWorkingDatesModalOpen, setIsNonWorkingDatesModalOpen] =
    useState(false);

  const {
    data: organisationQueryData,
    error,
    isError,
  } = useQuery({
    queryKey: [`/carriers/${organisationId}`],

    queryFn: async () => {
      const response = await fetch(`/carriers/${organisationId}`);
      return response.json();
    },
    enabled: !!organisationId,
  });

  const organisation = organisationQueryData?.data || null;
  const isOperationsWorkflow = organisation?.workflow === Workflow.Operations;

  const { data: shiftsData, isFetching: isFetchingShifts } = useQuery({
    queryKey: [`/carriers/${organisationId}/shifts?hubId=${id}`],

    queryFn: async () => {
      const response = await fetch(
        `/carriers/${organisationId}/shifts?hubId=${id}`,
      );
      return response.json();
    },
    enabled: !!organisationId,
  });

  const shifts = shiftsData?.data || [];

  if (isError) {
    return <ErrorPage error={error} />;
  }

  const isImportDeliveryByEmail = !!email;
  const hasNonWorkingDates = nonWorkingDates?.length > 0;

  if (isHubLoading) {
    return (
      <div className="grid gap-4">
        <Skeleton wrapper={Card} height={158} />
        <Skeleton wrapper={Card} height={98} />
        <Skeleton wrapper={Card} height={254} />
      </div>
    );
  }

  return (
    <>
      <NonWorkingDatesModal
        isOpen={isNonWorkingDatesModalOpen}
        nonWorkingDates={nonWorkingDates}
        hubId={id}
        hubName={name}
        hubType={type}
        onClose={() => setIsNonWorkingDatesModalOpen(false)}
      />
      <div className="flex flex-col gap-4">
        <Card>
          <h3 className="mb-6 mt-2 font-semibold">{t('Basic Information')}</h3>
          <div className="flex flex-col gap-4">
            <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
              <div className="flex-1">
                <div className="mb-1.5 text-xs text-grey-700">{t('Name')}</div>
                <div className="text-sm font-medium">{name}</div>
              </div>
              <div className="flex-1">
                <div className="mb-1.5 text-xs text-grey-700">
                  {t('Location')}
                </div>
                <div className="text-sm font-medium">{location}</div>
              </div>
            </div>
          </div>
          {client && (
            <div className="bg-grey-100 p-3 text-sm mt-3 rounded-md">
              <div>
                <span className="font-medium">{t('Owned by Client')}:</span>
                &nbsp;
                <span className="font-semibold">
                  {client.name} ({client.shortCode})
                </span>
              </div>
            </div>
          )}
        </Card>

        <Card>
          <h3 className="mb-6 mt-2 font-semibold">{t('Setup')}</h3>
          <div className="flex flex-col gap-4">
            <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
              <div className="flex-1">
                <div className="mb-1.5 text-xs text-grey-700">
                  {t('Shortcode')}
                </div>
                <div className="text-sm font-medium">{shortCode}</div>
              </div>
              <div className="flex-1">
                <div className="mb-1.5 text-xs text-grey-700">{t('Type')}</div>
                <div className="text-sm font-medium">
                  {type.map((current) => hubTranslation[current]).join(', ')}
                </div>
              </div>
            </div>

            {isOperationsWorkflow && (
              <div className="rounded-md bg-grey-100 p-3 text-sm">
                <div>
                  <span>{t('Working Schedule:')}</span>{' '}
                  <span
                    className="font-semibold uppercase"
                    data-test="work-schedule"
                  >
                    {hasNonWorkingDates ? t('Custom') : t('Regular')}
                  </span>{' '}
                  <span className="text-grey-700">
                    (
                    {hasNonWorkingDates
                      ? t('Mon-Sat except dates stated below')
                      : t('Mon-Sat')}
                    )
                  </span>
                </div>
                {(hasNonWorkingDates || isAdmin) && (
                  <hr className="my-3 border-grey-300" />
                )}
                <div>
                  <div>
                    {nonWorkingDates.map((nonWorkingDate, index) => {
                      const momentDate = moment(nonWorkingDate);
                      const formattedMomentDate =
                        momentDate.format('DD.MM.YYYY');
                      return (
                        <span key={formattedMomentDate}>
                          <span>{index !== 0 && ', '}</span>
                          <span className="flex-1 text-sm font-medium text-primary-dark">
                            {`${momentDate
                              .format('dddd')
                              .substring(0, 3)} ${formattedMomentDate}`}
                          </span>
                        </span>
                      );
                    })}
                  </div>

                  {isAdmin && (
                    <div className="mt-3">
                      <Button
                        data-test="add-non-working-date"
                        size="s"
                        text={
                          hasNonWorkingDates
                            ? t('Edit Non-working Dates')
                            : t('Add Non-working Date')
                        }
                        variant="outlineBlue"
                        onClick={() => {
                          setIsNonWorkingDatesModalOpen(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}

            {isAdmin && (
              <div className="rounded-md border border-grey-300 p-3">
                <div className="flex flex-col gap-3">
                  <div className="text-sm font-medium text-primary-dark">
                    {t('Import by')}:{' '}
                    <span>
                      {isImportDeliveryByEmail ? t('E-mail') : t('Postcodes')}
                    </span>
                  </div>
                  <hr className="text-grey-300" />
                  <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
                    <div className="flex-1">
                      {isImportDeliveryByEmail ? (
                        <>
                          <div className="mb-1.5 text-xs text-grey-700">
                            {t('E-mail')}
                          </div>
                          <div className="text-sm">{email}</div>
                        </>
                      ) : (
                        <HubPostcodes postCodes={postCodes} id={id} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isOperationsWorkflow && (
              <div className="rounded-md border border-grey-300 p-3">
                <div className="flex flex-col gap-3">
                  <div className="text-sm font-medium text-primary-dark">
                    {t('Shifts')}
                  </div>
                  <hr className="text-grey-300" />

                  {isFetchingShifts && (
                    <div className="grid gap-1">
                      <Skeleton height={24} />
                      <Skeleton height={24} />
                      <Skeleton height={24} />
                    </div>
                  )}

                  {!isFetchingShifts && (
                    <div>
                      {shifts.length > 0 ? (
                        <div className="flex flex-col gap-3">
                          {shifts.map((shift) => (
                            <div
                              key={shift.id}
                              className="flex items-center gap-2"
                            >
                              <div>
                                <ShiftLabel shiftNumber={shift.number} />
                              </div>
                              <span className="text-sm">
                                {shift.startTime} - {shift.endTime}
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>
                          {isAdmin ? (
                            <Button
                              as={Link}
                              icon="plus"
                              variant="outlineBlack"
                              text={t('New Shift')}
                              to={`/my-organisation/shifts/new?hubId=${id}`}
                            />
                          ) : (
                            <span className="text-sm text-grey-700">
                              {t('No Shifts added yet.')}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            {isAdmin && isOperationsWorkflow && (
              <HubRoutingConfiguration
                organisationId={organisationId}
                hubId={id}
              />
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

HubOverview.propTypes = {
  client: PropTypes.oneOfType([PropTypes.object]),
  email: PropTypes.string,
  id: PropTypes.string,
  location: PropTypes.string,
  name: PropTypes.string,
  nonWorkingDates: PropTypes.arrayOf(PropTypes.string),
  organisationId: PropTypes.string,
  postCodes: PropTypes.arrayOf(PropTypes.string),
  shortCode: PropTypes.string,
  type: PropTypes.arrayOf(PropTypes.string),
  isHubLoading: PropTypes.bool,
};

HubOverview.defaultProps = {
  client: undefined,
  email: '',
  id: undefined,
  location: '',
  name: '',
  nonWorkingDates: [],
  organisationId: '',
  postCodes: [],
  shortCode: '',
  type: [],
  isHubLoading: true,
};

export default HubOverview;
