import { useQuery } from '@tanstack/react-query';

import ErrorPage from '../../components/ErrorPage';
import Breakpoint from '../../enums/Breakpoint';
import MyOrganisationShiftsPageDesktop from '../../features/shifts/ShiftsPage/MyOrganisationShiftsPageDesktop';
import MyOrganisationShiftsPageMobile from '../../features/shifts/ShiftsPage/MyOrganisationShiftsPageMobile';
import useWindowSize from '../../hooks/useWindowSize';
import useFetch from '../../lib/api/hooks/useFetch';
import { useUser } from '../../providers/UserProvider';

const MyOrganisationShifts = () => {
  const { user } = useUser();
  const { width } = useWindowSize();
  const { fetch } = useFetch();
  const isMobile = width < Breakpoint.LG;

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/carriers/${user.carrier.id}`],

    queryFn: async () => {
      const response = await fetch(`/carriers/${user.carrier.id}`);
      return response.json();
    },
  });

  const organisation = fetchedOrganisation?.data;

  if (isError) {
    return <ErrorPage error={error} />;
  }

  if (!organisation) {
    return null;
  }

  if (isMobile) {
    return (
      <MyOrganisationShiftsPageMobile
        organisation={organisation}
        isLoading={isPending}
      />
    );
  }
  return (
    <MyOrganisationShiftsPageDesktop
      organisation={organisation}
      isLoading={isPending}
    />
  );
};

export default MyOrganisationShifts;
