import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Outlet } from 'react-router';

import Topbar from './components/Topbar/Topbar';

const VerticalLayout = (props) => {
  const { variant } = props;

  useEffect(() => {
    document.documentElement.style.setProperty('--topbar-height', '56px');
  }, []);

  return (
    <div className="flex h-full w-full flex-col bg-white">
      <Topbar variant={variant} />
      <div className="flex w-full max-w-full flex-1 flex-col pt-[var(--topbar-height)]">
        <Outlet />
      </div>
    </div>
  );
};

VerticalLayout.propTypes = {
  variant: PropTypes.oneOf(['white', 'grey']),
};

VerticalLayout.defaultProps = {
  variant: 'grey',
};

export default VerticalLayout;
