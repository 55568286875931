import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const usePlatformFeaturesTranslations = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      'allowDuplicateImport:description': t(
        "Allows duplicate external ID imports, supported by the following logic: if Units are added under the same external ID (Task), they are added without changes. If the external ID contains the same Units with different codes, we automatically cancel the old Units and add the new ones as 'Created.'",
      ),
      'allowDuplicateImport:name': t('Allow Duplicate Import'),
      'useImportDeliverySlot:description': t(
        'Enables routing to accommodate a designated delivery slot provided via import.',
      ),
      'useImportDeliverySlot:name': t('Use Import Delivery Slot'),
      'customEmailFields:description': t(
        'The branding and copy in e-mails sent to customers will be fully tailored to represent the Client.',
      ),
      'customEmailFields:name': t('Send e-mails on Client’s behalf'),
      'TRP-rescheduleDate:description': t(
        'Enables date rescheduling on the Tracking page, allowing customers to change the execution date before routing.',
      ),
      'TRP-rescheduleDate:name': t('Tracking Page Date Reschedule'),
      'TRP-rescheduleShift:description': t(
        'Enables slot rescheduling on the Tracking page, allowing customers to change the execution slot before routing.',
      ),
      'TRP-rescheduleShift:name': t('Tracking Page Shift Reschedule'),
      'importEmailAfterLoadingList:description': t(
        'When enabled, imported emails will be sent to Customers only after the loading list is confirmed, rather than immediately after import.',
      ),
      'importEmailAfterLoadingList:name': t(
        'Send Import Email After Loading List Confirmation',
      ),
      'manualDriverScanning:description': t(
        'Enables dashboard functionality for managers to activate manual driver scan, making it accessible in the Driver app upon request.',
      ),
      'manualDriverScanning:name': t('Manual Driver Scanning'),
      'restrictCheckOutLocation:description': t(
        'Prevents the Driver from checking out the Tour if they are outside the Hub radius.',
      ),
      'restrictCheckOutLocation:name': t('Restrict Check-out Location'),
      'displayDeliverySlotRestrictionNote:name': t(
        'Display Delivery Slot Restriction Note',
      ),
      'displayDeliverySlotRestrictionNote:description': t(
        'Notifies the Driver that delivering a package outside the planned delivery slot is not allowed due to Client policy.',
      ),
      'noEmails:name': t('Limit Customer Email Notifications'),
      'noEmails:description': t(
        'By default, multiple emails are sent to customers throughout the ‘happy flow’ delivery process. If enabled, only the post-routing e-mail with the delivery window will be sent. This setting does not impact e-mails related to delays or issues.',
      ),
      'noSms:name': t('Customer SMS Notification Restriction'),
      'noSms:description': t(
        'By default, SMS notifications with the delivery window are sent to customers after routing. Activating this feature will restrict these notifications, preventing them from being sent.',
      ),
      'allowShiftStartBeforeDesiredSlot:name': t(
        'Allow Shift To Start Before Desired Slot',
      ),
      'allowShiftStartBeforeDesiredSlot:description': t(
        'By default, a delivery slot must start at or after the shift’s start time to be assigned. This feature allows parcels with client-specified delivery slots that start later than the shift to still be assigned to that shift.',
      ),
    }),
    [t],
  );
};

export default usePlatformFeaturesTranslations;
