import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';
import useCustomToast from '../../../../hooks/useCustomToast';

const FailedUnitsModal = ({ failedIds, isOpen, onClose, unitsUpdated }) => {
  const { t } = useTranslation();
  const copyToClipboard = useCopyToClipboard();
  const { toastSuccess } = useCustomToast();

  return (
    <Modal
      body={
        <>
          <p>{t('Following Units cannot be updated due to errors:')}</p>
          <p>{failedIds.join(', ')}</p>
        </>
      }
      footer={
        <div className="">
          <Button
            isFullWidth
            data-test="modal-button-action"
            text={t('Copy Codes to Clipboard and Close')}
            variant="solidBlue"
            onClick={async () => {
              try {
                await copyToClipboard(failedIds.join(', '));
                onClose();
                toastSuccess(t('Unit info copied to clipboard.'));
                return undefined;
              } catch (_e) {
                return undefined;
              }
            }}
          />
        </div>
      }
      header={
        <div>
          {t('{{successNumber}} Units updated, {{failedNumber}} failed', {
            successNumber: String(unitsUpdated),
            failedNumber: failedIds.length,
          })}
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

FailedUnitsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  failedIds: PropTypes.arrayOf(PropTypes.string),
  unitsUpdated: PropTypes.number,
};

FailedUnitsModal.defaultProps = {
  failedIds: [],
  unitsUpdated: 0,
};

export default FailedUnitsModal;
