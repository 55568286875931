import { useEffect, useRef, useState } from 'react';

import useDropdown from './useDropdown';

const useDropdownList = (props = {}) => {
  const {
    closeOnSelect = true,
    defaultActivePath,
    onClose,
    onOpen,
    onSelect,
    options = [],
    preserveActivePath = false,
  } = props;

  const [activePath, setActivePath] = useState(defaultActivePath);
  const timeoutRef = useRef();

  const { close, getRootProps, isOpen, open, toggle } = useDropdown({
    closeOnOutsideClick: false,
    onClose,
    onOpen: () => {
      setActivePath(defaultActivePath);
      if (onOpen) {
        onOpen();
      }
    },
  });

  useEffect(() => {
    // used for infinite scrolling, where options are updated async
    if (preserveActivePath) {
      return;
    }

    setActivePath(undefined);
  }, [options, preserveActivePath]);

  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current);
    },
    [],
  );

  const getOptionProps = (option) => ({
    onClick: () => {
      if (onSelect) {
        onSelect(option);
      }
      if (closeOnSelect) {
        close();
      }
    },
    onMouseEnter: () => {
      setActivePath(option.path);
    },
  });

  return {
    activePath,
    setActivePath,
    close,
    getOptionProps,
    getRootProps,
    isOpen,
    open,
    options,
    toggle,
  };
};

export default useDropdownList;
