import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router';

import CopyToClipboardButton from '../../../components/CopyToClipboardButton';
import Icon from '../../../components/Icon';
import IconButton from '../../../components/IconButton';
import Label from '../../../components/Label';

const UserCard = (props) => {
  const { user } = props;
  const { firstName, id, lastName, phoneNumber, subcarrier } = user;
  const { t } = useTranslation();
  const location = useLocation();

  const userName = `${firstName} ${lastName}`;

  return (
    <div className="flex flex-col gap-3 text-sm">
      <div className="flex items-center">
        <Link
          className="flex w-full items-center gap-2 rounded-md p-1.5 transition hover:bg-grey-200"
          to={`/members/${id}`}
          state={{ backLink: location.pathname + location.search }}
        >
          <div className="flex items-center gap-2 justify-between w-full">
            <span className="text-sm font-medium">{userName}</span>
            {subcarrier && (
              <Label size="xs" text={subcarrier} variant="primary" />
            )}
          </div>
          <Icon
            className="ml-auto h-3 w-3 text-primary-dark"
            icon="chevronRight"
          />
        </Link>
      </div>

      {phoneNumber && (
        <div className="flex gap-2 items-center justify-between">
          <div className="flex gap-2 items-center">
            <CopyToClipboardButton
              tooltipText={t('Copy phone number')}
              info={phoneNumber}
            />
            <span className="font-medium">{phoneNumber}</span>
          </div>
          <IconButton
            className="h-4 w-4 text-grey-900"
            variant="ghostBlack"
            icon="call"
            size="s"
            iconSize="s"
            onClick={() => {
              window.open(`tel:${phoneNumber}`, '_self');
            }}
          />
        </div>
      )}
    </div>
  );
};

UserCard.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    subcarrier: PropTypes.string,
  }).isRequired,
};

export default UserCard;
