import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import LinkButton from '../../../../components/LinkButton';
import eventType from '../eventType';
import ExecutionField from './ExecutionField';
import StatusField from './StatusField';
import UserField from './UserField';

const RoutingEvent = (props) => {
  const { event } = props;
  const { t } = useTranslation();
  return (
    <>
      <StatusField event={event} />
      <ExecutionField event={event} />
      {event.tourStop?.windowFrom && event.tourStop?.windowTo && (
        <div>
          <span>{t('Execution window')}: </span>
          <span className="font-medium">
            {moment(event.tourStop.windowFrom).format('HH:mm')}-
            {moment(event.tourStop.windowTo).format('HH:mm')}
          </span>
        </div>
      )}

      {event.tourStop && (
        <div>
          <span>{t('Tour stop')}: </span>
          <span className="font-medium">
            <LinkButton
              as={Link}
              text={`${event.tourStop.tour?.vehicle} ${event.tourStop.sequence}/${event.tourStop.tour?.totalStops}`}
              to={{
                pathname: `/tours/${event.tourStop.tour?.id}`,
                search: `?stopId=${event.tourStop.id}`,
              }}
            />
          </span>
        </div>
      )}

      <UserField event={event} />
    </>
  );
};

RoutingEvent.propTypes = {
  event: eventType.isRequired,
};

export default RoutingEvent;
