import cn from 'classnames';
import { FC } from 'react';

interface Props {
  className?: string;
  text: string;
}

const LabelOval: FC<Props> = ({ className, text }: Props) => (
  <span
    className={cn(
      'inline-flex w-fit items-center gap-1.5 rounded-xl border font-semibold bg-grey-900 text-white border-transparent text-xs px-[7px]',
      className,
    )}
  >
    {text}
  </span>
);

export default LabelOval;
