import cn from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import InputLayout from '../../../components/InputLayout';
import Postcode from './Postcode';

const PostcodesInput = (props) => {
  const {
    className,
    defaultValue,
    disabled,
    id,
    label,
    name,
    required,
    type,
    ...rest
  } = props;

  const { t } = useTranslation();
  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useFormContext();

  const buttonRef = useRef(null);
  const inputRef = useRef(null);

  const [newPostcodeValue, setNewPostcodeValue] = useState('');
  const [localError, setLocalError] = useState(undefined);

  const postcodes = watch(name);

  const isEmpty = postcodes?.length < 1;

  useEffect(() => {
    if (!isEmpty) {
      clearErrors(name);
    }
  }, [clearErrors, isEmpty, name]);

  useFieldArray({ control, name });

  const submitNewPostcode = useCallback(() => {
    const alreadyExists = postcodes.includes(newPostcodeValue);

    inputRef?.current?.focus();
    if (alreadyExists) {
      setLocalError(`${t('Postcode already exists')}.`);
      return;
    }

    const newFieldValue = [...postcodes, newPostcodeValue];
    setValue(name, newFieldValue, {
      shouldDirty: true,
      shouldValidate: true,
    });

    setNewPostcodeValue('');
    setLocalError(undefined);
  }, [name, newPostcodeValue, postcodes, setValue, t]);

  const generateValidators = useCallback(() => {
    const validators = {};

    if (required) {
      validators.validate = (value) =>
        value.length > 0 || t('This field is required.');
    }

    return validators;
  }, [required, t]);

  const errorInput =
    localError || (errors[name] && errors[name]?.root?.message);

  // eslint-disable-next-line no-unused-vars
  const _postcodesTranslationKey = t(
    'No postcodes added yet. Enter a postcode in the field above.',
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur } }) => (
        <div className={cn('flex flex-col', isEmpty ? 'gap-6' : 'gap-4')}>
          <InputLayout
            error={errorInput}
            id={id}
            label={label}
            required={required}
          >
            <div className="flex justify-between">
              <Input
                autoComplete="off"
                disabled={disabled}
                hasError={!!errorInput}
                id={id}
                isFullWidth
                ref={inputRef}
                type="text"
                value={newPostcodeValue}
                placeholder={t('Enter postcodes')}
                onBlur={(e) => {
                  // case when triggering add postcode and preventing double validation (blur and submit)
                  if (e.relatedTarget !== buttonRef?.current) {
                    onBlur();
                  }
                }}
                onChange={(e) => setNewPostcodeValue(e.target.value)}
                onFocus={() => {
                  setLocalError('');
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.code === 'Enter') {
                    if (newPostcodeValue.length > 0) {
                      submitNewPostcode();
                    }
                    e.preventDefault();
                  }
                }}
                {...rest}
              />
              <Button
                className="ml-4"
                disabled={newPostcodeValue?.length < 1}
                icon="plus"
                ref={buttonRef}
                text={t('Add Postcode')}
                variant="solidBlack"
                onClick={submitNewPostcode}
              />
            </div>
          </InputLayout>
          <div>
            {isEmpty ? (
              <p className="text-sm text-grey-700 text-center">
                <Trans i18nKey="No postcodes added yet. Enter a postcode in the field above." />
              </p>
            ) : (
              <div className="flex flex-col">
                <p className="text-sm text-grey-700">
                  {t('Current postcodes')}:
                </p>
                <div className="flex flex-wrap">
                  {postcodes?.map((postcode) => (
                    <Postcode
                      key={postcode}
                      value={postcode}
                      onDeleteClick={(postcodeToDelete) => {
                        const filteredArray = postcodes.filter(
                          (postcodeListItem) =>
                            postcodeListItem !== postcodeToDelete,
                        );
                        setValue(name, filteredArray, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });

                        if (filteredArray.length < 1) {
                          trigger(name);
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      rules={generateValidators()}
    />
  );
};

PostcodesInput.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
};

PostcodesInput.defaultProps = {
  className: '',
  defaultValue: undefined,
  disabled: false,
  id: '',
  label: '',
  name: '',
  required: false,
  type: undefined,
};

export default PostcodesInput;
