import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CopyToClipboardButton from '../../../components/CopyToClipboardButton';
import DrawerCard from '../../../components/Drawer/DrawerCard';
import DrawerTextRow from '../../../components/Drawer/DrawerTextRow';
import IconButton from '../../../components/IconButton';
import LinkButton from '../../../components/LinkButton';
import TourStopUnitStatus from '../../../enums/TourStopUnitStatus';
import useDeliveryOptionsTranslations from '../../../hooks/useDeliveryOptionsTranslations';
import useFailureReasonTranslations from '../../../hooks/useFailureReasonTranslations';
import useSpecialHandlingTranslations from '../../../hooks/useSpecialHandlingTranslations';
import tourStopUnitPropType from '../../../prop-types/tourStopUnitPropType';
import UnitDetailsDrawer from '../../shared/UnitDetailsDrawer';
import UnitStatusUpdate from '../../shared/UnitStatusUpdate';

const TourStopUnitCard = (props) => {
  const { tourStopUnit } = props;
  const { t } = useTranslation();

  const [isDrawerStatusUpdateOpen, setIsDrawerStatusUpdateOpen] =
    useState(false);
  const [isDrawerDetailsOpened, setIsDrawerDetailsOpened] = useState(false);

  const { nameTranslations } = useDeliveryOptionsTranslations();
  const executionTypeText =
    tourStopUnit.deliveryOption === null
      ? t('Customer (direct)')
      : nameTranslations[tourStopUnit.deliveryOption];
  const failureReasonTranslations = useFailureReasonTranslations();

  const specialHandlingTranslations = useSpecialHandlingTranslations();

  const specialHandling = tourStopUnit.specialHandling
    ?.map((specialHandling) => specialHandlingTranslations[specialHandling])
    .join(', ');

  return (
    <>
      <DrawerCard
        title={t(`Unit {{number}}`, {
          number: tourStopUnit.unit.number,
        })}
        rightContent={
          <IconButton
            icon="updateUnitStatus"
            iconSize="s"
            size="s"
            variant="ghostBlack"
            onClick={() => {
              setIsDrawerStatusUpdateOpen(true);
            }}
          />
        }
      >
        <div className="flex flex-col gap-2 pt-2">
          <DrawerTextRow
            title={t('Unit Code')}
            content={
              <>
                <CopyToClipboardButton
                  info={tourStopUnit.unit.code}
                  tooltipText={t('Copy Unit code')}
                />
                <LinkButton
                  className="cursor-pointer break-all text-right"
                  text={tourStopUnit.unit.code}
                  onClick={() => {
                    setIsDrawerDetailsOpened(true);
                  }}
                />
              </>
            }
          />

          <DrawerTextRow
            title={t('Execution Status')}
            content={
              <span
                className={
                  tourStopUnit.status === TourStopUnitStatus.Blocked
                    ? 'text-ui-red'
                    : undefined
                }
              >
                {tourStopUnit.status === TourStopUnitStatus.Pending &&
                  t('Pending')}
                {tourStopUnit.status === TourStopUnitStatus.Executed &&
                  t('Executed')}
                {tourStopUnit.status === TourStopUnitStatus.Unsuccessful &&
                  t('Unsuccessful')}
                {tourStopUnit.status === TourStopUnitStatus.Blocked &&
                  t('Blocked')}
              </span>
            }
          />

          {tourStopUnit.status === TourStopUnitStatus.Executed && (
            <DrawerTextRow
              title={t('Execution Type')}
              content={executionTypeText}
              collapsedContent={tourStopUnit.deliveryOptionNote}
            />
          )}

          <DrawerTextRow
            title={
              <div className="flex items-center gap-2">
                {tourStopUnit.specialHandling?.length > 0 && (
                  <div className="w-2.5 h-2.5 bg-primary-dark rounded-full flex-shrink-0" />
                )}
                <div>{t('Special Type of Goods')}</div>
              </div>
            }
            content={
              <span className="max-w-[180px] break-normal">
                {tourStopUnit.specialHandling?.length > 0
                  ? specialHandling
                  : 'N/A'}
              </span>
            }
          />

          {tourStopUnit.executionTime && (
            <div className="flex flex-row items-center justify-between text-grey-700 gap-1 min-h-[24px]">
              <div className="font-medium">{t('Change Time')}</div>
              <div className="flex-1 text-right font-medium text-primary-dark flex items-center justify-end gap-[2px] break-all">
                {moment(tourStopUnit.executionTime).format('DD.MM.YYYY, HH:mm')}
              </div>
            </div>
          )}

          {tourStopUnit.status === TourStopUnitStatus.Unsuccessful && (
            <DrawerTextRow
              title={t('Failure Reason')}
              content={failureReasonTranslations[tourStopUnit.failureReason]}
              collapsedContent={tourStopUnit.failureReasonNote}
            />
          )}

          <div className="border-grey-200 border-b" />

          <DrawerTextRow
            title={t('Customer')}
            content={`${tourStopUnit.unit.firstName} ${tourStopUnit.unit.lastName}`}
          />
          <DrawerTextRow
            title={t('Phone')}
            content={
              tourStopUnit.unit.phoneNumber && (
                <div className="flex gap-2 items-center">
                  <CopyToClipboardButton
                    tooltipText={t('Copy phone number')}
                    info={tourStopUnit.unit.phoneNumber}
                  />
                  <span className="break-all">
                    {tourStopUnit.unit.phoneNumber}
                  </span>
                </div>
              )
            }
          />
          <DrawerTextRow
            title={t('E-mail')}
            content={
              tourStopUnit.unit.email && (
                <div className="flex gap-2 items-center">
                  <CopyToClipboardButton
                    tooltipText={t('Copy e-mail')}
                    info={tourStopUnit.unit.email}
                  />
                  <span className="break-all">{tourStopUnit.unit.email}</span>
                </div>
              )
            }
          />
        </div>
      </DrawerCard>

      <UnitDetailsDrawer
        isOpen={isDrawerDetailsOpened}
        unitId={tourStopUnit.unit.id}
        onClose={() => setIsDrawerDetailsOpened(false)}
        showBackButton
        transparentBackdrop
      />
      <UnitStatusUpdate
        isOpen={isDrawerStatusUpdateOpen}
        units={[tourStopUnit.unit]}
        onClose={() => setIsDrawerStatusUpdateOpen(false)}
        showBackButton
        transparentBackdrop
      />
    </>
  );
};

TourStopUnitCard.propTypes = {
  tourStopUnit: tourStopUnitPropType.isRequired,
};

export default TourStopUnitCard;
