import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import Button from '../../components/Button';
import CollapsableCard from '../../components/CollapsableCard';
import ShiftLabel from '../shared/ShiftLabel';
import GroceriesTourAssignment from './GroceriesTourAssignment';
import GroceriesTourDetails from './GroceryTourDetails';
import GroceryTourStatusInfo from './GroceryTourStatusInfo';

const GroceryTourCard = (props) => {
  const { tour } = props;

  const { t } = useTranslation();

  return (
    <CollapsableCard
      id={tour.id}
      expandedContent={
        <div className="flex flex-col gap-1">
          <div className="flex flex-col border-t border-grey-200 py-3">
            <GroceriesTourAssignment size="m" tour={tour} />
          </div>

          <div className="flex flex-1 flex-col border-t border-grey-200 py-3">
            <GroceriesTourDetails className="justify-between" tour={tour} />
          </div>

          <div className="flex items-center justify-between border-t border-grey-200 py-3 text-sm">
            <span data-test="tour-mobile-card-date">
              {moment(tour.date).format('DD.MM.YYYY')}
            </span>
            <span>
              <ShiftLabel shiftNumber={tour.shift} />{' '}
              {moment(tour.startLoading).format('HH:mm')}
            </span>
          </div>
          <div className="flex flex-col border-t border-grey-200 pt-4">
            <Button
              as={Link}
              icon="pageView"
              isFullWidth
              state={{
                backLink: -1,
              }}
              text={t('View Tour Details')}
              to={{
                pathname: `/grocery-tours/${tour.id}`,
              }}
              variant="outlineBlack"
            />
          </div>
        </div>
      }
      key={tour.id}
    >
      <div className="flex flex-1 flex-col gap-2">
        <span className="break-all text-base font-medium">
          {tour.tourNumber}
        </span>
        <div>
          <GroceryTourStatusInfo tourStatus={tour.status} />
        </div>
      </div>
    </CollapsableCard>
  );
};

GroceryTourCard.propTypes = {
  tour: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default GroceryTourCard;
