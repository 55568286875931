import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useSearchParams } from 'react-router';

import Button from '../../../components/Button';
import DebouncedSearch from '../../../components/DebouncedSearch';
import Page from '../../../components/Page';
import Titlebar from '../../../components/Titlebar';
import MemberSortType from '../../../enums/MemberSortType';
import UserRole from '../../../enums/UserRole';
import Resource from '../../../lib/api/Resource';
import { useIsRole } from '../../../providers/UserProvider';
import MembersTable from '../../shared/MembersTable';

const ClientMembersPageDesktop = ({ isLoading, organisation }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isAdmin = useIsRole(UserRole.Admin);
  const { id } = useParams();

  const searchQuery = searchParams.get('search') || '';
  const sortTypeQuery = searchParams.get('sort');

  const currentSortType = useMemo(() => {
    if (sortTypeQuery === MemberSortType.EarliestCreated) {
      return MemberSortType.EarliestCreated;
    }
    if (sortTypeQuery === MemberSortType.LastCreated) {
      return MemberSortType.LastCreated;
    }
    if (sortTypeQuery === MemberSortType.RatingBadToExcellent) {
      return MemberSortType.RatingBadToExcellent;
    }

    return MemberSortType.LastActive;
  }, [sortTypeQuery]);

  const params = useMemo(
    () => ({
      name: searchQuery,
      sort: currentSortType,
    }),
    [currentSortType, searchQuery],
  );

  return (
    <Page className="max-h-screen">
      <Titlebar
        variant="small"
        backLink={`/clients/${organisation?.id}`}
        menu={
          <div className="flex">
            <DebouncedSearch
              className="max-w-[200px]"
              placeholder={`${t('Find member')}...`}
              searchValue={searchQuery}
              onSearch={(newSearchValue) => {
                if (newSearchValue === searchQuery) {
                  return;
                }
                searchParams.set('page', '1');
                if (!newSearchValue) {
                  searchParams.delete('search');
                } else {
                  searchParams.set('search', newSearchValue?.trim());
                }
                setSearchParams(searchParams);
              }}
            />
          </div>
        }
        textPrimary={`${t('Members')} (${organisation?.userCount})`}
        textSecondary={`${organisation?.name} (${organisation?.shortCode})`}
        isLoading={isLoading}
        titleRightContent={
          isAdmin && (
            <Button
              as={Link}
              className="w-full lg:w-fit"
              icon="userAdd"
              text={t('New Member')}
              to="new"
              variant="solidBlue"
            />
          )
        }
      />
      <Page.Content variant="wide">
        <div className="flex flex-col min-h-0">
          <Resource
            paginationStickyBottom
            itemsPerPage={100}
            showPageSizePicker={false}
            params={params}
            resourceUrl={`/clients/${id}/users`}
          >
            <MembersTable isClient />
          </Resource>
        </div>
      </Page.Content>
    </Page>
  );
};

ClientMembersPageDesktop.propTypes = {
  organisation: PropTypes.shape({
    id: PropTypes.string,
    userCount: PropTypes.number,
    name: PropTypes.string,
    shortCode: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool,
};

ClientMembersPageDesktop.defaultProps = {
  isLoading: false,
};

export default ClientMembersPageDesktop;
