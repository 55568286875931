import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import FormAddressInput from '../../../components/FormAddressInput';
import FormCheckbox from '../../../components/FormCheckbox';
import FormCheckboxGroup from '../../../components/FormCheckboxGroup';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import HubType from '../../../enums/HubType';
import useHubTranslation from '../../../hooks/useHubTranslation';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useUser } from '../../../providers/UserProvider';
import ShiftLabel from '../../shared/ShiftLabel';
import ShortcodeInput from '../../shared/ShortcodeInput';
import ImportDeliveryFields from './ImportDeliveryFields';

const ShiftWarning = ({ isEditMode }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-sm text-grey-700">
        {isEditMode
          ? t(
              'For a Hub to operate, a Shift must be assigned. To assign one, follow these steps: go to Organisation > Shifts > New Shift.',
            )
          : t(
              'For a Hub to operate, a shift must be assigned. After saving this form, you will be redirected to the Shift creation form. If you prefer to do it later, follow these steps: go to Organisation > Shifts > New Shift.',
            )}
      </div>
      <Alert
        className="mt-5"
        fontWeight="normal"
        message={t('A Hub cannot operate without a shift.')}
        variant="warningOrange"
      />
    </div>
  );
};

ShiftWarning.propTypes = {
  isEditMode: PropTypes.bool,
};

ShiftWarning.defaultProps = {
  isEditMode: false,
};

const HubForm = (props) => {
  const {
    address,
    clientId,
    email,
    id,
    isEditMode,
    isSubmitting,
    name,
    onCancel,
    onIsDirtyChange,
    onSubmit,
    postCodes,
    shortCode,
    type,
  } = props;

  const { t } = useTranslation();
  const { isGroceries, user } = useUser();
  const { fetch } = useFetch();

  const methods = useForm({
    defaultValues: {
      address,
      clientId,
      email,
      isCustomerOwnedChecked: !!clientId,
      name,
      organisationId: user.carrier.id,
      postCodes,
      shortCode,
      type,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const {
    formState: { isDirty },
    handleSubmit,
    watch,
  } = methods;

  const isCustomerOwnedChecked = watch('isCustomerOwnedChecked');

  const { data: allClientsQueryData } = useQuery({
    queryKey: ['/clients'],

    queryFn: async () => {
      const response = await fetch('/clients', {
        method: 'GET',
      });
      return response.json();
    },
    enabled: isCustomerOwnedChecked,
  });

  const clients = useMemo(
    () =>
      allClientsQueryData?.data?.map((client) => ({
        label: `${client.name} (${client.shortCode})`,
        value: client.id,
      })),
    [allClientsQueryData?.data],
  );

  const carrierId = user.carrier.id;
  const { data: shiftsData, isFetching } = useQuery({
    enabled: isEditMode && !!id,
    queryKey: [`/carriers/${carrierId}/shifts?hubId=${id}`],
    queryFn: async () => {
      const response = await fetch(
        `/carriers/${carrierId}/shifts?hubId=${id}`,
        {
          method: 'GET',
        },
      );
      return response.json();
    },
  });

  const shifts = shiftsData?.data || [];

  const onFormSubmit = (values) => {
    let data = values;

    if (isGroceries) {
      data = { ...data, postCodes: [] };
    }

    if (!isGroceries) {
      data = { ...data, email: null };
    }

    if (!isCustomerOwnedChecked) {
      data = {
        ...data,
        clientId: null,
      };
    }

    onSubmit(data);
  };

  useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  const hubTranslation = useHubTranslation();

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-5"
        noValidate
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <div className="flex flex-col">
          <div>
            <h3 className="grey-200 mb-5 text-base font-semibold">
              {t('Basic Information')}
            </h3>
            <div className="flex flex-col gap-4">
              <FormInput
                id="name"
                label={t('Name')}
                name="name"
                required
                placeholder={t('Enter Hub name')}
              />
              <FormAddressInput
                disabled={isEditMode}
                id="address"
                label={t('Location')}
                name="address"
                required={!isEditMode}
                placeholder={t('Enter Hub address')}
              />
            </div>
            <div className="flex flex-col mt-4">
              <FormCheckbox
                label={t('Owned by Client')}
                name="isCustomerOwnedChecked"
              />
              {isCustomerOwnedChecked && (
                <FormSelect
                  label={t('Client')}
                  name="clientId"
                  options={clients || []}
                  placeholder={t('Select Client')}
                  required
                />
              )}
            </div>
          </div>

          <div className="mt-6 mb-5">
            <h3 className="grey-200 mb-5 text-base font-semibold">
              {t('Setup')}
            </h3>
            <div className="flex flex-col gap-4">
              <ShortcodeInput
                disabled={isEditMode}
                disclaimerMessage={t(
                  'Shortcode must be exactly 3 characters long.',
                )}
                id="shortCode"
                label={t('Shortcode')}
                maxLength={{
                  message: t('Shortcode must be exactly 3 characters long'),
                  value: 3,
                }}
                minLength={{
                  message: t('Shortcode must be exactly 3 characters long'),
                  value: 3,
                }}
                name="shortCode"
                pattern={{
                  message: t('Shortcode can only contain uppercase letters'),
                  value: /^[A-Z]+$/,
                }}
                placeholder={t('Enter shortcode')}
                required={!isEditMode}
              />
              <FormCheckboxGroup
                id="type"
                label={t('Type')}
                name="type"
                options={[
                  {
                    label: hubTranslation[HubType.Central],
                    value: HubType.Central,
                  },
                  {
                    label: hubTranslation[HubType.Local],
                    value: HubType.Local,
                  },
                ]}
                required
              />
              <hr className="text-grey-200" />
              <ImportDeliveryFields isGroceries={isGroceries} />
            </div>
          </div>
          {!isGroceries && (
            <div className="p-5 rounded-md border border-grey-300">
              <div className="text-sm mb-5">{t('Shifts')}</div>

              {isEditMode && (
                <>
                  {isFetching && (
                    <div className="grid gap-1">
                      <Skeleton height={24} />
                      <Skeleton height={24} />
                      <Skeleton height={24} />
                    </div>
                  )}
                  {!isFetching && (
                    <div>
                      {shifts.length > 0 ? (
                        <div className="flex flex-col gap-3">
                          {shifts.map((shift) => (
                            <div
                              key={shift.id}
                              className="flex items-center gap-2"
                            >
                              <div>
                                <ShiftLabel shiftNumber={shift.number} />
                              </div>
                              <span className="text-sm">
                                {shift.startTime} - {shift.endTime}
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <ShiftWarning isEditMode={isEditMode} />
                      )}
                    </div>
                  )}
                </>
              )}

              {!isEditMode && <ShiftWarning />}
            </div>
          )}
        </div>

        <hr className="text-grey-200 border-t-2" />
        <div className="flex flex-col justify-between gap-4 sm:flex-row sm:items-center">
          <Button
            className="order-last sm:order-none sm:flex-1"
            data-test="modal-button-cancel"
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onCancel}
          />
          <Button
            className="sm:flex-1"
            data-test="modal-button-action"
            disabled={isSubmitting}
            text={isEditMode ? t('Save Changes') : t('Create Hub')}
            type="submit"
            variant="solidBlue"
          />
        </div>
      </form>
    </FormProvider>
  );
};

HubForm.propTypes = {
  id: PropTypes.string,
  address: PropTypes.string,
  clientId: PropTypes.string,
  email: PropTypes.string,
  isEditMode: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  name: PropTypes.string,
  onCancel: PropTypes.func,
  onIsDirtyChange: PropTypes.func,
  onSubmit: PropTypes.func,
  postCodes: PropTypes.arrayOf(PropTypes.string),
  shortCode: PropTypes.string,
  type: PropTypes.arrayOf(PropTypes.string),
};

HubForm.defaultProps = {
  id: '',
  address: '',
  clientId: '',
  email: '',
  isEditMode: false,
  isSubmitting: false,
  name: '',
  onCancel: () => {},
  onIsDirtyChange: () => {},
  onSubmit: () => {},
  postCodes: [],
  shortCode: '',
  type: [],
};

export default HubForm;
