import DeliveryTaskUnitStatus from '../enums/DeliveryTaskUnitStatus';
import ReturnReason from '../enums/ReturnReason';

export default class UnitsHelper {
  static isReferenceUpdateDisabled(unit) {
    return (
      (unit.attempt === 1 && !!unit.latestTour) ||
      unit.attempt > 1 ||
      unit.status === DeliveryTaskUnitStatus.Cancelled
    );
  }

  static isCancelUnitTooltipHidden(unit) {
    return (
      unit.status === DeliveryTaskUnitStatus.Created ||
      (unit.status === DeliveryTaskUnitStatus.ReturnToClient &&
        unit.returnReason === ReturnReason.Cancelled) ||
      unit.status === DeliveryTaskUnitStatus.Cancelled
    );
  }
}
