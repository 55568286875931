import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router';

import Button from '../../../components/Button';
import Page from '../../../components/Page';
import Titlebar from '../../../components/Titlebar';
import UserRole from '../../../enums/UserRole';
import Resource from '../../../lib/api/Resource';
import Allow from '../../../lib/rbac/Allow';
import ShiftsTable from '../ShiftsTable';

const MyOrganisationShiftsPageDesktop = ({ isLoading, organisation }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const hubIdQuery = searchParams.get('hubId') || '';

  const params = {
    hubId: hubIdQuery,
  };

  return (
    <Page className="max-h-screen">
      <Titlebar
        backLink="/my-organisation"
        variant="small"
        textPrimary={`${t('Shifts')} (${organisation?.shiftCount})`}
        textSecondary={organisation?.name}
        isLoading={isLoading}
        titleRightContent={
          <Allow roles={[UserRole.Admin]}>
            <Button
              as={Link}
              className="w-full lg:w-fit"
              icon="plus"
              text={t('New Shift')}
              to="new"
              variant="solidBlue"
            />
          </Allow>
        }
      />
      <Page.Content variant="wide">
        <div className="flex flex-col min-h-0">
          <Resource
            paginationStickyBottom
            itemsPerPage={100}
            showPageSizePicker={false}
            params={params}
            resourceUrl={`/carriers/${organisation?.id}/shifts`}
          >
            {organisation && <ShiftsTable />}
          </Resource>
        </div>
      </Page.Content>
    </Page>
  );
};

MyOrganisationShiftsPageDesktop.propTypes = {
  isLoading: PropTypes.bool,
  organisation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    shiftCount: PropTypes.number,
  }).isRequired,
};

MyOrganisationShiftsPageDesktop.defaultProps = {
  isLoading: false,
};

export default MyOrganisationShiftsPageDesktop;
