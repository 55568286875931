import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../../../components/Icon';
import TourTypeIcon from '../../../components/TourTypeIcon';
import TourType from '../../../enums/TourType';
import getTourDuration from '../../../helpers/getTourDuration';

const TourDetails = (props) => {
  const { className, tour } = props;

  return (
    <div className={cn('flex flex-wrap items-center gap-1 text-sm', className)}>
      <div className="flex items-center gap-1 lg:w-[48px]">
        <Icon className="h-5 w-5 text-grey-500" icon="tourStop" />
        <span className="text-sm text-primary-dark">{tour.totalStops}</span>
      </div>
      <div className="flex items-center gap-1 lg:w-[80px]">
        <TourTypeIcon showTooltip tourType={tour.tourType} />
        <span className="text-sm text-primary-dark">
          {tour.distance ? `${Math.round(tour.distance / 1000)}km` : 'N/A'}
        </span>
      </div>
      <div className="flex items-center gap-1 lg:w-[80px]">
        <Icon className="h-5 w-5 text-grey-500" icon="history" />
        <span className="text-sm text-primary-dark">
          {getTourDuration(tour)}
        </span>
      </div>
      <div className="flex items-center gap-1 lg:w-[48px]">
        <Icon className="h-5 w-5 text-grey-500" icon="parcel" />
        <span className="text-sm text-primary-dark">{tour.unitsCount}</span>
      </div>
    </div>
  );
};

TourDetails.propTypes = {
  className: PropTypes.string,
  tour: PropTypes.shape({
    totalStops: PropTypes.number,
    distance: PropTypes.number,
    duration: PropTypes.number,
    unitsCount: PropTypes.number,
    tourType: PropTypes.oneOf(Object.values(TourType)),
  }),
};

TourDetails.defaultProps = {
  className: '',
  tour: undefined,
};

export default TourDetails;
