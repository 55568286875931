import { default as posthog } from 'posthog-js';
import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import UserHelper from '../helpers/UserHelper';
import useCurrentPath from '../hooks/useCurrentPath';
import { useUser } from './UserProvider';

function maskParams(url) {
  if (url.indexOf('?') === -1) return url;

  const queryString = url.split('?')[1];
  const searchParams = new URLSearchParams(queryString);
  const safeKeys = [
    'page',
    'searchBy',
    'shiftNumber',
    'status',
    'invalid-address',
    'date',
    'redelivery',
    'role',
  ];
  searchParams.forEach((_value, key) => {
    if (!safeKeys.includes(key)) return;
    searchParams.set(key, '****');
  });

  return `${url.split('?')[0]}?${searchParams.toString()}`;
}

if (import.meta.env.MODE === 'development') {
  posthog.debug(true);
}

export const isPostHogEnabled =
  import.meta.env.PROD &&
  !window.Cypress &&
  import.meta.env.VITE_POSTHOG_ENABLED === 'true';

// eslint-disable-next-line no-console
console.log('isPostHogEnabled:', isPostHogEnabled);

const toolbarJSON = new URLSearchParams(window.location.hash.substring(1)).get(
  '__posthog',
);

const PosthogContext = createContext({
  capture: () => {},
});

const PosthogProvider = (props) => {
  const { children } = props;
  const { user } = useUser();
  const posthogRef = useRef(undefined);
  const [isIdentified, setIsIdentified] = useState(false);
  const currentPath = useCurrentPath();
  const [isLoaded, setIsLoaded] = useState(false);

  const capture = useCallback(
    (eventName, data = {}) => {
      const eventData = {
        ...data,
        path: currentPath,
      };

      if (isPostHogEnabled && user && posthogRef.current) {
        if (posthogRef.current.get_distinct_id() !== user.id) {
          if (import.meta.env.MODE === 'development') {
            // eslint-disable-next-line no-console
            console.warn(
              `[PosthogProvider] get_distinct_id() doesn't match with user.id, skipping sending event: ${eventName}`,
              eventData,
              {
                session_id: posthogRef.current.get_session_id(),
                distinct_id: posthogRef.current.get_distinct_id(),
                userId: user.id,
                // eslint-disable-next-line no-underscore-dangle
                posthogLoaded: posthogRef.current.__loaded,
              },
            );
          }
          return;
        }

        posthogRef.current.capture(eventName, eventData);

        if (import.meta.env.MODE === 'development') {
          // eslint-disable-next-line no-console
          console.log(`[PosthogProvider] ${eventName}`, eventData);
        }
      }
    },
    [currentPath, user],
  );

  useEffect(() => {
    if (!isPostHogEnabled) {
      return undefined;
    }

    // identify user only once
    if (user && !isIdentified) {
      const posthogInstance = posthog.init(
        import.meta.env.VITE_POSTHOG_API_KEY,
        {
          api_host: 'https://eu.posthog.com',
          autocapture: false,
          disable_session_recording: true,
          session_recording: {
            // https://posthog.com/docs/session-replay/privacy#selective-privacy---only-reveal-things-that-are-marked-as-safe
            maskAllInputs: true,
            // until https://github.com/PostHog/posthog-js/issues/950 is resolved, mask everything
            maskTextSelector: '*',
          },
          capture_pageleave: false,
          capture_pageview: false,
          ip: false,
          mask_all_text: true,
          mask_all_element_attributes: true,

          loaded: (instance) => {
            posthogRef.current = instance;
            setIsLoaded(true);

            // this capture is needed only for first loaded event because
            // otherwise the $pageview would not be captured if the library is not loaded
            capture('$pageview', {
              path: currentPath,
            });
            if (import.meta.env.MODE === 'development') {
              // eslint-disable-next-line no-console
              console.log(
                `[PosthogProvider] loaded - send pageview event: ${currentPath}`,
              );
            }
          },
          // we dont want to send these properties to posthog as theyre sensitive information
          property_blacklist: [
            '$initial_current_url',
            '$pathname',
            'title',
            '$ip',
          ],
          sanitize_properties: (properties) => {
            // instead of sending current url (full url with query params that contain sensitive info), we mask the query values, keys are intact
            if (properties.$current_url) {
              // eslint-disable-next-line no-param-reassign
              properties.$current_url = maskParams(properties.$current_url);
            }
            return properties;
          },
        },
      );

      if (toolbarJSON) {
        posthogRef.current.loadToolbar(JSON.parse(toolbarJSON));
      }

      // posthog assigns random uid for anonymous users, we need to check if id is random
      // or from our user to call identify at the right time
      const postHogDistinctId = posthogInstance.get_distinct_id();
      if (postHogDistinctId === user.id) {
        setIsIdentified(true);
        if (import.meta.env.MODE === 'development') {
          // eslint-disable-next-line no-console
          console.log(
            '[PosthogProvider] user already identified, skipping identify',
          );
        }
        return undefined;
      }

      const userProps = {
        role: user.role,
        organisationId: user.carrier?.id || null,
        subcarrierId: user.subcarrier?.id || null,
        path: currentPath,
        initials: UserHelper.getInitials(user),
        userId: user.id,
      };

      if (import.meta.env.MODE === 'development') {
        // eslint-disable-next-line no-console
        console.log(
          `[PosthogProvider] get_distinct_id() ${posthogInstance.get_distinct_id()}`,
        );
        // eslint-disable-next-line no-console
        console.log(`[PosthogProvider] identify user ${user.id}`, userProps);
      }

      posthogInstance.identify(user.id, userProps);
      setIsIdentified(true);
      return undefined;
    }

    return undefined;
  }, [capture, currentPath, isIdentified, user]);

  useEffect(() => {
    if (!user && isIdentified) {
      if (import.meta.env.MODE === 'development') {
        // eslint-disable-next-line no-console
        console.warn('[PosthogProvider] reset');
      }

      if (posthogRef.current) {
        posthogRef.current.reset();
      }
      setIsIdentified(false);
    }
  }, [user, isIdentified]);

  const value = useMemo(() => ({ isLoaded, capture }), [isLoaded, capture]);

  return (
    <PosthogContext.Provider value={value}>{children}</PosthogContext.Provider>
  );
};

PosthogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PosthogProvider;

export const usePosthog = () => useContext(PosthogContext);
