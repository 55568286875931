import moment from 'moment';
import { useTranslation } from 'react-i18next';

import eventType from '../eventType';
import ExecutionField from './ExecutionField';
import FailureReasonField from './FailureReasonField';
import StatusField from './StatusField';
import UserField from './UserField';

const PostponementEvent = (props) => {
  const { event } = props;
  const { t } = useTranslation();
  return (
    <>
      <StatusField event={event} />
      {event.failureReason && <FailureReasonField event={event} />}
      <div>
        <span>{t('Attempted at')}: </span>
        <span className="font-medium">
          {moment(event.timestamp).format('DD.MM.YYYY - HH:mm')}
        </span>
      </div>

      {event.autoExecutionSlotChange && <ExecutionField event={event} />}

      <UserField event={event} />
    </>
  );
};

PostponementEvent.propTypes = {
  event: eventType.isRequired,
};

export default PostponementEvent;
