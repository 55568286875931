import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';

import DebouncedSearch from '../../../components/DebouncedSearch';

const OrganisationsFilters = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get('name')?.trim() || '';

  return (
    <div className="flex w-full flex-col gap-4 lg:flex-row lg:flex-wrap lg:items-center">
      <div className="flex flex-1 flex-col gap-4 lg:flex-row lg:flex-wrap lg:items-center">
        <DebouncedSearch
          placeholder={`${t('Find Organisation')}...`}
          searchValue={searchQuery}
          onSearch={(newSearchValue) => {
            if (newSearchValue === searchQuery) {
              return;
            }
            searchParams.set('page', '1');
            if (!newSearchValue) {
              searchParams.delete('name');
            } else {
              searchParams.set('name', newSearchValue?.trim());
            }
            setSearchParams(searchParams);
          }}
        />
      </div>
    </div>
  );
};

export default OrganisationsFilters;
