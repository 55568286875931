import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { usePrevious } from 'react-use';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Switch from '../../../components/Switch';
import ApiErrorCode from '../../../enums/ApiErrorCode';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import HubType from '../../../enums/HubType';
import PrinterStatus from '../../../enums/PrinterStatus';
import LabelHelper from '../../../helpers/LabelHelper';
import useOnScan from '../../../hooks/useOnScan';
import useScanFeedProgress from '../../../hooks/useScanFeedProgress';
import useScanSoundEffects from '../../../hooks/useScanSoundEffects';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';
import PrinterError from '../../../lib/browser-print/hooks/PrinterError';
import { usePackageScanningSettings } from '../../../providers/PackageScanningSettingsProvider';
import BarcodeInput from '../BarcodeInput';
import BarcodeInputStatus from '../BarcodeInput/BarcodeInputStatus';
import Metadata from '../Metadata';
import PrinterErrorTroubleshootingInfo from '../PrinterErrorTroubleshootingInfo';
import PrinterInfo from '../PrinterInfo';
import ScanHeader from '../ScanHeader';
import WrongHubModal from '../WrongHubModal';

const isAlreadyScanned = (scanResponseData) =>
  (scanResponseData.data.status === DeliveryTaskUnitStatus.Arrived ||
    scanResponseData.data.status === DeliveryTaskUnitStatus.Prepared) &&
  scanResponseData.info.autoPrint === false;

const isEarlyScan = (scanResponseData) =>
  scanResponseData.data.status === DeliveryTaskUnitStatus.ArrivedLocal;

const isNotArrivedScan = (scanResponseData) =>
  scanResponseData.info.isNotArrived;

const isMissortedScan = (scanResponseData) => scanResponseData.info.isMissorted;

const ScanFeed = (props) => {
  const { print, printerStatus, resetPrinterState } = props;

  const { t } = useTranslation();
  const { isAutoPrintEnabled, isCentral, labelSize, setIsAutoPrintEnabled } =
    usePackageScanningSettings();
  const [scanningDate, setScanningDate] = useState(new Date());
  const { getFeedData, scanned, total } = useScanFeedProgress(scanningDate);
  const previousIsCentral = usePrevious(isCentral);
  const [wrongWarehouseModalData, setWrongWarehouseModalData] = useState({
    isOpen: false,
    correctHubName: undefined,
  });

  const { fetch } = useFetch();
  const { toastFetchError } = useToastFetchError();
  const {
    playAlreadyScannedSound,
    playScannerErrorSound,
    playScannerSuccessSound,
  } = useScanSoundEffects();

  // eslint-disable-next-line no-unused-vars
  const _notArrivedWarningTranslationKey = t(
    'The Unit was in not arrived status. The execution date has been updated to today.',
  );
  // eslint-disable-next-line no-unused-vars
  const _missortedWarningTranslationKey = t(
    'The Unit was in missorted status. The execution date has been updated to today.',
  );

  const scanPackageMutation = useMutation({
    mutationFn: async ({ body, url }) => {
      const response = await fetch(url, {
        method: 'PATCH',
        body,
      });
      return response.json();
    },
  });

  const [barcodeInputValue, setBarcodeInputValue] = useState('');
  const [submittedBarcode, setSubmittedBarcode] = useState(undefined);

  const inputRef = useRef(undefined);

  const barcodeInputStatus = useMemo(() => {
    if (submittedBarcode === undefined) {
      return BarcodeInputStatus.Pending;
    }

    if (scanPackageMutation.isPending) {
      return BarcodeInputStatus.Loading;
    }

    if (scanPackageMutation.isError) {
      return BarcodeInputStatus.Error;
    }

    if (
      scanPackageMutation.data?.data &&
      isAlreadyScanned(scanPackageMutation.data?.data)
    ) {
      return BarcodeInputStatus.AlreadyScanned;
    }

    if (scanPackageMutation.data?.data) {
      return BarcodeInputStatus.Success;
    }

    return BarcodeInputStatus.Pending;
  }, [
    scanPackageMutation.data?.data,
    scanPackageMutation.isError,
    scanPackageMutation.isPending,
    submittedBarcode,
  ]);

  const onSubmit = useCallback(
    async (value) => {
      setSubmittedBarcode(value);

      try {
        const scanPackageResponse = await scanPackageMutation.mutateAsync({
          url: '/units/hub-scan',
          body: {
            type: isCentral ? HubType.Central : HubType.Local,
            code: value,
            date: moment(scanningDate).format('YYYY-MM-DD'),
          },
        });

        const backendAutoPrintEnabled =
          scanPackageResponse?.data?.info?.autoPrint;

        const frontendAutoPrintEnabled = !isCentral || isAutoPrintEnabled;

        if (scanPackageResponse.success) {
          getFeedData();
        }

        if (scanPackageResponse.success && backendAutoPrintEnabled) {
          playScannerSuccessSound();
        }

        if (
          scanPackageResponse.success &&
          scanPackageResponse?.data?.data &&
          backendAutoPrintEnabled &&
          frontendAutoPrintEnabled
        ) {
          const labelZpl = LabelHelper.transformParcelToLabelZpl(
            scanPackageResponse?.data,
            isCentral,
            labelSize,
          );
          await print(labelZpl);
        }
      } catch (e) {
        const errorCode = e?.data?.errorCode;
        playScannerErrorSound();

        // do not show toast if printer error
        if (e instanceof PrinterError) {
          return;
        }

        if (errorCode === ApiErrorCode.UnitWrongHub) {
          setWrongWarehouseModalData({
            isOpen: true,
            correctHubName: e?.data?.errors?.correctHubName,
          });
        } else {
          toastFetchError(e);
        }
      }
    },
    [
      scanPackageMutation,
      isCentral,
      scanningDate,
      isAutoPrintEnabled,
      getFeedData,
      playScannerSuccessSound,
      labelSize,
      print,
      toastFetchError,
      playScannerErrorSound,
    ],
  );

  const resetFeed = useCallback(() => {
    setBarcodeInputValue('');
    setSubmittedBarcode(undefined);

    if (scanPackageMutation.data?.data || scanPackageMutation.error) {
      scanPackageMutation.reset();
    }

    resetPrinterState();
  }, [resetPrinterState, scanPackageMutation]);

  // reset on hub type change
  useEffect(() => {
    if (isCentral !== previousIsCentral) {
      resetFeed();

      setTimeout(() => {
        inputRef?.current?.focus();
      }, 0);
    }
  }, [isCentral, previousIsCentral, resetFeed]);

  const onScan = useCallback(
    (scannedEvent) => {
      if (wrongWarehouseModalData?.isOpen) {
        return;
      }

      if (scanPackageMutation.isPending) {
        playScannerErrorSound();
        return;
      }

      const code = scannedEvent?.detail?.scanCode;
      setBarcodeInputValue(code);
      onSubmit(code);
    },
    [
      onSubmit,
      playScannerErrorSound,
      scanPackageMutation.isPending,
      wrongWarehouseModalData?.isOpen,
    ],
  );

  useOnScan(onScan);

  const isManualPrintEnabled = useMemo(
    () =>
      submittedBarcode &&
      printerStatus !== PrinterStatus.Printing &&
      !scanPackageMutation.isPending &&
      scanPackageMutation.data?.data,
    [
      printerStatus,
      scanPackageMutation.data?.data,
      scanPackageMutation.isPending,
      submittedBarcode,
    ],
  );

  const isAlreadyScannedStateVisible =
    submittedBarcode &&
    !scanPackageMutation.isPending &&
    scanPackageMutation.data?.data &&
    isAlreadyScanned(scanPackageMutation.data?.data);

  const isWrongWarehouseStateVisible =
    submittedBarcode &&
    !scanPackageMutation.isPending &&
    scanPackageMutation.error?.data?.errorCode === ApiErrorCode.UnitWrongHub &&
    wrongWarehouseModalData?.isOpen === false;

  useEffect(() => {
    if (isAlreadyScannedStateVisible) {
      playAlreadyScannedSound();
    }
  }, [isAlreadyScannedStateVisible, playAlreadyScannedSound]);

  return (
    <>
      <WrongHubModal
        isOpen={wrongWarehouseModalData?.isOpen}
        onClose={() => {
          setWrongWarehouseModalData({
            isOpen: false,
            correctHubName: undefined,
          });
        }}
        correctHubName={wrongWarehouseModalData?.correctHubName}
      />
      <div>
        <ScanHeader
          scanned={scanned}
          scanningDate={scanningDate}
          total={total}
          onScanningDateChange={(newDate) => {
            if (!newDate) {
              setScanningDate(new Date());
              return;
            }
            setScanningDate(newDate);
          }}
        />
        <div className="w-full rounded-md bg-white shadow-elevation-200 p-5 flex flex-col gap-5 divide-y divide-grey-200">
          <div className="flex flex-col gap-6">
            {isAlreadyScannedStateVisible && (
              <Alert
                data-test="already-scanned-info"
                message={t('This code was already scanned.')}
                variant="info"
              />
            )}
            {isWrongWarehouseStateVisible && (
              <Alert
                data-test="wrong-hub-info"
                message={t('This unit belongs to the {{hubName}} Hub.', {
                  hubName:
                    scanPackageMutation.error?.data?.errors?.correctHubName,
                })}
                variant="error"
              />
            )}
            {scanPackageMutation.data?.data &&
              isEarlyScan(scanPackageMutation.data?.data) && (
                <Alert
                  message={`
                        ${t('Unit early at the local Hub, routing on estimated')} 
                        ${moment(scanPackageMutation.data?.data?.data?.date).format('DD.MM.YYYY')} 
                        (S${scanPackageMutation.data?.data?.data?.shiftNumber}).`}
                  variant="warningOrange"
                />
              )}
            {scanPackageMutation.data?.data &&
              isNotArrivedScan(scanPackageMutation.data?.data) && (
                <Alert
                  data-test="not-arrived-info"
                  message={
                    <Trans i18nKey="The Unit was in not arrived status. The execution date has been updated to today.">
                      The Unit was in <strong>not arrived</strong> status. The
                      execution date has been updated to today.
                    </Trans>
                  }
                  variant="warningOrange"
                />
              )}
            {scanPackageMutation.data?.data &&
              isMissortedScan(scanPackageMutation.data?.data) && (
                <Alert
                  data-test="missorted-info"
                  message={
                    <Trans i18nKey="The Unit was in missorted status. The execution date has been updated to today.">
                      The Unit was in <strong>missorted</strong> status. The
                      execution date has been updated to today.
                    </Trans>
                  }
                  variant="warningOrange"
                />
              )}

            <div className="flex flex-wrap">
              <h1 className="mb-[6px] w-full text-sm font-medium uppercase tracking-[0.5px] text-grey-900">
                {t('Scan code')}
              </h1>
              <div className="mr-4 flex-1">
                <BarcodeInput
                  disabled={scanPackageMutation.isPending}
                  key="barcode-input"
                  ref={inputRef}
                  status={barcodeInputStatus}
                  value={barcodeInputValue}
                  onChange={(e) => {
                    setBarcodeInputValue(e.target.value);
                    if (e.target.value === '') {
                      resetFeed();
                      setTimeout(() => {
                        inputRef?.current?.focus();
                      }, 0);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (
                      (e.key === 'Enter' || e.code === 'Enter') &&
                      barcodeInputValue.length > 0
                    ) {
                      onSubmit(barcodeInputValue);
                    }
                  }}
                />
              </div>
              <div className="w-[180px]">
                <Button
                  data-test="reset-scan"
                  disabled={
                    barcodeInputValue?.length === 0 ||
                    scanPackageMutation.isPending ||
                    printerStatus === PrinterStatus.Printing
                  }
                  isFullWidth
                  text={t('Reset Scan')}
                  variant="outlineBlack"
                  onClick={() => {
                    resetFeed();
                    setTimeout(() => {
                      inputRef?.current?.focus();
                    }, 0);
                  }}
                />
              </div>
            </div>

            <div className="flex flex-wrap">
              <h1 className="mb-[6px] w-full text-sm font-medium uppercase tracking-[0.5px] text-grey-900">
                {t('Printer')}
              </h1>
              <div className="mr-4 flex-1">
                <PrinterInfo status={printerStatus} />
              </div>
              <div className="w-[180px]">
                <Button
                  data-test="manual-print"
                  disabled={!isManualPrintEnabled}
                  isFullWidth
                  text={
                    isAlreadyScannedStateVisible
                      ? t('Reprint Label')
                      : t('Manual Print')
                  }
                  variant="outlineBlack"
                  onClick={async () => {
                    try {
                      const labelZpl = LabelHelper.transformParcelToLabelZpl(
                        scanPackageMutation.data?.data,
                        isCentral,
                        labelSize,
                      );
                      await print(labelZpl);
                      return undefined;
                    } catch (e) {
                      // eslint-disable-next-line no-console
                      console.error(e);
                      return undefined;
                    }
                  }}
                />
              </div>
            </div>

            {printerStatus === PrinterStatus.Error && (
              <PrinterErrorTroubleshootingInfo />
            )}

            {isCentral && (
              <div className="flex items-center justify-between text-sm">
                <div
                  className="flex gap-2 items-center font-medium px-4 py-2.5 border border-grey-200 rounded-md w-full"
                  data-test="auto-print-switch"
                >
                  <Switch
                    checked={isAutoPrintEnabled}
                    onChange={() => {
                      setIsAutoPrintEnabled(!isAutoPrintEnabled);
                    }}
                  />
                  <span>{t('Automatic label printing')}</span>
                </div>
              </div>
            )}
          </div>
          <Metadata
            barcodeInputStatus={barcodeInputStatus}
            unit={scanPackageMutation.data?.data?.data}
            isMissorted={
              scanPackageMutation.data?.data &&
              isMissortedScan(scanPackageMutation.data?.data)
            }
          />
        </div>
      </div>
    </>
  );
};

ScanFeed.propTypes = {
  print: PropTypes.func,
  printerStatus: PropTypes.string,
  resetPrinterState: PropTypes.func,
};

ScanFeed.defaultProps = {
  print: () => {},
  printerStatus: undefined,
  resetPrinterState: () => {},
};

export default ScanFeed;
