import {
  autoUpdate,
  flip,
  offset,
  size as sizeMiddleware,
  useFloating,
} from '@floating-ui/react-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { usePrevious } from 'react-use';

import Breakpoint from '../../enums/Breakpoint';
import useClickOutside from '../../hooks/useClickOutside';
import useWindowSize from '../../hooks/useWindowSize';
import Icon from '../Icon';
import Option, { optionPropType } from './Option';
import SelectContext from './SelectContext';

const Options = (props) => {
  const { indent, options } = props;
  const { activePath, getOptionProps, setActivePath, value } =
    useContext(SelectContext);

  return options.map((option) => {
    const optionProps = getOptionProps(option);
    return (
      <Option
        isActive={option.path.join('-') === activePath?.join('-')}
        isSelected={option.value === value}
        isDisabled={option.isDisabled}
        key={option.value}
        option={option}
        indent={indent}
        onClick={() => {
          if (option.options) {
            setActivePath(option.path);
            return;
          }

          optionProps.onClick();
        }}
      />
    );
  });
};

Options.propTypes = {
  options: PropTypes.arrayOf(optionPropType),
  indent: PropTypes.bool,
};

Options.defaultProps = {
  options: [],
  indent: false,
};

const SingleDropdown = ({
  className,
  floatingReferenceElement,
  minWidth,
  noOptionsMessage,
  onBottomReached,
  options,
  placement,
}) => {
  const { t } = useTranslation();
  const { activePath, close, selectId, setActivePath } =
    useContext(SelectContext);
  const root = document.getElementById(`dropdown-${selectId}`);

  const { inView, ref: bottomRef } = useInView({
    rootMargin: '0px 0px 10px 0px',
    root,
  });
  const previousInView = usePrevious(inView);

  const { width } = useWindowSize();
  const isSmallerScreen = width < Breakpoint.LG;

  const { floatingStyles, refs } = useFloating({
    elements: {
      reference: floatingReferenceElement,
    },
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [
      offset({ mainAxis: 8 }),
      sizeMiddleware({
        apply: ({ elements, rects }) => {
          if (isSmallerScreen) {
            // eslint-disable-next-line no-param-reassign
            elements.floating.style.width = `${rects.reference.width}px`;
          }
        },
      }),
      flip(),
    ],
  });

  useEffect(() => {
    if (inView && !previousInView) {
      onBottomReached();
    }
  }, [onBottomReached, inView, previousInView]);

  const activeOption =
    activePath?.length > 0
      ? activePath.reduce((finalOptions, pathIndex, currentIndex) => {
          if (currentIndex === activePath.length - 1) {
            return finalOptions[pathIndex];
          }
          return finalOptions[pathIndex].options;
        }, options)
      : undefined;

  const filteredOptions =
    activePath?.length > 0
      ? activePath.reduce((finalOptions, pathIndex) => {
          if (finalOptions[pathIndex].options) {
            return finalOptions[pathIndex].options;
          }
          return finalOptions[pathIndex];
        }, options)
      : options;

  const ref = useRef(null);
  useClickOutside(ref, (e) => {
    const isClickOnInput = e.target.closest(
      `input[data-select-id="${selectId}"]`,
    );
    const isClickOnInnerSelectElements = e.target.closest(
      `[data-select-group="${selectId}"]`,
    );

    if (isClickOnInnerSelectElements || isClickOnInput) {
      return;
    }
    close();
  });

  return (
    <div
      data-select-group={selectId}
      data-test="select-dropdown"
      id={`dropdown-${selectId}`}
      ref={(el) => {
        refs.setFloating(el);
        ref.current = el;
      }}
      style={{
        ...floatingStyles,
        minWidth: minWidth || undefined,
      }}
      className={cn(
        'z-30 flex flex-col overflow-auto rounded-md bg-white py-2 shadow-elevation-300',
        className,
        filteredOptions.length <= 6 && 'max-h-[256px]',
        filteredOptions.length > 6 && 'max-h-[233px]',
      )}
    >
      {activeOption && (
        <div className="px-2 flex flex-col">
          <div className="border-b text-sm px-3 py-2.5 w-full items-center flex border-grey-200 text-ui-blue gap-2 font-medium">
            <div
              tabIndex={-1}
              role="button"
              className={
                "w-4 h-4 relative before:content-[''] before:absolute before:w-6 before:h-6 before:-translate-x-1/2 before:-translate-y-1/2 before:left-1/2 before:top-1/2"
              }
              onClick={() => {
                const newPath = activeOption.path.slice(0, -1);
                setActivePath(newPath);
              }}
            >
              <Icon className="w-4 h-4" icon="arrowBack" />
            </div>
            {activeOption.backButtonLabel || <span>&nbsp;</span>}
          </div>
          <div className="px-3 py-2 text-sm text-grey-700">
            {activeOption.groupLabel}
          </div>
        </div>
      )}
      {filteredOptions.length ? (
        <Options options={filteredOptions} />
      ) : (
        <span className="bg-white px-4 py-2.5 text-center text-sm text-grey-700">
          {noOptionsMessage || t('No options')}
        </span>
      )}
      <div ref={bottomRef} />
    </div>
  );
};

SingleDropdown.propTypes = {
  className: PropTypes.string,
  minWidth: PropTypes.number,
  noOptionsMessage: PropTypes.string,
  options: PropTypes.arrayOf(optionPropType),
  placement: PropTypes.string,
  floatingReferenceElement: PropTypes.oneOfType([PropTypes.object]),
  onBottomReached: PropTypes.func,
};

SingleDropdown.defaultProps = {
  className: '',
  minWidth: 240,
  noOptionsMessage: undefined,
  options: [],
  placement: 'bottom-start',
  floatingReferenceElement: null,
  onBottomReached: () => {},
};

export default SingleDropdown;
