import moment from 'moment/moment';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router';

import TasksSearchBy from '../enums/TasksSearchBy';
import TasksSortType from '../enums/TasksSortType';
import { useUserHubId } from '../providers/UserProvider';
import useDateQuery from './useDateQuery';

const useTasksPageSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    searchBy: TasksSearchBy.Code,
  });

  const defaultHubId = useUserHubId();
  const hubIdQuery = searchParams.get('hubId') || defaultHubId;

  const searchByQuery = searchParams.get('searchBy') || '';
  const searchQuery = searchParams.get('search') || '';

  const startDateQuery = useDateQuery('startDate');
  const startDate = startDateQuery
    ? moment(startDateQuery).format('YYYY-MM-DD')
    : undefined;

  const endDateQuery = useDateQuery('endDate');
  const endDate = endDateQuery
    ? moment(endDateQuery).format('YYYY-MM-DD')
    : undefined;

  const statusQuery = searchParams.get('status') || '';
  const typeQuery = searchParams.get('type') || '';
  const shiftQuery = searchParams.get('shiftNumber') || '';
  const clientIdQuery = searchParams.get('clientId') || '';

  const sortTypeQuery = searchParams.get('sortType');
  const currentSortType = useMemo(() => {
    if (sortTypeQuery === TasksSortType.LatestStatusChange) {
      return TasksSortType.LatestStatusChange;
    }
    if (sortTypeQuery === TasksSortType.InvalidAddressFirst) {
      return TasksSortType.InvalidAddressFirst;
    }
    if (sortTypeQuery === TasksSortType.EarliestImport) {
      return TasksSortType.EarliestImport;
    }

    return TasksSortType.LatestExecutionDate;
  }, [sortTypeQuery]);

  useEffect(() => {
    if (!Object.values(TasksSearchBy).includes(searchByQuery)) {
      searchParams.set('searchBy', TasksSearchBy.Code);
      setSearchParams(searchParams, {
        replace: true,
      });
    }
  }, [searchByQuery, searchParams, setSearchParams]);

  const isFilterApplied = useMemo(
    () =>
      !!startDate ||
      !!endDate ||
      !!hubIdQuery ||
      !!searchQuery ||
      !!clientIdQuery ||
      !!shiftQuery ||
      !!statusQuery ||
      !!typeQuery,
    [
      clientIdQuery,
      endDate,
      hubIdQuery,
      searchQuery,
      shiftQuery,
      startDate,
      statusQuery,
      typeQuery,
    ],
  );

  const allParams = {
    clientId: clientIdQuery,
    startDate,
    endDate,
    hubId: hubIdQuery,
    shiftNumber: shiftQuery,
    status: statusQuery,
    type: typeQuery,
    ...(searchQuery && { search: searchQuery }),
    ...(searchQuery && { searchBy: searchByQuery }),
    orderBy: currentSortType,
  };

  return {
    clientIdQuery,
    hubIdQuery,
    currentSortType,
    searchParams,
    shiftQuery,
    setSearchParams,
    searchQuery,
    searchByQuery,
    statusQuery,
    allParams,
    typeQuery,
    isFilterApplied,
    startDateQuery,
    endDateQuery,
  };
};

export default useTasksPageSearchParams;
