import PropTypes from 'prop-types';

import CardListContainer from '../../../components/CardListContainer';
import TourType from '../../../enums/TourType';
import UserRole from '../../../enums/UserRole';
import { useIsRole } from '../../../providers/UserProvider';
import ShiftCard from '../ShiftCard';

const ShiftsCardList = (props) => {
  const { data, isLoading, isPending } = props;
  const showActions = useIsRole([UserRole.Admin]);

  return (
    <CardListContainer
      isLoading={isLoading}
      isPending={isPending}
      isEmpty={data.length === 0}
    >
      {data.map((shift) => (
        <ShiftCard
          key={shift.id}
          id={shift.id}
          hub={shift.hub}
          startTime={shift.startTime}
          endTime={shift.endTime}
          executionSlotStart={shift.executionSlotStart}
          executionSlotEnd={shift.executionSlotEnd}
          routingTime={shift.routingTime}
          standardDrivingTime={shift.standardDrivingTime}
          number={shift.number}
          tourType={shift.tourType}
          fixedTourPrice={shift.fixedTourPrice}
          overtimeHourPrice={shift.overtimeHourPrice}
          showActions={showActions}
        />
      ))}
    </CardListContainer>
  );
};

ShiftsCardList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      hub: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      startTime: PropTypes.string.isRequired,
      endTime: PropTypes.string.isRequired,
      executionSlotStart: PropTypes.string.isRequired,
      executionSlotEnd: PropTypes.string.isRequired,
      number: PropTypes.number.isRequired,
      standardDrivingTime: PropTypes.number,
      fixedTourPrice: PropTypes.number,
      overtimeHourPrice: PropTypes.number,
      tourType: PropTypes.oneOf(Object.values(TourType)),
    }),
  ),
  isLoading: PropTypes.bool,
  isPending: PropTypes.bool,
};

ShiftsCardList.defaultProps = {
  data: [],
  isLoading: false,
  isPending: false,
};

export default ShiftsCardList;
