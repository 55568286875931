import cn from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router';
import { useWindowSize } from 'react-use';

import Icon from '../../../components/Icon';
import Tooltip from '../../../components/Tooltip';
import Breakpoint from '../../../enums/Breakpoint';
import NotificationCountBadge, {
  notificationCountBadgeVariantsMap,
} from './NotificationCountBadge';

const propTypes = {
  badgeNumber: PropTypes.number,
  hideTextOnLg: PropTypes.bool,
  icon: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(notificationCountBadgeVariantsMap)),
};

const defaultProps = {
  hideTextOnLg: true,
  badgeNumber: 0,
  icon: undefined,
  name: undefined,
  onClick: () => {},
  to: undefined,
  variant: undefined,
};

const Inner = ({
  badgeNumber,
  hideTextOnLg,
  icon,
  isActive,
  name,
  onClick,
  variant,
}) => (
  <div
    className={cn(
      'relative flex h-12 w-full cursor-pointer items-center justify-between gap-2 rounded-md p-3',
      isActive
        ? 'bg-primary-dark text-white hover:bg-primary-dark'
        : 'bg-transparent text-primary-dark hover:bg-grey-200',
    )}
    onClick={onClick}
  >
    <div className="flex items-center gap-3">
      {icon && (
        <Icon
          className={cn('h-5 w-5', isActive && 'text-primary-yellow')}
          icon={icon}
        />
      )}
      <span
        data-record="true"
        className={cn(
          'flex-shrink-0 text-base',
          hideTextOnLg && 'lg:invisible',
        )}
      >
        {name}
      </span>
    </div>
    {badgeNumber > 0 && (
      <NotificationCountBadge
        active={isActive}
        count={badgeNumber}
        data-test={`menu-item-link-counter-${variant}`}
        variant={variant}
      />
    )}
  </div>
);

Inner.propTypes = { ...propTypes, isActive: PropTypes.bool };
Inner.defaultProps = { ...defaultProps, isActive: false };

const MenuItem = (props) => {
  const { badgeNumber, hideTextOnLg, icon, name, onClick, to, variant } = props;
  const { width } = useWindowSize();
  const isMobile = width < Breakpoint.LG;

  if (!to) {
    return (
      <div>
        <Tooltip hidden={isMobile} placement="right" size="sm" text={name}>
          <Inner
            isActive={false}
            onClick={onClick}
            badgeNumber={badgeNumber}
            icon={icon}
            name={name}
            hideTextOnLg={hideTextOnLg}
            variant={variant}
          />
        </Tooltip>
      </div>
    );
  }

  return (
    <div>
      <Tooltip hidden={isMobile} placement="right" size="sm" text={name}>
        <NavLink end to={to} onClick={onClick}>
          {({ isActive }) => (
            <Inner
              isActive={isActive}
              badgeNumber={badgeNumber}
              icon={icon}
              name={name}
              hideTextOnLg={hideTextOnLg}
              variant={variant}
            />
          )}
        </NavLink>
      </Tooltip>
    </div>
  );
};

export default MenuItem;

MenuItem.propTypes = propTypes;
MenuItem.defaultProps = defaultProps;
