import { useTranslation } from 'react-i18next';

import SpecialHandling from '../enums/SpecialHandling';

const useSpecialHandlingTranslations = () => {
  const { t } = useTranslation();

  return {
    [SpecialHandling.Fragile]: t('Fragile'),
    [SpecialHandling.HighValue]: t('High-value'),
    [SpecialHandling.PrescriptionMedication]: t('Prescription medication'),
  };
};

export default useSpecialHandlingTranslations;
