import useAsyncFn from '../../../hooks/useAsyncFn';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from './useFetch';

export default () => {
  const { toastFetchError } = useToastFetchError();
  const { fetch } = useFetch();

  const { runAsyncFn: download, status } = useAsyncFn(async (config) => {
    try {
      const searchParamsString = new URLSearchParams(config.params).toString();
      const fullUrl = searchParamsString
        ? `${config.url}?${searchParamsString}`
        : config.url;

      const response = await fetch(fullUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${config.fileName}`;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();

      return response;
    } catch (err) {
      toastFetchError(err);
      return Promise.reject(err);
    }
  });

  return [status, download];
};
