import { Link } from 'react-router';

const Logo = () => (
  <Link to="/">
    <svg
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 8.58031C30 8.22145 29.8077 7.8901 29.4961 7.71206L16.4961 0.28349C16.1887 0.107814 15.8113 0.107815 15.5039 0.28349L2.50386 7.71206C2.19229 7.8901 2 8.22145 2 8.5803V23.4197C2 23.7785 2.19229 24.1099 2.50386 24.2879L15.5039 31.7165C15.8113 31.8922 16.1887 31.8922 16.4961 31.7165L29.4961 24.2879C29.8077 24.1099 30 23.7785 30 23.4197V8.58031Z"
        fill="#1D1D1B"
      />
      <path
        clipRule="evenodd"
        d="M9 17.5V9H13V17.5C13 18.8807 14.1193 20 15.5 20H16.5C17.8807 20 19 18.8807 19 17.5V9H23V17.5C23 21.0899 20.0899 24 16.5 24H15.5C11.9101 24 9 21.0899 9 17.5Z"
        fill="#FFBE2E"
        fillRule="evenodd"
      />
    </svg>
  </Link>
);

export default Logo;
