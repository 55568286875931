import cn from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';

const DrawerTabs = (props) => {
  const { defaultActiveTabIndex, drawerBodyRef, onTabChange, tabs } = props;

  const [activeTabIndex, setActiveTabIndex] = useState(defaultActiveTabIndex);
  const [tabScroll, setTabScroll] = useState(() =>
    tabs.reduce((acc, _tab, index) => ({ ...acc, [index]: 0 }), {}),
  );

  return (
    <>
      <div className="sticky top-0 bg-white z-10">
        <div className="flex flex-row gap-6 px-4 pt-4">
          {tabs.map((tab, index) => {
            const { label } = tab;
            return (
              <button
                className={cn(
                  `min-w-[120px] truncate border-b-2 py-2 text-sm px-2.5 transition-[border] text-center`,
                  activeTabIndex === index
                    ? 'border-b-2 border-ui-blue font-semibold text-primary-dark'
                    : 'border-transparent text-grey-700 font-normal',
                )}
                key={label}
                type="button"
                onClick={() => {
                  setActiveTabIndex(index);
                  onTabChange(index);

                  const currentScrollTop = drawerBodyRef?.current?.scrollTop;
                  setTabScroll({
                    ...tabScroll,
                    [activeTabIndex]: currentScrollTop,
                  });

                  setTimeout(() => {
                    if (!drawerBodyRef?.current) {
                      return;
                    }
                    drawerBodyRef.current.scrollTop = tabScroll[index];
                  }, 0);
                }}
              >
                {label}
              </button>
            );
          })}
        </div>
        <div className="border-b border-b-grey-300" />
      </div>
      <div className="flex-1 p-4 pb-6">{tabs[activeTabIndex].content}</div>
    </>
  );
};

DrawerTabs.propTypes = {
  defaultActiveTabIndex: PropTypes.number,
  onTabChange: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node.isRequired,
      label: PropTypes.string.isRequired,
      'data-test': PropTypes.string,
    }),
  ),
  drawerBodyRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

DrawerTabs.defaultProps = {
  defaultActiveTabIndex: 0,
  onTabChange: () => {},
  tabs: [],
  drawerBodyRef: undefined,
};

export default DrawerTabs;
