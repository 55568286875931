import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link, Navigate, useParams } from 'react-router';

import Button from '../../components/Button';
import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import Icon from '../../components/Icon';
import IconButton from '../../components/IconButton';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import OrganisationType from '../../enums/OrganisationType';
import Workflow from '../../enums/Workflow';
import BasicInformationCard from '../../features/organisations/BasicInformationCard';
import DeleteOrganisation from '../../features/organisations/DeleteOrganisation';
import OrganisationDetailsHubsCard from '../../features/organisations/OrganisationDetailsHubsCard';
import OrganisationDetailsPlatformFeaturesCard from '../../features/organisations/OrganisationDetailsPlatformFeaturesCard';
import OrganisationDetailsShiftsCard from '../../features/organisations/OrganisationDetailsShiftsCard';
import MembersCard from '../../features/shared/MembersCard';
import OrganisationDetailsSectionCard from '../../features/shared/OrganisationDetailsSectionCard';
import useFetch from '../../lib/api/hooks/useFetch';

const OrganisationDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { fetch } = useFetch();

  const {
    data: fetchedOrganisation,
    error,
    isError,
    isPending,
  } = useQuery({
    queryKey: [`/carriers/${id}`],

    queryFn: async () => {
      const response = await fetch(`/carriers/${id}`);
      return response.json();
    },
  });

  const organisation = fetchedOrganisation?.data || null;
  const isOperationsWorkflow = organisation?.workflow === Workflow.Operations;

  if (isError) {
    return <ErrorPage error={error} />;
  }

  if (organisation?.type === OrganisationType.Superadmin) {
    return <Navigate replace to={`/organisations/${id}/members`} />;
  }

  const getDetailsCardTitle = (mainTitle, count) => {
    const subTitle = ((count || count === 0) && `(${count})`) || '';

    return `${mainTitle} ${subTitle}`;
  };

  return (
    <Page>
      <Titlebar
        backLink="/organisations"
        icon={<Icon className="h-5 w-5 text-grey-700" icon="domain" />}
        textPrimary={organisation?.name}
        isLoading={isPending}
      />

      <Page.Content variant="grey">
        <div className="flex flex-col gap-4">
          <div
            className={cn(
              'grid gap-4',
              isOperationsWorkflow ? 'xl:grid-cols-3' : 'xl:grid-cols-2',
            )}
          >
            <MembersCard organisation={organisation} />
            <OrganisationDetailsHubsCard hubCount={organisation?.hubCount} />
            {isOperationsWorkflow && (
              <OrganisationDetailsShiftsCard
                shiftCount={organisation?.shiftCount}
              />
            )}
          </div>

          {isOperationsWorkflow && (
            <OrganisationDetailsSectionCard
              noWrap
              title={
                <div className="flex items-center gap-4">
                  <Icon className="h-5 w-5" icon="settings" />
                  <div className="font-semibold">{t('Routing')}</div>
                </div>
              }
              topRightContent={
                <Button
                  as={Link}
                  className="w-fit"
                  iconRight="chevronRight"
                  iconSize="s"
                  text={t('Configuration')}
                  to="routing"
                  variant="outlineBlue"
                />
              }
            />
          )}

          <div>
            {isPending ? (
              <Skeleton wrapper={Card} height={214} />
            ) : (
              <BasicInformationCard
                organisation={organisation}
                topRightContent={
                  <Button
                    as={Link}
                    className="w-full sm:w-fit"
                    icon="edit"
                    state={{
                      backLink: `/organisations/${organisation.id}`,
                    }}
                    text={t('Edit')}
                    to="edit"
                    variant="outlineBlack"
                  />
                }
              />
            )}
          </div>
          <div className="grid gap-4 xl:grid-cols-2">
            <OrganisationDetailsSectionCard
              noWrap
              title={getDetailsCardTitle(
                t('Subcarriers'),
                organisation?.subcarrierCount,
              )}
              topRightContent={
                <IconButton
                  as={Link}
                  icon="chevronRight"
                  iconSize="xs"
                  size="m"
                  to="subcarriers"
                  variant="outlineBlack"
                />
              }
            />

            <OrganisationDetailsSectionCard
              noWrap
              title={getDetailsCardTitle(
                t('Clients'),
                organisation?.clients.length,
              )}
              topRightContent={
                <IconButton
                  as={Link}
                  icon="chevronRight"
                  iconSize="xs"
                  size="m"
                  to="clients"
                  variant="outlineBlack"
                />
              }
            />
          </div>
          {isPending ? (
            <Skeleton wrapper={Card} height={160} />
          ) : (
            <OrganisationDetailsSectionCard title={t('Settings')}>
              <OrganisationDetailsPlatformFeaturesCard
                organisationId={organisation?.id}
                organisationType={OrganisationType.Carrier}
              />
              <DeleteOrganisation organisation={organisation} />
            </OrganisationDetailsSectionCard>
          )}
        </div>
      </Page.Content>
    </Page>
  );
};

export default OrganisationDetails;
