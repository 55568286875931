import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router';

import Button from '../../../components/Button';
import CopyToClipboardButton from '../../../components/CopyToClipboardButton';
import DrawerCard from '../../../components/Drawer/DrawerCard';
import DrawerTextRow from '../../../components/Drawer/DrawerTextRow';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Icon from '../../../components/Icon';
import IconButton from '../../../components/IconButton';
import LinkButton from '../../../components/LinkButton';
import Tooltip from '../../../components/Tooltip';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import FailureReason from '../../../enums/FailureReason';
import PostponeReason from '../../../enums/PostponeReason';
import ReturnReason from '../../../enums/ReturnReason';
import TableId from '../../../enums/TableId';
import UnitProgress from '../../../enums/UnitProgress';
import UnitsHelper from '../../../helpers/UnitsHelper';
import useDeliveryOptionsTranslations from '../../../hooks/useDeliveryOptionsTranslations';
import useDeliveryUnitStatusTranslations from '../../../hooks/useDeliveryUnitStatusTranslations';
import useFailureReasonTranslations from '../../../hooks/useFailureReasonTranslations';
import usePostponeReasonTranslations from '../../../hooks/usePostponeReasonTranslations';
import useReturnReasonTranslations from '../../../hooks/useReturnReasonTranslations';
import useUnitProgress from '../../../hooks/useUnitProgress';
import CancelUnitModal from '../../units/CancelUnitModal';
import eventType from '../../units/UnitEventHistory/eventType';
import UpdateUnitCode from '../../units/UpdateUnitCode';
import UnitStatusUpdate from '../UnitStatusUpdate';

const UnitDetailsGeneralInfo = ({ unit }) => {
  const location = useLocation();

  const { t } = useTranslation();
  const deliveryUnitStatusTranslations = useDeliveryUnitStatusTranslations();
  const unitProgress = useUnitProgress();
  const [isUnitStatusUpdateOpen, setIsUnitStatusUpdateOpen] = useState(false);
  const [isUnitUpdateCodeOpen, setIsUnitUpdateCodeOpen] = useState(false);
  const [isCancelUnitModalVisible, setIsCancelUnitModalVisible] =
    useState(false);

  const isCancelable = unit.status === DeliveryTaskUnitStatus.Created;
  const isCanceled = unit.status === DeliveryTaskUnitStatus.Cancelled;
  const isCodeChangeDisabled = UnitsHelper.isReferenceUpdateDisabled(unit);
  const isTooltipHidden = UnitsHelper.isCancelUnitTooltipHidden(unit);

  const menuItems = [
    {
      icon: 'codeScan',
      iconClassName: 'text-grey-700',
      onClick: () => {
        if (!isCodeChangeDisabled) {
          setIsUnitUpdateCodeOpen(true);
        }
      },
      text: t('Update Unit code'),
      isDisabled: isCodeChangeDisabled,
    },
    {
      icon: 'updateUnitStatus',
      iconClassName: 'text-grey-700',
      onClick: () => setIsUnitStatusUpdateOpen(true),
      text: t('Update Unit status'),
    },
  ];

  const tourStopText = useMemo(() => {
    if (unit.latestTour?.totalStops && unit.latestTour?.tourStop?.sequence) {
      return `${unit.latestTour.vehicle} ${unit.latestTour?.tourStop?.sequence}/${unit.latestTour.totalStops}`;
    }
    return unit.latestTour?.vehicle;
  }, [unit.latestTour]);

  const failureReasonTranslations = useFailureReasonTranslations();
  const returnReasonTranslations = useReturnReasonTranslations();
  const postponedReasonsTranslations = usePostponeReasonTranslations();
  const { nameTranslations } = useDeliveryOptionsTranslations();
  const executionTypeText =
    unit.deliveryOption === null
      ? t('Customer (direct)')
      : nameTranslations[unit.deliveryOption];

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col text-sm">
            <div className="text-sm font-medium mb-4">
              {t('Task Reference')}
            </div>

            <div className="flex flex-col gap-4">
              <DrawerTextRow
                title={
                  <div className="flex gap-2">
                    <Icon className="h-5 w-5 text-grey-700" icon="task" />
                    <div className="font-medium">{t('Task Code')}</div>
                  </div>
                }
                content={
                  <div className="flex gap-[2px] items-center">
                    <CopyToClipboardButton
                      info={unit.task?.code}
                      tooltipText={t('Copy Task code')}
                    />
                    <LinkButton
                      className="break-all"
                      as={Link}
                      text={unit.task?.code || ''}
                      state={{
                        [TableId.Tasks]: {
                          expanded: {
                            [unit.task?.id]: true,
                          },
                        },
                      }}
                      to={{
                        pathname: `/tasks`,
                        search: `search=${encodeURIComponent(unit.task?.code)}&page=1`,
                      }}
                    />
                  </div>
                }
              />

              <DrawerTextRow
                title={t('Unit Number')}
                content={`${unit.number}/${unit.unitsInTask}`}
              />
            </div>

            <div className="border-grey-200 border-b my-4" />

            <div className="flex flex-col gap-4">
              <DrawerCard
                title={t('Unit Info')}
                rightContent={
                  <Dropdown menu={menuItems} isDisabled={isCanceled}>
                    <IconButton
                      icon="edit"
                      iconSize="s"
                      size="s"
                      variant="ghostBlack"
                      disabled={isCanceled}
                    />
                  </Dropdown>
                }
              >
                <div className="flex flex-col gap-3 pt-2.5">
                  <DrawerTextRow
                    title={t('Unit Code')}
                    content={
                      <div className="flex gap-[2px] items-center">
                        <CopyToClipboardButton
                          info={unit.code}
                          tooltipText={t('Copy Unit code')}
                        />
                        <span>{unit.code}</span>
                      </div>
                    }
                  />
                  <DrawerTextRow
                    title={t('Unit Status')}
                    content={
                      <div className="items-center flex gap-2 ">
                        {unitProgress[unit.progress] && (
                          <Icon
                            className={cn(
                              unitProgress[unit.progress].labelIconClassname,
                              'h-4 w-4',
                            )}
                            icon={unitProgress[unit.progress].labelIcon}
                          />
                        )}
                        {deliveryUnitStatusTranslations[unit.status]}
                      </div>
                    }
                    collapsedContent={unit.missortedHub}
                  />
                  {(unit.failureReason ||
                    unit.postponeReason ||
                    unit.returnReason) && (
                    <DrawerTextRow
                      title={t('Failure Reason')}
                      content={
                        failureReasonTranslations[unit.failureReason] ||
                        returnReasonTranslations[unit.returnReason] ||
                        postponedReasonsTranslations[unit.postponeReason]
                      }
                      collapsedContent={unit.reasonNote}
                    />
                  )}
                  {unit.status === DeliveryTaskUnitStatus.Delivered && (
                    <DrawerTextRow
                      title={t('Execution Type')}
                      content={executionTypeText}
                      collapsedContent={unit.deliveryOptionNote}
                    />
                  )}

                  <DrawerTextRow
                    title={t('Status Updated At')}
                    content={moment(unit.statusChangeTime).format(
                      'DD.MM.YYYY, HH:mm',
                    )}
                  />
                </div>
              </DrawerCard>

              <DrawerCard title={t('Execution')}>
                <div className="flex flex-col gap-3 pt-2.5">
                  <div className="flex flex-row items-center justify-between text-grey-700">
                    <div className="font-medium flex-1">{t('Date')}</div>
                    <div className="flex-1 text-right font-medium text-primary-dark">
                      {moment(unit.date).format('DD.MM.YYYY')}
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between text-grey-700">
                    <div className="font-medium flex-1">{t('Slot')}</div>
                    <div className="flex-1 text-right font-medium text-primary-dark">
                      {moment(unit.timeFrom).format('HH:mm')}-
                      {moment(unit.timeTo).format('HH:mm')} (S
                      {unit.shiftNumber})
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between text-grey-700">
                    <div className="font-medium flex-1">{t('Window')}</div>
                    <div className="flex-1 text-right font-medium text-primary-dark">
                      {unit.latestTour ? (
                        <>
                          {moment(unit.latestTour.tourStop.windowFrom).format(
                            'HH:mm',
                          )}
                          -
                          {moment(unit.latestTour.tourStop.windowTo).format(
                            'HH:mm',
                          )}
                        </>
                      ) : (
                        'N/A'
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between text-grey-700">
                    <div className="font-medium flex-1">{t('Tour Stop')}</div>
                    <div className="items-center flex gap-2 text-primary-dark font-medium">
                      {unit.latestTour ? (
                        <LinkButton
                          as={Link}
                          text={tourStopText}
                          state={{
                            backLink: location.pathname + location.search,
                          }}
                          to={{
                            pathname: `/tours/${unit.latestTour.id}`,
                            search: `?stopId=${unit.latestTour.tourStop?.id}`,
                          }}
                        />
                      ) : (
                        'N/A'
                      )}
                    </div>
                  </div>
                </div>
              </DrawerCard>

              <DrawerCard title={t('Address and Hub')}>
                <div className="flex flex-col gap-3 pt-2.5">
                  <DrawerTextRow
                    className="text-primary-dark"
                    title={unit.address}
                    collapsedContent={
                      (unit.addressNote ||
                        unit.endCustomerNote ||
                        unit.what3words) && (
                        <div className="text-sm">
                          {unit.addressNote && <div>{unit.addressNote}</div>}
                          {unit.endCustomerNote && (
                            <div>{unit.endCustomerNote}</div>
                          )}
                          {unit.what3words && (
                            <div>{`///${unit.what3words}`}</div>
                          )}
                        </div>
                      )
                    }
                  />
                  <div className="font-semibold">
                    {unit.hub?.shortCode || 'N/A'}
                  </div>
                </div>
              </DrawerCard>

              <DrawerCard title={t('Customer Info')}>
                <div className="flex flex-col gap-3 pt-2.5">
                  {unit.firstName && unit.lastName && (
                    <div className="flex-1 font-medium text-primary-dark flex items-center gap-[2px]">
                      {unit.firstName} {unit.lastName}
                    </div>
                  )}

                  {unit.phoneNumber && (
                    <div className="flex-1 font-medium text-primary-dark flex items-center gap-[2px]">
                      <CopyToClipboardButton
                        info={unit.phoneNumber}
                        tooltipText={t('Copy phone number')}
                      />
                      <span>{unit.phoneNumber}</span>
                    </div>
                  )}
                  {unit.email && (
                    <div className="flex-1 font-medium text-primary-dark flex items-center gap-[2px]">
                      <CopyToClipboardButton
                        info={unit.email}
                        tooltipText={t('Copy e-mail')}
                      />
                      <span>{unit.email}</span>
                    </div>
                  )}
                </div>
              </DrawerCard>

              <DrawerCard title={t('Import Date')}>
                <div className="flex flex-col gap-3 pt-2.5 font-medium">
                  {moment(unit.createdAt).format('DD.MM.YYYY')}
                </div>
              </DrawerCard>

              <Tooltip
                hidden={isTooltipHidden}
                text={t(
                  "The Unit has already arrived at Urbify. To cancel it, update its status to 'Return to Client: Canceled'.",
                )}
              >
                <Button
                  isFullWidth
                  disabled={!isCancelable}
                  size="s"
                  variant="outlineRed"
                  onClick={() => setIsCancelUnitModalVisible(true)}
                  text={t('Cancel Unit')}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      <UnitStatusUpdate
        isOpen={isUnitStatusUpdateOpen}
        units={[unit]}
        onClose={() => setIsUnitStatusUpdateOpen(false)}
        transparentBackdrop
        showBackButton
      />
      <UpdateUnitCode
        transparentBackdrop
        showBackButton
        isOpen={isUnitUpdateCodeOpen}
        unit={unit}
        onClose={() => setIsUnitUpdateCodeOpen(false)}
      />
      <CancelUnitModal
        isOpen={isCancelUnitModalVisible}
        unit={unit}
        onClose={() => setIsCancelUnitModalVisible(false)}
      />
    </>
  );
};

UnitDetailsGeneralInfo.propTypes = {
  unit: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    attempt: PropTypes.number,
    status: PropTypes.oneOf(Object.values(DeliveryTaskUnitStatus)),
    statusChangeTime: PropTypes.string,
    number: PropTypes.number,
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
    progress: PropTypes.oneOf(Object.values(UnitProgress)),
    shiftNumber: PropTypes.number,
    unitsInTask: PropTypes.number,
    eventNote: PropTypes.string,
    date: PropTypes.string,
    task: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
    }),
    events: PropTypes.arrayOf(eventType),
    address: PropTypes.string,
    addressNote: PropTypes.string,
    endCustomerNote: PropTypes.string,
    hub: PropTypes.shape({
      id: PropTypes.string,
      shortCode: PropTypes.string,
    }),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    createdAt: PropTypes.string,
    latestTour: PropTypes.shape({
      date: PropTypes.string,
      id: PropTypes.string,
      vehicle: PropTypes.string,
      totalStops: PropTypes.number,
      tourStop: PropTypes.shape({
        id: PropTypes.string,
        sequence: PropTypes.number,
        windowFrom: PropTypes.string,
        windowTo: PropTypes.string,
      }),
    }),
    missortedHub: PropTypes.string,
    failureReason: PropTypes.oneOf(Object.values(FailureReason)),
    postponeReason: PropTypes.oneOf(Object.values(PostponeReason)),
    returnReason: PropTypes.oneOf(Object.values(ReturnReason)),
    reasonNote: PropTypes.string,
    deliveryOptionNote: PropTypes.string,
    deliveryOption: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
    what3words: PropTypes.string,
  }),
};

UnitDetailsGeneralInfo.defaultProps = {
  unit: undefined,
};
export default UnitDetailsGeneralInfo;
