// Sentry initialization should be imported first!
import './instrument';
// fix for import https://github.com/moment/moment/issues/5926#issuecomment-1056785251
import 'moment/dist/locale/de';
import './translations';
import './index.css';
import 'react-loading-skeleton/dist/skeleton.css';

import moment from 'moment';
import { createRoot } from 'react-dom/client';

import App from './app/App';
import registerServiceWorker from './serviceWorkerRegistration';
import getCurrentLanguage from './translations/getCurrentLanguage';

moment.locale(getCurrentLanguage());

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(<App />);

// check readme.md for more info
if ('serviceWorker' in navigator) {
  registerServiceWorker();
}
