import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

const CollapsableCardsContext = createContext();

export const useCollapsableCardsContext = () => {
  const context = useContext(CollapsableCardsContext);
  if (context === undefined) {
    throw new Error(
      'useCollapsableCardsContext must be used within a CollapsableCardsProvider',
    );
  }
  return context;
};

const CollapsableCardsProvider = ({
  children,
  defaultOpenIds,
  id,
  isMultipleExpandable,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationCardState = location?.state?.[id];
  const [openedCardIds, setOpenedCardIds] = useState([
    ...defaultOpenIds,
    ...(locationCardState || []),
  ]);

  const value = useMemo(() => {
    const openCardIds = (cardIds) => {
      if (id) {
        navigate(location, {
          preventScrollReset: true,
          replace: true,
          state: {
            [id]: cardIds,
          },
        });
      }

      setOpenedCardIds(cardIds);
    };

    return {
      openedCardIds,
      setOpenedCardIds: openCardIds,
      isMultipleExpandable,
    };
  }, [id, isMultipleExpandable, location, navigate, openedCardIds]);

  return (
    <CollapsableCardsContext.Provider value={value}>
      {children}
    </CollapsableCardsContext.Provider>
  );
};

CollapsableCardsProvider.propTypes = {
  id: PropTypes.string,
  isMultipleExpandable: PropTypes.bool,
  children: PropTypes.node.isRequired,
  defaultOpenIds: PropTypes.arrayOf(PropTypes.string),
};

CollapsableCardsProvider.defaultProps = {
  id: undefined,
  isMultipleExpandable: false,
  defaultOpenIds: [],
};

export default CollapsableCardsProvider;
