import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router';

import Button from '../../components/Button';
import CancelPrompt from '../../components/CancelPrompt';
import { Card } from '../../components/Card';
import ErrorPage from '../../components/ErrorPage';
import Icon from '../../components/Icon';
import Page from '../../components/Page';
import Titlebar from '../../components/Titlebar';
import OrganisationForm from '../../features/shared/OrganisationForm';
import useBlocker from '../../hooks/useBlocker';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';

const EditClient = () => {
  const { toastSuccess } = useCustomToast();
  const navigate = useNavigate();
  const { fetch } = useFetch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { data, error, isError, isPending } = useQuery({
    queryKey: [`/clients/`, id],

    queryFn: async () => {
      const response = await fetch(`/clients/${id}`, {
        method: 'GET',
      });
      return response.json();
    },
  });

  const organisation = data?.data || null;

  const { toastFetchError } = useToastFetchError();
  const queryClient = useQueryClient();
  const updateClient = useMutation({
    mutationFn: async (values) => {
      const response = await fetch(`/clients/${id}`, {
        body: {
          address: values.address,
          publicName: values.publicName,
          ...(values.usePublicAsInternal
            ? { name: values.publicName }
            : { name: values.name }),
        },
        method: 'PATCH',
      });
      return response.json();
    },
    onError: toastFetchError,
    onSuccess: () => {
      setIsFormDirty(false);
      toastSuccess(t('Organisation data successfully updated.'));
      queryClient.invalidateQueries({
        queryKey: [`/carriers/`, id],
      });
    },
  });

  const shouldBlock = useMemo(() => {
    if (updateClient.isSuccess) {
      return false;
    }
    return isFormDirty;
  }, [isFormDirty, updateClient.isSuccess]);

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (updateClient.isSuccess) {
      navigate(`/clients/${id}`, {
        replace: true,
      });
    }
  }, [id, navigate, updateClient.isSuccess]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      <CancelPrompt
        title={t('Cancel Editing Organisation?')}
        isOpen={blocker.state === 'blocked'}
        onClose={() => {
          blocker.reset();
        }}
        onExitClick={() => {
          blocker.proceed();
        }}
      />
      <Page>
        <Titlebar
          icon={<Icon className="h-5 w-5 text-ui-blue" icon="edit" />}
          textPrimary={t('Edit')}
          textSecondary={`${organisation?.name} (${organisation?.shortCode})`}
          titleRightContent={
            <Button
              className="w-full lg:w-fit"
              disabled={updateClient.isPending}
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => navigate(`/clients/${id}`)}
            />
          }
          isLoading={isPending}
        />

        <Page.Content centerContent variant="grey">
          <div className="w-full max-w-[480px]">
            <Card className="pt-6 sm:p-8">
              {isPending ? (
                <div className="grid gap-8">
                  <Skeleton height={156} />
                  <Skeleton height={110} />
                  <Skeleton height={110} />
                </div>
              ) : (
                <OrganisationForm
                  errorCode={updateClient?.error?.data?.errorCode}
                  errors={updateClient?.error?.data?.errors}
                  isClient
                  isEdit
                  isLoading={isPending}
                  isSubmitting={updateClient.isPending}
                  organisation={organisation}
                  onCancel={() => navigate(`/clients/${id}`)}
                  onIsDirtyChange={setIsFormDirty}
                  onSubmit={updateClient.mutate}
                />
              )}
            </Card>
          </div>
        </Page.Content>
      </Page>
    </>
  );
};

export default EditClient;
