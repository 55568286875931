import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Drawer from '../../../components/Drawer';
import FormInput from '../../../components/FormInput';
import Icon from '../../../components/Icon';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useFetch from '../../../lib/api/hooks/useFetch';

const UpdateUnitCode = ({
  isOpen,
  onClose,
  showBackButton,
  transparentBackdrop,
  unit,
}) => {
  const { t } = useTranslation();
  const { fetch } = useFetch();
  const queryClient = useQueryClient();
  const { toastSuccess } = useCustomToast();
  const { toastFetchError } = useToastFetchError();

  const methods = useForm({
    defaultValues: {
      reference: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const {
    formState: { isDirty },
    reset,
    setValue,
  } = methods;

  useEffect(() => {
    if (unit?.importReference && isOpen) {
      setValue('reference', unit?.code);
    }
  }, [setValue, unit?.importReference, unit?.code, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [reset, isOpen]);

  const mutation = useMutation({
    mutationFn: async (values) => {
      const response = await fetch(`/units/${unit.id}/reference`, {
        body: values,
        method: 'PATCH',
      });
      return response.json();
    },
    onError: (error) => {
      toastFetchError(error);
    },
    onSuccess: () => {
      onClose();
      toastSuccess(t('Unit code updated.'));
      queryClient.invalidateQueries({
        queryKey: ['/units'],
      });
      queryClient.invalidateQueries({
        queryKey: [`/units/${unit.id}`],
      });
      queryClient.invalidateQueries({
        queryKey: [`/units/${unit.id}`, 'details'],
      });
    },
  });

  return (
    <FormProvider {...methods}>
      <Drawer
        showBackButton={showBackButton}
        transparentBackdrop={transparentBackdrop}
        onClose={onClose}
        isOpen={isOpen}
        body={
          <div className="flex flex-col gap-4">
            <div className="flex justify-between text-sm gap-1">
              <div className="flex items-center gap-3">
                <Icon className="h-4 w-4 text-grey-700" icon="parcel" />
                <span>{t('Initial (import) Unit code')}</span>
              </div>
              <div className="text-sm font-medium text-primary-dark whitespace-nowrap">
                {unit?.importReference || unit?.code}
              </div>
            </div>

            <div className="border-b border-grey-200 w-full" />
            <FormInput
              id="new-unit-code"
              label={t('New Unit Code')}
              name="reference"
              placeholder={t('Enter new Unit code')}
              required
              minLength={{
                message: t('New Unit Code must be at least 3 characters long.'),
                value: 3,
              }}
            />
          </div>
        }
        footer={
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
            <Button
              className="order-last sm:order-none"
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => {
                onClose();
              }}
            />
            <Button
              disabled={mutation.isPending || !isDirty}
              text={t('Save Changes')}
              variant="solidBlue"
              onClick={methods.handleSubmit(mutation.mutate)}
            />
          </div>
        }
        header={<span className="capitalize">{t('Update Unit code')}</span>}
      />
    </FormProvider>
  );
};

export default UpdateUnitCode;

UpdateUnitCode.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  unit: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    importReference: PropTypes.string,
    status: PropTypes.oneOf(Object.values(DeliveryTaskUnitStatus)),
    address: PropTypes.string,
    unitsInTask: PropTypes.number,
  }),
  showBackButton: PropTypes.bool,
  transparentBackdrop: PropTypes.bool,
};

UpdateUnitCode.defaultProps = {
  isOpen: false,
  onClose: undefined,
  unit: undefined,
  showBackButton: false,
  transparentBackdrop: false,
};
