import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router';

import Button from '../../../components/Button';
import CollapsableCard from '../../../components/CollapsableCard';
import Label from '../../../components/Label';

const ClientCard = (props) => {
  const { address, id, memberCount, name, publicName, shortCode } = props;
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <CollapsableCard
      id={id}
      expandedContent={
        <div className="flex flex-col gap-1">
          <div className="flex flex-col divide-y divide-grey-200 border-t border-grey-200">
            <div className="flex flex-col py-2">
              <span className="mb-1 text-xs text-grey-700">
                {t('Public Name')}
              </span>
              <span className="text-sm">{publicName}</span>
            </div>

            <div className="flex flex-col py-2">
              <span className="mb-1 text-xs text-grey-700">{t('Members')}</span>
              <span className="text-sm">{memberCount}</span>
            </div>

            <div className="flex flex-col py-2">
              <span className="mb-1 text-xs text-grey-700">{t('Address')}</span>
              <span className="text-sm">{address}</span>
            </div>
          </div>

          <div className="flex flex-col border-t-[1px] border-grey-200 pt-4">
            <Button
              as={Link}
              icon="pageView"
              isFullWidth
              text={t('View Organisation Details')}
              to={`/clients/${id}`}
              variant="outlineBlack"
              state={{ backLink: location.pathname + location.search }}
            />
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-1">
        <div>
          <span className="text-base font-medium">
            <span className="mr-2 align-middle">{name}</span>
            <Label
              className="align-middle "
              size="xs"
              text={shortCode}
              variant="greyOutline"
            />
          </span>
        </div>
      </div>
    </CollapsableCard>
  );
};

ClientCard.propTypes = {
  address: PropTypes.string,
  id: PropTypes.string,
  memberCount: PropTypes.number,
  name: PropTypes.string,
  shortCode: PropTypes.string,
  publicName: PropTypes.string,
};

ClientCard.defaultProps = {
  address: undefined,
  id: undefined,
  memberCount: undefined,
  name: undefined,
  shortCode: undefined,
  publicName: undefined,
};

export default ClientCard;
