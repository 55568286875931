import { useTranslation } from 'react-i18next';

import HubType from '../enums/HubType';

const useHubTranslation = () => {
  const { t } = useTranslation();
  return {
    [HubType.Central]: t('Central'),
    [HubType.Local]: t('Local'),
  };
};

export default useHubTranslation;
