import { useMutation, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import logo from '../../assets/images/logo-full.svg';
import Button from '../../components/Button';
import FormInput from '../../components/FormInput';
import PageLoader from '../../components/PageLoader';
import UserRole from '../../enums/UserRole';
import useCustomToast from '../../hooks/useCustomToast';
import useToastFetchError from '../../hooks/useToastFetchError';
import useFetch from '../../lib/api/hooks/useFetch';
import { useUser } from '../../providers/UserProvider';
import ActivateUserErrorScreen from './ActivateUserErrorScreen';

const ActivateUserGuestScreen = () => {
  const { setUser } = useUser();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { token } = useParams();
  const GOOGLE_PLAY_URL =
    // old URL because package name can not be changed
    'https://play.google.com/store/apps/details?id=de.urbeee.service';
  const { fetch } = useFetch();
  const { toastError, toastSuccess } = useCustomToast();
  const { toastFetchError } = useToastFetchError();

  const methods = useForm({
    defaultValues: {
      confirmPassword: '',
      email: '',
      password: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });
  const { handleSubmit, setValue, watch } = methods;
  const watchEmail = watch('email');

  const {
    data: fetchedUserData,
    error: fetchedUserError,
    isError: fetchUserIsError,
    isPending: fetchUserIsPending,
  } = useQuery({
    queryKey: [`/users/email/${token}`],
    queryFn: async () => {
      const response = await fetch(`/users/email/${token}`);
      return response.json();
    },
    retry: false,
    meta: {
      skipOnError: true,
    },
  });

  useEffect(() => {
    if (fetchedUserError) {
      toastError(t('Registration unsuccessful.'));
    }
  }, [fetchedUserError, t, toastError]);

  useEffect(() => {
    setValue('email', fetchedUserData?.data?.email);
  }, [fetchedUserData?.data?.email, setValue]);

  const redirectToDriversMessage = () => {
    window.location.href = GOOGLE_PLAY_URL;
  };

  const magicPasswordMutation = useMutation({
    mutationFn: async (values) => {
      const response = await fetch(`/users/magic-password`, {
        body: {
          confirmPassword: values.confirmPassword,
          magicToken: token,
          password: values.password,
        },
        method: 'POST',
      });
      return response.json();
    },
  });

  const webLoginMutation = useMutation({
    mutationFn: async (body) => {
      const response = await fetch('/users/login', {
        body,
        method: 'POST',
      });

      return response.json();
    },
    onError: toastFetchError,
    onSuccess: (response) => {
      toastSuccess(
        <>
          <div>{t('Registered successfully.')}</div>
          <div>{t('Welcome aboard!')}</div>
        </>,
      );
      setUser(response?.data);
      navigate('/', {
        replace: true,
      });
    },
  });

  const onSubmit = useCallback(
    async (values) => {
      try {
        const magicPasswordResponse =
          await magicPasswordMutation.mutateAsync(values);

        const { data: { role } = {} } = magicPasswordResponse;
        const isDriver = role === UserRole.Driver;

        if (isDriver) {
          redirectToDriversMessage();
        } else {
          const dataForWebLogin = {
            email: values.email,
            password: values.password,
          };

          webLoginMutation.mutate(dataForWebLogin);
        }
      } catch (e) {
        toastFetchError(e);
      }
    },
    [magicPasswordMutation, toastFetchError, webLoginMutation],
  );

  return (
    <div className="min-h-full w-full max-w-full bg-grey-200">
      {(magicPasswordMutation.isPending ||
        webLoginMutation.isPending ||
        fetchUserIsPending) && <PageLoader />}

      {!fetchUserIsError && !fetchUserIsPending && (
        <div className="mx-auto flex flex-1 flex-col items-center justify-center pt-18">
          <img
            alt="Urbify logo"
            className="h-[96px] w-[128px]"
            height="96"
            src={logo}
          />

          <div className="px-4">
            <p className="mt-[60px] max-w-[320px] pb-8 text-center font-medium">
              {t(
                'Complete the registration by creating a password for your account.',
              )}
            </p>
          </div>

          <div className="mb-[60px] flex w-full justify-center px-4">
            <div className="w-full rounded-md bg-white px-4 py-5 shadow-elevation-200 sm:w-[480px] sm:p-8">
              <FormProvider {...methods}>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-col gap-5">
                    <p className="text-base font-semibold text-primary-dark">
                      {t('Create password')}
                    </p>

                    <div className="flex flex-col gap-4">
                      <FormInput
                        disabled={!!watchEmail}
                        id="email"
                        label={t('Your Account E-mail Address')}
                        name="email"
                        required
                        type="email"
                      />

                      <FormInput
                        id="password"
                        label={t('Password')}
                        minLength={{
                          message: t(
                            'Password has to be longer than 8 characters',
                          ),
                          value: 8,
                        }}
                        name="password"
                        placeholder={t('Enter password')}
                        required
                        type="password"
                      />

                      <FormInput
                        id="confirm-password"
                        label={t('Repeat Password')}
                        minLength={{
                          message: t(
                            'Password has to be longer than 8 characters',
                          ),
                          value: 8,
                        }}
                        name="confirmPassword"
                        placeholder={t('Repeat password')}
                        required
                        type="password"
                        validate={(confirmPassword) => {
                          const password = watch('password');
                          if (confirmPassword !== password) {
                            return t('Passwords must match');
                          }
                          return undefined;
                        }}
                      />
                    </div>

                    <div className="border-b-2 border-grey-200" />

                    <Button
                      isFullWidth
                      text={t('Finish Account Setup')}
                      type="submit"
                      variant="solidBlack"
                    />
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      )}
      {fetchUserIsError && (
        <ActivateUserErrorScreen fetchedUserError={fetchedUserError} />
      )}
    </div>
  );
};

ActivateUserGuestScreen.propTypes = {
  children: PropTypes.node,
};

ActivateUserGuestScreen.defaultProps = {
  children: undefined,
};

export default ActivateUserGuestScreen;
