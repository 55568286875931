import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import Button from '../../../components/Button';
import CollapsableCard from '../../../components/CollapsableCard';
import Collapse from '../../../components/Collapse';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Icon from '../../../components/Icon';
import LinkButton from '../../../components/LinkButton';
import ListSelectionCheckbox from '../../../components/ListSelectionCheckbox';
import Tooltip from '../../../components/Tooltip';
import DeliveryTaskUnitStatus from '../../../enums/DeliveryTaskUnitStatus';
import UnitsHelper from '../../../helpers/UnitsHelper';
import useCopyUnitInfoToClipboard from '../../../hooks/useCopyUnitInfoToClipboard';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import EndCustomerPopover from '../../shared/EndCustomerPopover';
import ShiftLabel from '../../shared/ShiftLabel';
import UnitsStatusAndChangeTime from '../../shared/UnitsStatusAndChangeTime';

const Row = ({ children, title }) => (
  <div className="flex flex-col gap-2 border-t border-grey-200 py-3">
    {title && <span className="text-grey-700 text-xs">{title}</span>}
    <div>{children}</div>
  </div>
);
Row.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

Row.defaultProps = {
  title: undefined,
};

const UnitCard = (props) => {
  const {
    onDetailsClick,
    onUnitCancel,
    onUpdateUnitCodeClick,
    onUpdateUnitStatusClick,
    unit,
    ...rest
  } = props;
  const { t } = useTranslation();
  const [seeAll, setSeeAll] = useState(false);
  const copyUnitInfoToClipboard = useCopyUnitInfoToClipboard();
  const { isSelectionVisible } = useListSelection();

  const isCancelable = unit.status === DeliveryTaskUnitStatus.Created;
  const isCanceled = unit.status === DeliveryTaskUnitStatus.Cancelled;
  const isCodeChangeDisabled = UnitsHelper.isReferenceUpdateDisabled(unit);
  const isTooltipHidden = UnitsHelper.isCancelUnitTooltipHidden(unit);

  const menuItems = useMemo(
    () => [
      {
        icon: 'codeScan',
        iconClassName: 'text-grey-700',
        text: t('Update Unit code'),
        isDisabled: isCodeChangeDisabled,
        onClick: () => {
          if (!isCodeChangeDisabled) {
            onUpdateUnitCodeClick();
          }
        },
      },
      {
        icon: 'copy',
        iconClassName: 'text-grey-700',
        text: t('Copy info to clipboard'),
        onClick: () => {
          copyUnitInfoToClipboard(unit);
        },
      },
      {
        icon: 'xCircleOutlined',
        iconClassName: 'text-ui-red',
        onClick: () => {
          if (isCancelable) {
            onUnitCancel(unit);
          }
        },
        textClassName: 'w-full',
        isDisabled: !isCancelable,
        key: 'cancelUnit',
        text: (
          <Tooltip
            hidden={isTooltipHidden}
            text={t(
              "The Unit has already arrived at Urbify. To cancel it, update its status to 'Return to Client: Canceled'.",
            )}
          >
            <div className="flex items-center w-full justify-between flex-1 gap-1">
              <span>{t('Cancel unit')}</span>
              {!isTooltipHidden && (
                <Icon icon="infoFilled" className="h-4 w-4 text-grey-700" />
              )}
            </div>
          </Tooltip>
        ),
      },
    ],
    [
      copyUnitInfoToClipboard,
      isCancelable,
      isTooltipHidden,
      isCodeChangeDisabled,
      onUnitCancel,
      onUpdateUnitCodeClick,
      t,
      unit,
    ],
  );

  return (
    <CollapsableCard
      id={unit.id || unit.code}
      expandedContent={
        <div className="flex flex-col">
          <Row title={t('Task code')}>
            <Link
              to={{
                pathname: `/tasks`,
                search: `search=${encodeURIComponent(unit.task?.code)}&page=1`,
              }}
              className="flex justify-between"
            >
              <LinkButton text={unit.task?.code || ''} />
              <Icon className="h-4 w-4 text-ui-blue" icon="openInNew" />
            </Link>
          </Row>
          <Row>
            {unit.invalidAddress ? (
              <Tooltip text={t('Review and update address on a Task level')}>
                <span className="text-ui-red text-sm">{unit.address}</span>
              </Tooltip>
            ) : (
              <span className="text-sm">{unit.address}</span>
            )}
          </Row>
          <Row title={t('Execution Date')}>
            <div className="flex items-center justify-between gap-2 text-sm">
              <span>{moment(unit.date).format('DD.MM.YYYY.')}</span>
            </div>
          </Row>
          <Row title={t('Execution Slot')}>
            <div className="flex items-center gap-2 text-sm">
              <ShiftLabel shiftNumber={unit.shiftNumber} />
              <span>
                {moment(unit.timeFrom).format('HH:mm')}-
                {moment(unit.timeTo).format('HH:mm')}
              </span>
            </div>
          </Row>
          <Row title={t('Import date')}>
            <div className="flex items-center justify-between gap-2 text-sm">
              <span>{moment(unit.createdAt).format('DD.MM.YYYY.')}</span>
            </div>
          </Row>
          {unit.latestTour && (
            <Row title={t('Tour Stop')}>
              <Link
                state={{ backLink: -1 }}
                to={{
                  pathname: `/tours/${unit.latestTour.id}`,
                  search: `?stopId=${unit.latestTour.tourStop.id}`,
                }}
                className="flex justify-between"
              >
                <LinkButton
                  data-test="upcoming-routings-modal-button"
                  text={`${unit.latestTour.vehicle} ${unit.latestTour.tourStop.sequence}/${unit.latestTour.totalStops}`}
                />
                <Icon className="h-4 w-4 text-ui-blue" icon="openInNew" />
              </Link>
            </Row>
          )}
          <Collapse isOpen={seeAll}>
            <Row title={t('Hub')}>
              <span className="text-sm">{unit.hub?.shortCode || 'N/A'}</span>
            </Row>
            <Row title={t('Client')}>
              <span className="text-sm">
                {unit.client?.publicName || 'N/A'}
              </span>
            </Row>
            <Row title={t('Customer info')}>
              <EndCustomerPopover
                title={`${unit.firstName} ${unit.lastName}`}
                phoneNumber={unit.phoneNumber}
                email={unit.email}
                key={unit.id}
              >
                <LinkButton text={`${unit.firstName} ${unit.lastName}`} />
              </EndCustomerPopover>
            </Row>
          </Collapse>
          <Row>
            <div>
              <LinkButton
                text={seeAll ? t('See Less') : t('See All')}
                onClick={() => {
                  if (seeAll) {
                    setSeeAll(false);
                    return;
                  }
                  setSeeAll(true);
                }}
              />
              {!isSelectionVisible && (
                <div className="flex flex-col gap-3 rounded-md p-4 bg-grey-100 mt-4">
                  <Button
                    color="secondary"
                    icon="parcelView"
                    isFullWidth
                    size="s"
                    text={t('View Unit Details')}
                    variant="outlineBlack"
                    onClick={onDetailsClick}
                  />
                  <Button
                    color="secondary"
                    icon="updateUnitStatus"
                    isFullWidth
                    size="s"
                    disabled={isCanceled}
                    text={t('Update Unit status')}
                    variant="outlineBlack"
                    onClick={() => {
                      if (!isCanceled) {
                        onUpdateUnitStatusClick(unit);
                      }
                    }}
                  />
                  <Dropdown menu={menuItems}>
                    <Button
                      color="secondary"
                      icon="moreVert"
                      isFullWidth
                      size="s"
                      text={t('More Unit options')}
                      variant="outlineBlack"
                    />
                  </Dropdown>
                </div>
              )}
            </div>
          </Row>
        </div>
      }
      {...rest}
    >
      <div className="flex gap-4">
        <div className="flex flex-1 flex-col gap-1">
          <div className="flex gap-2 items-center">
            {isSelectionVisible && (
              <div className="flex items-center">
                <ListSelectionCheckbox
                  record={unit}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </div>
            )}
            <span className="text-sm font-medium break-all">{unit.code}</span>
          </div>
          <div>
            <UnitsStatusAndChangeTime unit={unit} />
          </div>
        </div>
      </div>
    </CollapsableCard>
  );
};

UnitCard.propTypes = {
  unit: PropTypes.oneOfType([PropTypes.object]),
  onUpdateUnitCodeClick: PropTypes.func,
  onUpdateUnitStatusClick: PropTypes.func,
  onUnitCancel: PropTypes.func,
  onDetailsClick: PropTypes.func,
};

UnitCard.defaultProps = {
  unit: undefined,
  onUpdateUnitCodeClick: undefined,
  onUpdateUnitStatusClick: undefined,
  onUnitCancel: undefined,
  onDetailsClick: undefined,
};

export default UnitCard;
