import cn from 'classnames';
import PropTypes from 'prop-types';
import { isValidElement } from 'react';

import Icon from '../../../components/Icon';

const variantsMap = {
  blue: 'text-ui-info-blue',
  grey: 'text-grey-700',
};

const CardDetailsRow = (props) => {
  const { border, className, icon, label, paddingTop, value, variant } = props;

  return (
    <div
      className={cn(
        'flex flex-row gap-4 text-sm sm:items-center',
        border && 'border-b-grey-300 border-b-[1px] last:border-b-0',
        paddingTop ? 'py-4' : 'pb-4',
        className,
      )}
    >
      <div className="flex flex-1 items-center gap-2 self-start text-grey-900">
        {typeof icon === 'object' && isValidElement(icon) && icon}
        {typeof icon === 'string' && (
          <Icon className={cn('h-5 w-5', variantsMap[variant])} icon={icon} />
        )}
        <span>{label}</span>
      </div>
      <span className="text-right font-medium text-primary-dark">{value}</span>
    </div>
  );
};

CardDetailsRow.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  variant: PropTypes.oneOf(Object.keys(variantsMap)),
  border: PropTypes.bool,
  paddingTop: PropTypes.bool,
};

CardDetailsRow.defaultProps = {
  className: '',
  icon: undefined,
  value: undefined,
  variant: 'grey',
  border: true,
  paddingTop: true,
};

export default CardDetailsRow;
