import { useSearchParams } from 'react-router';

const usePageLocationQuery = () => {
  const [searchParams] = useSearchParams();
  const pageParam = Number(searchParams.get('page'));

  if (!Number.isNaN(pageParam) && pageParam > 0 && pageParam % 1 === 0) {
    return pageParam;
  }

  return undefined;
};

export default usePageLocationQuery;
