import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';
import { useDebounce } from 'react-use';

import Pagination from '../../components/Pagination';
import deepCopy from '../../helpers/deepCopy';
import getFullUrl from '../../helpers/getFullUrl';
import useFetch from './hooks/useFetch';
import useResourceQuery from './hooks/useResourceQuery';
import { SocketHandler } from './hooks/useSocket';

const ResourceContext = createContext(undefined);
// exported only for storybook
export const ResourceContextProvider = ResourceContext.Provider;

export const useResource = () => {
  const context = useContext(ResourceContext);
  if (context === undefined) {
    throw new Error('useResource must be used within a Resource');
  }
  return context;
};

const Resource = forwardRef((props, ref) => {
  const {
    children,
    itemsPerPage,
    paginationStickyBottom,
    params,
    resourceUrl,
    showPageSizePicker,
    socketEntityName,
  } = props;
  const { fetch } = useFetch();
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const { allParams, page, pageSize, queryKey } = useResourceQuery({
    itemsPerPage,
    params,
    resourceUrl,
  });

  const {
    data: fetchedData,
    isFetching,
    isPending,
    refetch,
  } = useQuery({
    // ignoring exhaustive deps because query key is correctly exported in useResourceQuery
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,

    queryFn: async ({ signal }) => {
      const fullUrl = getFullUrl(resourceUrl, allParams);
      const response = await fetch(fullUrl, {
        signal,
      });
      return response.json();
    },
    // this query is always stale, we want to always fetch new data on page change
    staleTime: 0,
    placeholderData: keepPreviousData,
  });

  const data = useMemo(
    () => (Array.isArray(fetchedData?.data) ? fetchedData?.data : []),
    [fetchedData?.data],
  );
  const total = fetchedData?.count || 0;
  const isPaginationVisible = !isPending && !!(total && total > 0);
  const numberOfPages = Math.max(Math.ceil(total / pageSize), 1);
  const notFoundPage = page > numberOfPages && total > 0;
  const { t } = useTranslation();

  useImperativeHandle(
    ref,
    () => ({
      refetch,
    }),
    [refetch],
  );

  const onPageChangeCallback = useCallback(
    (currentPage) => {
      if (currentPage !== undefined && page !== currentPage) {
        searchParams.set('page', currentPage);
        setSearchParams(searchParams);
      }
    },
    [page, searchParams, setSearchParams],
  );

  const onPageSizeChangeCallback = useCallback(
    (currentPageSize) => {
      if (currentPageSize !== undefined && pageSize !== currentPageSize) {
        searchParams.set('pageSize', currentPageSize);
        setSearchParams(searchParams);
      }
    },
    [pageSize, searchParams, setSearchParams],
  );

  const onSocketUpdate = useCallback(
    (event) => {
      if (event.type !== 'updated') {
        return;
      }

      const updatedRecord = event.data;

      queryClient.setQueryData([resourceUrl, allParams], (oldData) => {
        const changedProperties = event.changedProperties || [];

        if (changedProperties.length === 0) {
          return undefined;
        }

        const recordsData = oldData?.data || [];
        const newRecordsData = recordsData.map((record) => {
          if (record.id === updatedRecord.id) {
            const newRecord = deepCopy(record);
            changedProperties.forEach((propertyName) => {
              newRecord[propertyName] = updatedRecord[propertyName];
            });
            return newRecord;
          }
          return record;
        });

        if (import.meta.env.DEV) {
          // eslint-disable-next-line no-console
          console.log(
            `[Resource] Updated record ${socketEntityName} - ${updatedRecord.id}`,
            '\n',
            updatedRecord,
            '\n',
            'Changed properties: ',
            '\n',
            changedProperties,
          );
        }

        return {
          ...oldData,
          data: newRecordsData,
        };
      });
    },
    [allParams, queryClient, resourceUrl, socketEntityName],
  );

  const [isLoading, setIsLoading] = useState(false);
  const [, cancel] = useDebounce(
    () => {
      if (isFetching && !isPending) {
        setIsLoading(true);
      }
    },
    400,
    [isFetching, isPending],
  );

  useEffect(() => {
    if (isFetching === false) {
      setIsLoading(false);
    }
  }, [isFetching]);

  useEffect(
    () => () => {
      if (cancel) {
        cancel();
      }
    },
    [cancel],
  );

  const element = useMemo(
    () =>
      (isValidElement(children) &&
        cloneElement(children, {
          ...children.props,
          data,
          isLoading,
          isPending,
          total,
          ...(notFoundPage && { noRecordsText: t('Page not found') }),
        })) ||
      null,
    [children, data, isPending, isLoading, notFoundPage, t, total],
  );

  const contextValue = useMemo(
    () => ({
      data,
    }),
    [data],
  );

  return (
    <ResourceContextProvider value={contextValue}>
      {socketEntityName && (
        <SocketHandler entity={socketEntityName} onEvent={onSocketUpdate} />
      )}
      <>
        {element}
        {isPaginationVisible && (
          // negative margin for full screen in padded mobile layout
          <div
            className={cn(
              '-ml-4 mt-4 w-screen bg-white lg:ml-0 lg:mt-0 lg:w-full lg:rounded-md',
              paginationStickyBottom && 'sticky bottom-0',
            )}
          >
            <Pagination
              current={page}
              pageSize={pageSize}
              total={total}
              onChange={onPageChangeCallback}
              onPageSizeChange={onPageSizeChangeCallback}
              showPageSizePicker={showPageSizePicker}
            />
          </div>
        )}
      </>
    </ResourceContextProvider>
  );
});

Resource.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  params: PropTypes.oneOfType([PropTypes.object]),
  resourceUrl: PropTypes.string.isRequired,
  socketEntityName: PropTypes.string,
  showPageSizePicker: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  paginationStickyBottom: PropTypes.bool,
};

Resource.defaultProps = {
  children: () => {},
  params: undefined,
  socketEntityName: undefined,
  showPageSizePicker: true,
  itemsPerPage: 15,
  paginationStickyBottom: false,
};

Resource.displayName = 'Resource';
export default Resource;
