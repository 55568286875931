import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Breakpoint from '../../enums/Breakpoint';
import TourType from '../../enums/TourType';
import useWindowSize from '../../hooks/useWindowSize';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

const TourIcon = ({ tourType }) => (
  <div className="h-5 w-5 rounded-full bg-grey-200 flex items-center justify-center">
    {tourType === TourType.Circular ? (
      <Icon className="h-4 w-4 text-ui-blue" icon="loop" />
    ) : (
      <Icon className="h-4 w-4 text-grey-900" icon="arrowRightAlt" />
    )}
  </div>
);

TourIcon.propTypes = {
  tourType: PropTypes.oneOf(Object.values(TourType)).isRequired,
};

const TourTypeIcon = (props) => {
  const { showTooltip, tourType } = props;

  // prevent null
  const finalTourType = useMemo(() => {
    if (tourType === TourType.Circular) {
      return TourType.Circular;
    }
    return TourType.OpenEnded;
  }, [tourType]);

  const { width } = useWindowSize();
  const isMobile = width < Breakpoint.SM;

  if (!showTooltip || isMobile) {
    return <TourIcon tourType={finalTourType} />;
  }

  const tooltipText =
    tourType === TourType.Circular ? t('Circular Tour') : t('Open-ended Tour');

  return (
    <Tooltip className="flex" placement="top" size="md" text={tooltipText}>
      <TourIcon tourType={finalTourType} />
    </Tooltip>
  );
};

TourTypeIcon.propTypes = {
  tourType: PropTypes.oneOf(Object.values(TourType)),
  showTooltip: PropTypes.bool,
};

TourTypeIcon.defaultProps = {
  tourType: TourType.OpenEnded,
  showTooltip: false,
};

export default TourTypeIcon;
