import cn from 'classnames';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

import NotificationCloseButton from '../app/Notifications/NotificationCloseButton';
import Icon from '../components/Icon';

const useCustomToast = () => {
  const getOptions = useCallback((message, options) => {
    const {
      hideCloseButton,
      hideIcon,
      hideProgressBar,
      onCloseClick,
      size,
      toastId,
    } = options;

    // if toastId is not provided, use the message to prevent showing duplicate toasts
    const finalToastId = toastId || message;

    return {
      closeButton: hideCloseButton
        ? false
        : ({ closeToast }) => (
            // enables handler for click on button, without closing
            <NotificationCloseButton onClick={onCloseClick || closeToast} />
          ),
      className: cn({
        'hide-progress-bar': hideProgressBar,
        'hide-close-button': hideCloseButton,
        'size-lg': size === 'lg',
      }),
      hideProgressBar,
      ...(hideIcon
        ? {
            icon: false,
          }
        : {}),
      toastId: finalToastId,
      ...options,
    };
  }, []);

  const toastSuccess = useCallback(
    (message, options = {}) => {
      toast.success(message, {
        icon: (
          <Icon
            data-test="toast-icon"
            className="h-4 w-4 text-ui-green-dark"
            icon="checkmarkCircleFilled"
          />
        ),
        ...getOptions(message, options),
      });
    },
    [getOptions],
  );

  const toastError = useCallback(
    (message, options = {}) => {
      toast.error(message, {
        icon: (
          <Icon
            data-test="toast-icon"
            className="h-4 w-4 text-ui-red"
            icon="report"
          />
        ),
        ...getOptions(message, options),
      });
    },
    [getOptions],
  );

  const toastWarning = useCallback(
    (message, options = {}) => {
      toast.warning(message, {
        icon: (
          <Icon
            data-test="toast-icon"
            className="h-4 w-4 text-ui-warning-orange"
            icon="warning"
          />
        ),
        ...getOptions(message, options),
      });
    },
    [getOptions],
  );

  const toastInfo = useCallback(
    (message, options = {}) => {
      toast.info(message, {
        icon: (
          <Icon
            data-test="toast-icon"
            className="h-4 w-4 text-ui-info-blue"
            icon="infoFilled"
          />
        ),
        ...getOptions(message, options),
      });
    },
    [getOptions],
  );

  return { toastError, toastInfo, toastSuccess, toastWarning };
};

export default useCustomToast;
