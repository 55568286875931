import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import FormCheckbox from '../../../components/FormCheckbox';
import FormInput from '../../../components/FormInput';
import FormPhoneInput from '../../../components/FormPhoneInput';
import FormSelect from '../../../components/FormSelect';
import Icon from '../../../components/Icon';
import InfiniteScrollFormSelect from '../../../components/InfiniteScrollFormSelect';
import OrganisationType from '../../../enums/OrganisationType';
import UserRole from '../../../enums/UserRole';
import Forbid from '../../../lib/rbac/Forbid';
import { useIsRole, useUser } from '../../../providers/UserProvider';
import useGetRoleOptions from './useGetRoleOptions';

const MembersForm = (props) => {
  const {
    email,
    errors,
    firstName,
    hubId,
    isCustomer,
    isEdit,
    isSubcarrierInitiallyChecked,
    isSubmitting,
    lastName,
    loggedInUserRole,
    managingHubId,
    memberId,
    onCancel,
    onIsDirtyChange,
    onMemberDelete,
    onSubmit,
    organisationId,
    organisationName,
    organisationType,
    phoneNumber,
    role: propRole,
    subcarrier,
  } = props;

  const { t } = useTranslation();
  const isSuperadmin = loggedInUserRole === UserRole.Superadmin;
  const isHubManager = loggedInUserRole === UserRole.HubManager;
  const { isSubcarrierUser, user } = useUser();
  const isAdmin = useIsRole(UserRole.Admin);

  let phoneError = '';
  if (errors.phoneNumber) {
    phoneError = t('Must be a valid phone number');
  }

  let emailError = '';
  if (errors.email) {
    emailError = t('Must be a valid e-mail');
  }

  const roleOptions = useGetRoleOptions(organisationType, isCustomer);

  const organisations = [
    {
      label: organisationName,
      value: organisationId,
    },
  ];

  const methods = useForm({
    defaultValues: {
      email,
      firstName,
      isSubcarrierChecked:
        !!subcarrier || isSubcarrierUser || isSubcarrierInitiallyChecked,
      lastName,
      managingHubId: isHubManager ? user?.managingHub?.id : managingHubId,
      organisationId,
      phoneNumber,
      role: propRole,
      subcarrierId: isSubcarrierUser ? user?.subcarrier?.id : subcarrier?.id,
      hubId: isHubManager ? user?.managingHub?.id : hubId,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });
  const {
    clearErrors,
    formState: { isDirty },
    handleSubmit,
    resetField,
    watch,
  } = methods;

  const role = watch('role');

  const isSubcarrierChecked = watch('isSubcarrierChecked');
  const isHubSectionVisible =
    organisationType === OrganisationType.Carrier &&
    (role === UserRole.Driver ||
      role === UserRole.HubManager ||
      role === UserRole.Crew ||
      isHubManager);

  const isSubcarrierSectionVisible =
    organisationType === OrganisationType.Carrier &&
    !isSuperadmin &&
    role !== UserRole.Superadmin &&
    role !== UserRole.Admin &&
    role !== UserRole.Customer;

  useEffect(() => {
    if (!isHubSectionVisible) {
      resetField('hubId');
    }
  }, [isHubSectionVisible, resetField]);

  useEffect(() => {
    if (!isSubcarrierChecked) {
      resetField('subcarrierId');
    }
  }, [isSubcarrierChecked, resetField]);

  useEffect(() => {
    if (role !== UserRole.HubManager) {
      resetField('managingHubId');
    }
  }, [role, resetField]);

  useEffect(() => {
    if (!isSubcarrierSectionVisible) {
      resetField('isSubcarrierChecked');
      resetField('subcarrierId');
    }
  }, [isSubcarrierSectionVisible, resetField]);

  useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  const transformOptionFn = useCallback(
    (option) => ({
      label: `${option.shortCode} (${option.name})`,
      value: option.id,
    }),
    [],
  );

  const onFormSubmit = (values) => {
    if (role !== UserRole.HubManager) {
      onSubmit({
        ...values,
        managingHubId: undefined,
      });
    } else {
      onSubmit(values);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-5"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <div className="flex flex-col gap-8">
          <div>
            <h3 className="grey-200 mb-5 text-base font-semibold">
              {t('Basic Information')}
            </h3>
            <div className="flex flex-col gap-4">
              <FormInput
                id="firstName"
                label={t('First Name')}
                name="firstName"
                placeholder={t('Enter first name')}
                required
              />
              <FormInput
                id="lastName"
                label={t('Last Name')}
                name="lastName"
                placeholder={t('Enter last name')}
                required
              />
              <FormInput
                disabled={isEdit}
                error={emailError}
                id="email"
                label={t('E-mail')}
                name="email"
                placeholder={t('Enter e-mail address')}
                required
              />
              <FormPhoneInput
                error={phoneError}
                id="phone-number"
                label={t('Phone Number')}
                name="phoneNumber"
                placeholder={t('Enter phone number')}
                required
              />
            </div>
          </div>

          {organisationType !== OrganisationType.Superadmin && (
            <div>
              <h3 className="grey-200 mb-5 text-base font-semibold">
                {isCustomer ? t('Client Organisation') : t('Organisation')}
              </h3>
              <div className="flex flex-col gap-4">
                <FormSelect
                  id="organisation-select"
                  label={t('Organisation')}
                  name="organisationId"
                  options={organisations}
                  placeholder={t('Select Organisation')}
                  readOnly
                  required
                />
                {isSubcarrierSectionVisible && (
                  <>
                    {!isSubcarrierUser && (
                      <FormCheckbox
                        id="subcarrier-team-checkbox"
                        label={t('Part of Subcarrier team')}
                        name="isSubcarrierChecked"
                      />
                    )}
                    {(isSubcarrierChecked || isSubcarrierUser) && (
                      <InfiniteScrollFormSelect
                        singleItemUrl="/subcarriers/"
                        url={`/carriers/${organisationId}/subcarriers`}
                        id="subcarrier-select"
                        label={t('Subcarrier')}
                        name="subcarrierId"
                        placeholder={t('Select Subcarrier')}
                        readOnly={isSubcarrierUser}
                        required
                        transformOptionFn={transformOptionFn}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          {!isCustomer && (
            <div className="flex flex-col gap-5">
              <h3 className="grey-200 text-base font-semibold">{t('Setup')}</h3>

              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <FormSelect
                    id="members-role"
                    label={t('Role')}
                    name="role"
                    options={roleOptions}
                    placeholder={t('Select Role')}
                    readOnly={isSuperadmin || propRole === UserRole.Admin}
                    required
                    onChange={(value) => {
                      if (value !== UserRole.Crew) {
                        clearErrors('hubId');
                      }
                    }}
                  />

                  {!isSuperadmin && (
                    <div className="flex items-center gap-2 text-sm text-grey-700">
                      <Icon
                        className="h-4 w-4 text-ui-info-blue"
                        icon="infoFilled"
                      />
                      <span>
                        {t(
                          'Selecting a role determines what permissions the member will have in your system.',
                        )}
                      </span>
                    </div>
                  )}
                </div>

                <Forbid roles={[UserRole.Superadmin]}>
                  {isHubSectionVisible && (
                    <div data-test="hub-id-select">
                      <InfiniteScrollFormSelect
                        singleItemUrl="/hubs/"
                        url={`/carriers/${organisationId}/hubs`}
                        id="hub-select"
                        label={t('Hub')}
                        name="hubId"
                        placeholder={t('Select Hub')}
                        readOnly={isHubManager}
                        required={
                          role === UserRole.Customer || role === UserRole.Crew
                        }
                        transformOptionFn={transformOptionFn}
                      />
                    </div>
                  )}
                </Forbid>

                {role === UserRole.HubManager && (
                  <div data-test="managing-hub-id-select">
                    <InfiniteScrollFormSelect
                      singleItemUrl="/hubs/"
                      url={`/carriers/${organisationId}/hubs`}
                      readOnly={isHubManager}
                      id="managing-hub-select"
                      label={t('Managing Hub')}
                      name="managingHubId"
                      placeholder={t('Select Managing Hub')}
                      required
                      transformOptionFn={transformOptionFn}
                    />
                  </div>
                )}
              </div>

              {isEdit && isAdmin && user.id !== memberId && (
                <div>
                  <Button
                    variant="ghostRed"
                    text={t('Delete Member')}
                    size="s"
                    onClick={onMemberDelete}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <hr className="text-grey-200" />
        <div className="flex flex-col justify-between gap-4 sm:flex-row sm:items-center">
          <Button
            className="order-last sm:order-none sm:flex-1"
            disabled={isSubmitting}
            text={t('Cancel')}
            variant="outlineBlack"
            onClick={onCancel}
          />
          <Button
            className="sm:flex-1"
            data-test="members-form-submit"
            disabled={isSubmitting}
            text={isEdit ? `${t('Save Changes')}` : `${t('Create Member')}`}
            type="submit"
            variant="solidBlue"
          />
        </div>
      </form>
    </FormProvider>
  );
};

MembersForm.propTypes = {
  email: PropTypes.string,
  errors: PropTypes.shape({
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  firstName: PropTypes.string,
  isCustomer: PropTypes.bool,
  isEdit: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  lastName: PropTypes.string,
  loggedInUserRole: PropTypes.string,
  managingHubId: PropTypes.string,
  onCancel: PropTypes.func,
  onIsDirtyChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  organisationId: PropTypes.string,
  organisationName: PropTypes.string,
  organisationType: PropTypes.string,
  phoneNumber: PropTypes.string,
  role: PropTypes.string,
  subcarrier: PropTypes.oneOfType([PropTypes.object]),
  hubId: PropTypes.string,
  isSubcarrierInitiallyChecked: PropTypes.bool,
  onMemberDelete: PropTypes.func,
  memberId: PropTypes.string,
};

MembersForm.defaultProps = {
  email: '',
  errors: {},
  firstName: '',
  isCustomer: false,
  isEdit: false,
  isSubmitting: false,
  lastName: '',
  loggedInUserRole: '',
  managingHubId: '',
  onCancel: () => null,
  onIsDirtyChange: () => null,
  organisationId: '',
  organisationName: null,
  organisationType: '',
  phoneNumber: '',
  role: '',
  subcarrier: null,
  hubId: '',
  isSubcarrierInitiallyChecked: false,
  onMemberDelete: () => null,
  memberId: '',
};

export default MembersForm;
