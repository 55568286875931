import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import Collapse from '../../../components/Collapse';
import Drawer from '../../../components/Drawer';
import FormDatePicker from '../../../components/FormDatePicker';
import FormSelect from '../../../components/FormSelect';
import FormTextarea from '../../../components/FormTextarea';
import Icon from '../../../components/Icon';
import LinkButton from '../../../components/LinkButton';
import UnitProgress from '../../../enums/UnitProgress';
import ObjectHelper from '../../../helpers/ObjectHelper';
import useCustomToast from '../../../hooks/useCustomToast';
import useToastFetchError from '../../../hooks/useToastFetchError';
import useUnitProgress from '../../../hooks/useUnitProgress';
import useFetch from '../../../lib/api/hooks/useFetch';
import { useListSelection } from '../../../providers/ListSelectionProvider';
import AffectedUnitsList from './AffectedUnitsList';
import BulkEditTaskList from './BulkEditTaskList';

function getTodaysUpcomingShifts(rescheduleShifts) {
  const currentTime = new Date();
  const shifts = [];

  if (!rescheduleShifts) return [];

  // eslint-disable-next-line no-restricted-syntax
  for (const timeString of rescheduleShifts) {
    const [hours, minutes] = timeString.routingTimeUTC.split(':').map(Number);
    const timeToCheck = moment.utc(currentTime);

    timeToCheck.set({
      hours,
      minutes,
      second: 0,
      millisecond: 0,
    });

    // Subtract 10 minutes
    const timeToCheckWithTenMinuteBuffer = new Date(
      timeToCheck.toDate().getTime() - 10 * 60 * 1000,
    );

    if (timeToCheckWithTenMinuteBuffer > currentTime) {
      shifts.push(timeString);
    } else {
      shifts.push({ ...timeString, isDisabled: true });
    }
  }

  return shifts;
}

const updatableUnitProgress = [
  UnitProgress.RoutingPending,
  UnitProgress.Blocked,
];
const UpdateDateDrawer = ({
  isBulkUpdate,
  isOpen,
  onBulkUpdateFail,
  onClose: propOnClose,
  tasks,
}) => {
  const { t } = useTranslation();
  const { fetch } = useFetch();
  const { toastSuccess } = useCustomToast();
  const { toastFetchError } = useToastFetchError();
  const unitProgress = useUnitProgress();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const { unselectItem } = useListSelection();

  const task = tasks?.[0];

  const affectedUnits = useMemo(() => {
    if (isBulkUpdate) {
      return tasks.flatMap((_task) =>
        _task.units.filter((unit) =>
          updatableUnitProgress.includes(unit.progress),
        ),
      );
    }

    return task?.units.filter((unit) =>
      updatableUnitProgress.includes(unit.progress),
    );
  }, [isBulkUpdate, task?.units, tasks]);

  const firstUpdatableUnit = affectedUnits?.length > 0 && affectedUnits[0];

  const queryClient = useQueryClient();

  const { data: availableShifts, isFetching } = useQuery({
    queryKey: [`/tasks/${task?.id}/available-shifts`],

    queryFn: async () => {
      const response = await fetch(`/tasks/${task?.id}/available-shifts`, {
        method: 'GET',
      });
      return response.json();
    },
    enabled: isOpen,
  });

  const minimumRescheduleDate = useMemo(() => {
    let rescheduledDeliveryDate = moment(firstUpdatableUnit.date)
      .startOf('day')
      .toDate();
    const currentDate = moment().startOf('day').toDate();

    if (!isOpen || !availableShifts || !firstUpdatableUnit) {
      return undefined;
    }

    // if delivery date is in the past, set current minimum reschedule date to today
    if (moment(rescheduledDeliveryDate).isBefore(currentDate)) {
      rescheduledDeliveryDate = currentDate;
    }

    // if current delivery date is today, return current day if another shift is available. if not, add 1 day
    if (moment(rescheduledDeliveryDate).isSame(new Date(), 'day')) {
      const upcomingShifts = getTodaysUpcomingShifts(availableShifts?.data);

      if (upcomingShifts?.every((shift) => shift.isDisabled)) {
        rescheduledDeliveryDate = moment(rescheduledDeliveryDate)
          .add(1, 'days')
          .startOf('day')
          .toDate();
      }
    }

    // if current date is in future, check if minimum date can be today
    if (moment(rescheduledDeliveryDate).isAfter(new Date(), 'day')) {
      const upcomingShifts = getTodaysUpcomingShifts(availableShifts?.data);

      if (upcomingShifts?.every((shift) => shift.isDisabled)) {
        rescheduledDeliveryDate = moment(currentDate)
          .add(1, 'days')
          .startOf('day')
          .toDate();
      } else {
        rescheduledDeliveryDate = currentDate;
      }
    }

    // first available reschedule is monday
    if (moment(rescheduledDeliveryDate).weekday() === 7) {
      rescheduledDeliveryDate = moment(rescheduledDeliveryDate)
        .add(1, 'days')
        .startOf('day')
        .toDate();
    }

    // if delivery is not today, let the user chose shifts for the same delivery date
    return rescheduledDeliveryDate;
  }, [availableShifts, firstUpdatableUnit, isOpen]);

  const maxRescheduleDate = useMemo(
    () => moment(minimumRescheduleDate).add(7, 'days').toDate(),
    [minimumRescheduleDate],
  );

  const methods = useForm({
    defaultValues: {
      newDate: '',
      newShift: null,
      notifyCustomer: false,
      // internal change note must be undefined as default value because if its disabled, it will be undefined
      internalChangeNote: undefined,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
  });

  const { control, formState, reset, setValue } = methods;
  const { isDirty, isValid } = formState;
  const formattedDate = methods.watch('newDate');
  const selectedShift = methods.watch('newShift');
  const internalChangeNote = methods.watch('internalChangeNote');
  const notifyCustomer = methods.watch('notifyCustomer');

  const onDateChange = useCallback(
    (newDate) => {
      if (newDate && moment(newDate).isSame(new Date(), 'day')) {
        const shifts = getTodaysUpcomingShifts(availableShifts?.data);

        const isExistingShiftAvailable = !!shifts.find(
          (shift) => !shift.isDisabled && shift.number === selectedShift,
        );

        if (!isExistingShiftAvailable) {
          setValue('newShift', '');
        }
      }
    },
    [availableShifts?.data, selectedShift, setValue],
  );

  const onClose = useCallback(() => {
    propOnClose();
    reset();
  }, [propOnClose, reset]);

  const bulkAffectedShifts = useMemo(() => {
    if (!isBulkUpdate) {
      return undefined;
    }

    const shiftGroups = affectedUnits.reduce((acc, unit) => {
      const dateKey = unit.shiftNumber;
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(unit);
      return acc;
    }, {});
    return shiftGroups;
  }, [affectedUnits, isBulkUpdate]);

  const mutation = useMutation({
    mutationFn: async () => {
      const newShift = () => {
        if (bulkAffectedShifts && Object.keys(bulkAffectedShifts).length > 1) {
          return undefined;
        }

        return availableShifts.data.find(
          (shift) => shift.number === selectedShift,
        );
      };

      const url = isBulkUpdate
        ? '/tasks/bulk-change-execution-date'
        : `/tasks/${task?.id}/execution-date`;

      const body = {
        date: moment(methods.getValues()?.newDate).format('YYYY-MM-DD'),
        ...(newShift() && { shiftId: newShift()?.id }),
        internalChangeNote,
        notifyCustomer,
        ...(isBulkUpdate && { taskIds: tasks.map((tsk) => tsk.id) }),
      };

      const response = await fetch(url, {
        body: ObjectHelper.removeNullableFields(body),
        method: isBulkUpdate ? 'POST' : 'PATCH',
      });

      return response.json();
    },
    onError: (error) => {
      toastFetchError(error);
    },
    onSuccess: ({ data }) => {
      onClose();
      if (isBulkUpdate) {
        const failedIds = [];
        tasks.forEach((tsk) => {
          unselectItem(tsk.id);
          if (data?.failedIds?.includes(tsk?.id)) {
            failedIds.push(tsk?.code);
          }
        });

        if (failedIds.length > 0) {
          onBulkUpdateFail({
            failedIds,
            successIdsLength: tasks.length - failedIds.length,
          });
        } else {
          toastSuccess(t('Multiple Tasks date updated.'));
        }
      } else {
        toastSuccess(t('Execution date and shift updated.'));
      }

      queryClient.invalidateQueries({
        queryKey: ['/tasks'],
      });
    },
  });

  const shiftsOptions = useMemo(() => {
    let shifts = availableShifts?.data;

    if (formattedDate && moment(formattedDate).isSame(new Date(), 'day')) {
      shifts = getTodaysUpcomingShifts(availableShifts?.data);
    }

    return shifts?.map((shift) => ({
      label: `${shift.hub ? t('Hub') : t('Carrier')} S${shift.number} (${
        shift.startTime
      }-${shift.endTime})`,
      value: shift.number,
      isDisabled: shift.isDisabled,
    }));
  }, [availableShifts?.data, formattedDate, t]);

  const affectedDates = useMemo(() => {
    if (!isBulkUpdate) {
      return undefined;
    }

    const dateGroups = affectedUnits.reduce((acc, unit) => {
      const dateKey = moment(unit.date).format('YYYY-MM-DD');
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(unit);
      return acc;
    }, {});
    return dateGroups;
  }, [affectedUnits, isBulkUpdate]);

  return (
    <Drawer
      onOpen={() => {
        reset({
          newDate: moment(firstUpdatableUnit?.date).startOf('day').toDate(),
          newShift: firstUpdatableUnit?.shiftNumber,
          notifyCustomer: false,
          internalChangeNote: undefined,
        });
      }}
      data-test="update-date-drawer"
      header={
        <span className="flex items-center gap-2 text-base font-medium">
          <span>{t('Change Execution Date and Shift')}</span>
        </span>
      }
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      body={
        <FormProvider {...methods}>
          <div className="flex flex-col divide-y divide-grey-200">
            <div className="flex flex-col gap-4 pb-4">
              {isBulkUpdate ? (
                <BulkEditTaskList />
              ) : (
                <div className="flex flex-col divide-y divide-grey-200 text-sm">
                  <div>
                    <div className="flex flex-row items-center justify-between pb-6 text-grey-700">
                      <Icon className="h-5 w-5 text-grey-700" icon="task" />
                      <div className="flex-1 ml-2 font-medium">
                        {t('Task Code')}
                      </div>
                      <div className="flex-1 text-right font-medium text-primary-dark">
                        {task?.code}
                      </div>
                    </div>

                    <div className="mb-4 text-grey-900">
                      {t('Units affected by this update')} (
                      {affectedUnits?.length}/{task?.units?.length})
                    </div>
                    <ul className="w-full flex flex-col gap-4">
                      {task?.units?.map((unit) => {
                        const isAffected = affectedUnits?.find(
                          (affectedUnit) => affectedUnit.id === unit.id,
                        );
                        return (
                          <li
                            key={unit.id}
                            className="flex items-center gap-4 text-sm"
                          >
                            <div className="flex-1 flex items-center gap-2">
                              <Icon
                                icon={unitProgress[unit.progress].labelIcon}
                                className={cn(
                                  unitProgress[unit.progress]
                                    .labelIconClassname,
                                  'w-[18px] h-[18px]',
                                )}
                              />
                              <div className="text-grey-900 font-medium">
                                {unit.code}
                              </div>
                            </div>
                            {isAffected ? (
                              <Icon icon="checkmark" className="w-4 h-4" />
                            ) : (
                              <Icon
                                icon="close"
                                className="w-[14px] h-[14px] text-grey-500"
                              />
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <div className="py-4">
              <div className="flex flex-col text-grey-900 text-sm">
                <div className="mb-2">{t('Latest Execution Date')}</div>
                {affectedDates ? (
                  <>
                    <div className="flex flex-col gap-2">
                      {Object.keys(affectedDates)
                        .slice(0, 5)
                        .map((date) => (
                          <AffectedUnitsList
                            info={moment(date).format('DD.MM.YYYY.')}
                            key={date}
                            unitsCount={
                              affectedDates[date].length > 1
                                ? affectedDates[date].length
                                : null
                            }
                          />
                        ))}
                    </div>
                    {Object.keys(affectedDates).length > 5 && (
                      <>
                        <Collapse isOpen={isCollapseOpen}>
                          <div className="flex flex-col gap-2 mt-2">
                            {Object.keys(affectedDates)
                              .slice(5)
                              .map((date) => (
                                <AffectedUnitsList
                                  info={moment(date).format('DD.MM.YYYY.')}
                                  key={date}
                                  unitsCount={
                                    affectedDates[date].length > 1
                                      ? affectedDates[date].length
                                      : null
                                  }
                                />
                              ))}
                          </div>
                        </Collapse>
                        <LinkButton
                          className="text-left mt-2"
                          text={
                            isCollapseOpen ? t('Show less') : t('Show more')
                          }
                          onClick={() => setIsCollapseOpen(!isCollapseOpen)}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <div className="font-medium">
                    {moment(firstUpdatableUnit?.date).format('DD.MM.YYYY.')}
                  </div>
                )}

                <div className="mt-4">
                  <FormDatePicker
                    disabled={isFetching}
                    dateFormat="dd.MM.yyyy"
                    isClearable
                    label={t('New Execution Date')}
                    minDate={minimumRescheduleDate}
                    maxDate={maxRescheduleDate}
                    name="newDate"
                    placeholderText={t('Select date')}
                    required
                    onChange={onDateChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col py-4">
              <div className="text-sm text-grey-900 mb-2">
                {t('Designated Shift')}
              </div>
              {bulkAffectedShifts &&
              Object.keys(bulkAffectedShifts).length > 1 ? (
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-2">
                    {Object.keys(bulkAffectedShifts).map((shift) => (
                      <AffectedUnitsList
                        info={`S${shift} (${moment(bulkAffectedShifts[shift][0].timeFrom).format('HH:mm')} - ${moment(bulkAffectedShifts[shift][0].timeTo).format('HH:mm')})`}
                        key={shift}
                        unitsCount={bulkAffectedShifts[shift].length}
                      />
                    ))}
                  </div>
                  <Alert
                    fontWeight="normal"
                    variant="info"
                    message={t(
                      'Bulk shift change is not possible because the selected Tasks are assigned to different shifts. When the date is changed, all Tasks will be automatically assigned to their latest designated shift.',
                    )}
                  />
                </div>
              ) : (
                <>
                  <div className="font-medium text-sm">
                    S{firstUpdatableUnit?.shiftNumber} (
                    {moment(firstUpdatableUnit.timeFrom).format('HH:mm')}-
                    {moment(firstUpdatableUnit.timeTo).format('HH:mm')})
                  </div>
                  <div className="flex flex-col gap-4 mt-4">
                    <FormSelect
                      disabled={isFetching}
                      required
                      label={t('New Shift')}
                      name="newShift"
                      isLoading={isFetching}
                      options={shiftsOptions}
                    />
                    {!selectedShift && !methods.formState.errors.newShift && (
                      <Alert
                        variant="error"
                        message={t(
                          'The designated shift is not available for the selected date. Please choose another shift or a different date.',
                        )}
                      />
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="pt-4">
              <FormTextarea
                showCounter
                label={t('Change Note (internal)')}
                name="internalChangeNote"
                disabled={!isDirty}
                className="max-h-[120px]"
                placeholder={t('Type here...')}
              />
            </div>
          </div>
        </FormProvider>
      }
      footer={
        <div className="flex flex-col gap-2">
          <Controller
            name="notifyCustomer"
            control={control}
            render={({ field: { onChange, value, ...controllerProps } }) => (
              <div className="flex min-h-[40px] items-center gap-2">
                <Checkbox
                  disabled={!isDirty}
                  checked={value}
                  onChange={(val) => onChange(val)}
                  {...controllerProps}
                />
                <span className="text-sm font-medium">
                  {t('Send e-mail update to customer')}
                </span>
              </div>
            )}
          />

          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
            <Button
              className="order-last sm:order-none"
              data-test="modal-button-cancel"
              text={t('Cancel')}
              variant="outlineBlack"
              onClick={() => {
                onClose();
              }}
            />
            <Button
              data-test="modal-button-action"
              text={t('Save Changes')}
              disabled={mutation.isPending || !isValid || !isDirty}
              variant="solidBlue"
              onClick={mutation.mutate}
            />
          </div>
        </div>
      }
    />
  );
};

UpdateDateDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isBulkUpdate: PropTypes.bool,
  onBulkUpdateFail: PropTypes.func,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      address: PropTypes.string,
      invalidAddress: PropTypes.bool,
      invalidAddressReasons: PropTypes.arrayOf(PropTypes.string),
      units: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          date: PropTypes.string,
          shiftNumber: PropTypes.number,
        }),
      ),
    }),
  ),
};

UpdateDateDrawer.defaultProps = {
  tasks: [],
  isBulkUpdate: false,
  onBulkUpdateFail: undefined,
};

export default UpdateDateDrawer;
