import PropTypes from 'prop-types';

import taskPropType from './taskPropType';

const locationPropTypes = PropTypes.shape({
  type: PropTypes.string.isRequired,
  coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
});

const tourStopPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  sequence: PropTypes.number.isRequired,
  street: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  country: PropTypes.string,
  eta: PropTypes.string.isRequired,
  windowFrom: PropTypes.string.isRequired,
  windowTo: PropTypes.string.isRequired,
  location: locationPropTypes.isRequired,
  address: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(taskPropType).isRequired,
  unitsBlocked: PropTypes.bool.isRequired,
  driverActionTime: PropTypes.string,
  what3Words: PropTypes.string,
});

export default tourStopPropType;
