import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Breakpoint from '../../../enums/Breakpoint';
import UserRole from '../../../enums/UserRole';
import useWindowSize from '../../../hooks/useWindowSize';
import { useIsRole } from '../../../providers/UserProvider';
import OrganisationDetailsSectionCard from '../../shared/OrganisationDetailsSectionCard';

const AddButton = ({ isMedium }) => {
  const { t } = useTranslation();
  const isAdmin = useIsRole(UserRole.Admin);

  if (!isAdmin) {
    return null;
  }

  return (
    <Button
      as={Link}
      className="w-full sm:w-fit"
      icon="plus"
      iconSize="m"
      text={isMedium ? t('Add') : t('Add Shift')}
      to="shifts/new"
      variant="outlineBlack"
    />
  );
};

AddButton.propTypes = {
  isMedium: PropTypes.bool,
};

AddButton.defaultProps = {
  isMedium: false,
};

const OrganisationDetailsShiftsCard = ({ shiftCount }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isMedium = width < Breakpoint.XXL && width >= Breakpoint.XL;

  return (
    <OrganisationDetailsSectionCard
      title={
        <span className="flex items-center gap-4">
          <Icon className="h-6 w-6" icon="clock" />
          <span>
            {t('Shifts')}{' '}
            {(shiftCount || shiftCount === 0) && `(${shiftCount})`}
          </span>
        </span>
      }
      topRightContent={
        shiftCount > 0 ? (
          <Button
            as={Link}
            className="w-full sm:w-fit"
            iconRight="chevronRight"
            iconSize="s"
            text={isMedium ? t('View') : t('View Shifts')}
            to="shifts"
            variant="outlineBlue"
          />
        ) : (
          <AddButton isMedium={isMedium} />
        )
      }
    />
  );
};

OrganisationDetailsShiftsCard.propTypes = {
  shiftCount: PropTypes.number,
};

OrganisationDetailsShiftsCard.defaultProps = {
  shiftCount: undefined,
};

export default OrganisationDetailsShiftsCard;
