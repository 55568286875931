import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import CurrencyHelper from '../../helpers/CurrencyHelper';
import FormInput from '../FormInput';

const FormCurrencyInput = ({ label, name, ...rest }) => {
  const { setValue } = useFormContext();

  return (
    <FormInput
      id={name}
      label={label}
      name={name}
      placeholder="0.00"
      inlineLabel="€"
      required
      min={0}
      setValueAs={(currency) => CurrencyHelper.currencyToNumber(currency)}
      onChange={(e) => {
        const currency = CurrencyHelper.stringToCurrency(e.target.value);
        setValue(name, currency);
      }}
      {...rest}
    />
  );
};

FormCurrencyInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormCurrencyInput;
