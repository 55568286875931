import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router';

import Button from '../../../components/Button';
import CollapsableCard from '../../../components/CollapsableCard';
import OrganisationType from '../../../enums/OrganisationType';
import OrganisationBadge from '../../shared/OrganisationBadge';

const OrganisationCard = (props) => {
  const { address, id, memberCount, name, type } = props;
  const location = useLocation();

  const { t } = useTranslation();

  return (
    <CollapsableCard
      id={id}
      data-test={`data-card-${id}`}
      expandedContent={
        <div className="flex flex-col gap-1">
          <div className="flex flex-col border-t border-grey-200 py-2">
            <span className="mb-1 text-xs text-grey-700">{t('Members')}</span>
            <span className="text-sm font-medium">{memberCount}</span>
          </div>
          <div className="flex flex-col border-t border-grey-200 py-2">
            <span className="mb-1 text-xs text-grey-700">{t('Address')}</span>
            <span className="text-sm font-medium">{address}</span>
          </div>
          <div className="flex flex-col border-t border-grey-200 pt-4">
            <Button
              as={Link}
              icon="pageView"
              isFullWidth
              text={t('View Organisation Details')}
              to={`/organisations/${id}`}
              variant="outlineBlack"
              state={{ backLink: location.pathname + location.search }}
            />
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-1">
        <span className="text-base font-medium">{name}</span>
        <OrganisationBadge organisationType={type} />
      </div>
    </CollapsableCard>
  );
};

OrganisationCard.propTypes = {
  address: PropTypes.string,
  id: PropTypes.string,
  memberCount: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.oneOf([
    OrganisationType.Carrier,
    OrganisationType.Superadmin,
  ]),
};

OrganisationCard.defaultProps = {
  address: undefined,
  id: undefined,
  memberCount: undefined,
  name: undefined,
  type: undefined,
};

export default OrganisationCard;
