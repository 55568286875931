import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import FormCheckbox from '../../../components/FormCheckbox';
import FormInput from '../../../components/FormInput';
import FormMultiSelect from '../../../components/FormMultiSelect';
import SpecialHandling from '../../../enums/SpecialHandling';
import useSpecialHandlingTranslations from '../../../hooks/useSpecialHandlingTranslations';

const Field = ({ id, index, name, onRemoveClick }) => {
  const { t } = useTranslation();
  const specialHandlingTranslations = useSpecialHandlingTranslations();

  const options = Object.values(SpecialHandling).map((value) => ({
    value,
    label: specialHandlingTranslations[value],
  }));

  const {
    formState: { errors },
    resetField,
    watch,
  } = useFormContext();

  const error = errors[name] && errors[name][index]?.reference?.message;
  const specialHandlingErrors =
    errors[name] && errors[name][index]?.specialHandling?.message;

  const isSpecialHandling = watch(`${name}.${index}.isSpecialHandling`);

  useEffect(() => {
    if (!isSpecialHandling) {
      resetField(`${name}.${index}.specialHandling`, { defaultValue: [] });
    }
  }, [name, index, resetField, isSpecialHandling]);

  return (
    <div className="flex flex-col gap-2 rounded-md border border-grey-300 p-4">
      <FormInput
        error={error}
        required
        name={`${name}.${index}.reference`}
        id={`${id}-reference`}
        label={t('Unit Code')}
        placeholder={t('Enter Unit code')}
      />
      <FormCheckbox
        className="w-max"
        name={`${name}.${index}.isSpecialHandling`}
        label={t('Contains special type of goods')}
      />
      {isSpecialHandling && (
        <FormMultiSelect
          error={specialHandlingErrors}
          id={`${id}-specialHandling`}
          label={t('Special Type of Goods')}
          name={`${name}.${index}.specialHandling`}
          options={options}
          placeholder={t('Select type')}
          required
        />
      )}
      {index !== 0 && (
        <div>
          <Button
            text={t('Remove Unit')}
            variant="ghostRed"
            onClick={onRemoveClick}
            size="s"
          />
        </div>
      )}
    </div>
  );
};

Field.propTypes = {
  onRemoveClick: PropTypes.func,
  id: PropTypes.string,
  index: PropTypes.number,
  name: PropTypes.string,
};

Field.defaultProps = {
  onRemoveClick: undefined,
  id: undefined,
  index: undefined,
  name: undefined,
};

export default Field;
