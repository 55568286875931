import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import IconButton from '../../../components/IconButton';
import NewTable, {
  CellAlignment,
  CellPadding,
} from '../../../components/NewTable';
import Tooltip from '../../../components/Tooltip';
import CheckoutType from '../../../enums/CheckoutType';
import UserRole from '../../../enums/UserRole';
import { useUser } from '../../../providers/UserProvider';
import ShiftLabel from '../../shared/ShiftLabel';
import TourAssignment from '../../shared/TourAssignment';
import TourDetails from '../../shared/TourDetails';
import TourStatusInfo from '../../tours/TourStatusInfo';
import FixedToursFilters from '../FixedToursFilters';

const FixedToursTable = (props) => {
  const { total, ...rest } = props;
  const { t } = useTranslation();
  const { user } = useUser();

  const userRole = user?.role;
  const isAssignmentCellVisible =
    userRole === UserRole.Admin ||
    userRole === UserRole.OperationsManager ||
    userRole === UserRole.HubManager;

  const columns = useMemo(() => {
    const allColumns = [
      {
        accessorKey: 'vehicle',
        header: t('Tour Code'),
        size: 184,
        cell: (info) => info.getValue(),
      },
      {
        size: 232,
        header: t('Assigned to'),
        key: 'assignment',
        cell: (info) => <TourAssignment tour={info.row.original} />,
        meta: {
          paddingY: CellPadding.Sm,
        },
      },
      {
        size: 220,
        header: t('Status'),
        accessorKey: 'status',
        cell: (info) => {
          const tour = info.row.original;
          return (
            <TourStatusInfo
              isManualCheckout={tour.checkOutType === CheckoutType.Manual}
              isDeleyed={tour.delayCount > 0}
              completedStops={tour.completedStops}
              totalStops={tour.totalStops}
              tourStatus={tour.status}
            />
          );
        },
      },
      {
        size: 301,
        header: t('Tour Details'),
        cell: (info) => <TourDetails tour={info.row.original} />,
      },
      {
        size: 120,
        header: t('Start'),
        cell: (info) => {
          const shiftNumber = info.row.original.shift;

          return (
            <span className="flex items-center gap-2">
              <div>
                <ShiftLabel shiftNumber={shiftNumber} />
              </div>
              <span>
                {moment(info.row.original.shiftStart).format('HH:mm')}
              </span>
            </span>
          );
        },
      },
      {
        size: 120,
        header: t('Date'),
        accessorKey: 'date',
        cell: (info) => moment(info.getValue()).format('DD.MM.YYYY'),
      },
      {
        id: 'actions',
        cell: (info) => (
          <Tooltip
            className="flex"
            placement="top"
            size="sm"
            text={t('Tour Details')}
          >
            <IconButton
              as={Link}
              icon="pageView"
              size="s"
              state={{
                backLink: -1,
              }}
              to={{
                pathname: `/tours/${info.row.original.id}`,
              }}
              variant="ghostBlack"
            />
          </Tooltip>
        ),
        meta: {
          align: CellAlignment.Center,
          paddingY: CellPadding.Sm,
        },
        size: 50,
        enableResizing: false,
      },
    ];

    return allColumns.filter((column) => {
      if (!isAssignmentCellVisible) {
        return column.key !== 'assignment';
      }

      return column;
    });
  }, [isAssignmentCellVisible, t]);

  return (
    <>
      <FixedToursFilters count={total} />
      <NewTable
        id="fixed-tours-table"
        roundedTop={false}
        restoreScroll
        inlineScroll
        minColSize={50}
        columns={columns}
        {...rest}
      />
    </>
  );
};

FixedToursTable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  total: PropTypes.number,
};

FixedToursTable.defaultProps = {
  children: undefined,
  className: '',
  total: undefined,
};

export default FixedToursTable;
